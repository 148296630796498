import React from "react";
import { InputFormProps } from "./interface/formInterface";
import DivStyle from "../style/div.component";

const FormCheckDayInput = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  return (
    <DivStyle
      display="flex"
      flex_direction="row"
      align_items="center"
      width={width}
    >
      <input
        checked={value.value}
        onChange={(e) => {
          value.setValue(e.target.checked);
          if (e.target.checked === true) {
            setValue(["월", "화", "수", "목", "금", "토", "일"]);
          } else {
            setValue([]);
          }
        }}
        type="checkbox"
        id="check"
        style={{ width: "20px", height: "20px" }}
      ></input>
      <label
        htmlFor="check"
        style={{
          color: "white",
          marginLeft: "7px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        {label}
      </label>
    </DivStyle>
  );
};
export default FormCheckDayInput;
