/* eslint-disable */
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import DivStyle from "../style/div.component";
import { PageProps } from "./interface/interface";

const Page = (props: PageProps) => {
  const { currentPage, totalPages, dataList, setCurrentPage } = props;
  return (
    <DivStyle
      width="auto"
      display="flex"
      fontSize="12px"
      align_items="center"
      marginTop="10px"
      marginRight="40px"
      justify_content="flex-end"
    >
      <p style={{ color: "white" }}>Total : {dataList.length}</p>
      <p style={{ color: "white", marginLeft: "15px" }}>
        {currentPage} of {totalPages > 0 ? totalPages : 1}
      </p>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "10px",
        }}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <Icon
          path={mdiChevronLeft}
          size={1}
          style={{
            color: currentPage === 1 ? "#626262" : "white",
          }}
        />
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "20px",
        }}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        <Icon
          path={mdiChevronRight}
          size={1}
          style={{
            color: currentPage >= totalPages ? "#626262" : "white",
          }}
        />
      </button>
    </DivStyle>
  );
};

export default Page;
