/* eslint-disable */
import { useEffect, useState } from "react";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";
import AdminAPI from "../../api/admin";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const PubGradeTable = (props: TableProps) => {
  const [isWide, setIsWide] = useState(window.innerWidth > 700);
  const {
    headers,
    currentPage,
    dataList,
    setDetail,
    setIsOpen,
    size,
    getDatas,
  } = props;
  const pageSize = size ? size : 10;
  const getDataForPage = (page: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataList.slice(startIndex, endIndex);
  };
  const setLoading = useSetRecoilState(loadingState);
  const option = [
    {
      value: "REQUEST",
      name: "신청",
    },
    {
      value: "TM_STAND_BY",
      name: "tm대기",
    },
    {
      value: "WAIT_DEPOSIT",
      name: "상담완료 / 입금대기",
    },
    {
      value: "FINISH",
      name: "완료",
    },
    {
      value: "RETURN",
      name: "반려",
    },
  ];
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setIsWide(false);
      } else {
        setIsWide(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getNestedData(data: any, path: string) {
    const keys = path.split(".");
    let result = data;
    for (let key of keys) {
      result = result[key];
      if (result === undefined) break;
    }
    return result;
  }
  function getNameByValue(value: string) {
    const result = option.find((item) => item.value === value);
    return result ? result.name : null;
  }

  const getPackageList = async (id: number) => {
    const res = await AdminAPI.getPackage(id);
    if (res.length > 0) {
      const packageNames = res.map((item) => item.packageName).join(", ");
      alert(packageNames);
    }
  };

  const convertGrade = (grade: string) => {
    return grade === "NORMAL"
      ? "FREE"
      : grade === "PREMIUM"
      ? "STANDARD"
      : "PREMIUM";
  };
  return isWide ? (
    <TableStyle marginTop="30px">
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
              if (setIsOpen) setIsOpen(true);
              getPackageList(data.id);
            }}
          >
            {headers.map((header) =>
              header.text === "실청 플랜" ? (
                <td key={header.value}>
                  {header.subValue ? convertGrade(data[header.subValue]) : ""}
                  {" ->" + convertGrade(data[header.value])}
                </td>
              ) : header.text === "진행" ? (
                <td key={header.value}>
                  <select
                    onClick={(e) => e.stopPropagation()}
                    value={data[header.value]}
                    onChange={async (
                      e: React.ChangeEvent<HTMLSelectElement>
                    ) => {
                      setLoading(true);
                      const selectedName = e.target.value;
                      await AdminAPI.updateGradeRequestState(data.id, {
                        state: selectedName,
                      });
                      await getDatas();
                      setLoading(false);
                      if (selectedName === "FINISH") {
                        alert("펍 관리에서 등급을 변경해주세요. ");
                      }
                    }}
                  >
                    <option value="" disabled>
                      {"진행 현황"}
                    </option>
                    {option?.map(
                      (
                        item: { value: string; name: string },
                        index: number
                      ) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      )
                    )}
                  </select>
                </td>
              ) : header.text === "진행 현황" ? (
                <td key={header.value}>{getNameByValue(data.state)}</td>
              ) : header.text === "결합 상품 여부" ? (
                <td key={header.value}>{data.hasPackage ? "Y" : "N"}</td>
              ) : header.value.includes(".") ? (
                <td key={header.value}>{getNestedData(data, header.value)}</td>
              ) : (
                <td key={header.value}>{data[header.value]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  ) : (
    <TableStyle setMobile={true} marginTop="30px">
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
              if (setIsOpen) setIsOpen(true);
            }}
          >
            {headers.map((header) => (
              <td key={header.value}>
                <div>{header.text}</div>
                {header.text === "실청 플랜" ? (
                  <div>
                    {header.subValue ? convertGrade(data[header.subValue]) : ""}
                    {" ->" + convertGrade(data[header.value])}
                  </div>
                ) : header.text === "진행" ? (
                  <select
                    value={data[header.value]}
                    onChange={async (
                      e: React.ChangeEvent<HTMLSelectElement>
                    ) => {
                      setLoading(true);
                      const selectedName = e.target.value;
                      await AdminAPI.updateGradeRequestState(data.id, {
                        state: selectedName,
                      });
                      await getDatas();
                      setLoading(false);
                      if (selectedName === "FINISH") {
                        alert("펍 관리에서 등급을 변경해주세요. ");
                      }
                    }}
                  >
                    <option value="" disabled>
                      {"진행 현황"}
                    </option>
                    {option?.map(
                      (
                        item: { value: string; name: string },
                        index: number
                      ) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      )
                    )}
                  </select>
                ) : header.text === "진행 현황" ? (
                  <td key={header.value}>{getNameByValue(data.state)}</td>
                ) : header.value.includes(".") ? (
                  <td key={header.value}>
                    {getNestedData(data, header.value)}
                  </td>
                ) : (
                  <td key={header.value}>{data[header.value]}</td>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default PubGradeTable;
