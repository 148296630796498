import { useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
interface SellerProps {
  setUserId: (value: any) => void;
  setUserNickname: (value: any) => void;
  setIsOpen: (value: any) => void;
}
const SearchUser = (props: SellerProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState([]);
  const { setUserId, setUserNickname, setIsOpen } = props;

  const [search, setSearch] = useState("");
  const headers = [
    {
      text: "id",
      align: "center",
      value: "id",
      sortable: false,
    },
    {
      text: "닉네임",
      align: "center",
      value: "nickname",
      sortable: false,
    },
  ];

  const getList = async () => {
    const res = await AdminAPI.findUsers(search, 0);
    if (res && res.status === 200) {
      setDataList(res.data.userList);
    }
  };

  const setData = () => {
    if (dataList.length > 0 && selected !== "") {
      const data = dataList[parseInt(selected)];
      setUserId(data["id"]);
      setUserNickname(data["nickname"]);
      setIsOpen(false);
    } else {
      alert("선택해주세요.");
    }
  };

  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>유저 검색</h4>
      <DivStyle
        width="80%"
        display="flex"
        flex_direction="row"
        align_items="center"
      >
        <InputFormStyle
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getList();
            }
          }}
        ></InputFormStyle>
        <div onClick={() => getList()}>
          <Icon path={mdiMagnify} size={1} />
        </div>
      </DivStyle>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />
      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SearchUser;
