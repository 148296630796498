import { adminApiRequest } from "../../utils/apiUtils";
export interface ArticleResponse {
  id: number;
  title: string;
  content: string;
  userProfile?: string;
  authorId: number;
  authorNickname: string;
  likeCount: number;
  viewCount: number;
  createdAt: string;
  updatedAt: string;
  commentCount: number;
  type: string;
  isExit: boolean;
}

export interface ArticleReportResponse {
  id: number;
  article: ArticleResponse;
  complete: boolean;
  delete: boolean;
  reportNickname: string;
  type: string;
  description: string;
  createdAt: string;
}

export interface ArticleCommentResponse {
  id: number;
  content: string;
  authorNickname: string;
  userProfile: string;
  createdAt: string;
  isExit: boolean;
}

export interface ArticleCommentReportResponse {
  comment: ArticleCommentResponse;
  id: number;
  complete: boolean;
  delete: boolean;
  reportNickname: string;
  type: string;
  description: string;
  createdAt: string;
}
export const AdminBoardAPI = {
  registerBoardNotice: (body: any) => {
    return adminApiRequest
      .post("/community/hand-board/notice", body)
      .then((res) => res);
  },
  updateBoardNotice: (body: any, id: number) => {
    return adminApiRequest
      .put(`/community/hand-board/notice/${id}`, body)
      .then((res) => res);
  },
  removeBoardNotice: (id: number) => {
    return adminApiRequest
      .delete(`/community/hand-board/notice/${id}`)
      .then((res) => res);
  },
  removeBoard: (id: number) => {
    return adminApiRequest
      .delete(`/community/hand-board/${id}`)
      .then((res) => res);
  },
  removeBoardReport: (id: number) => {
    return adminApiRequest
      .delete(`/board/articles/report/${id}`)
      .then((res) => res);
  },
  acceptBoardReport: (id: number) => {
    return adminApiRequest
      .put(`/board/articles/report/${id}`)
      .then((res) => res);
  },
  removeBoardCommentReport: (id: number) => {
    return adminApiRequest
      .delete(`/board/articles-comment/report/${id}`)
      .then((res) => res);
  },
  removeBoardComment: (id: number) => {
    return adminApiRequest
      .delete(`/community/hand-board/comment/${id}`)
      .then((res) => res);
  },
  acceptBoardCommentReport: (id: number) => {
    return adminApiRequest
      .put(`/board/articles-comment/report/${id}`)
      .then((res) => res);
  },
  getUserArticle: () => {
    return adminApiRequest.get("/community/hand-board").then((res) => res.data);
  },
  getArticleComment: () => {
    return adminApiRequest
      .get("/community/hand-board/comment")
      .then((res) => res.data);
  },
  getArticleReport: () => {
    return adminApiRequest
      .get("/board/articles/report")
      .then((res) => res.data);
  },
  getArticleCommentReport: () => {
    return adminApiRequest
      .get("/board/articles/comment/report")
      .then((res) => res.data);
  },
  getBoardNotice: () => {
    return adminApiRequest
      .get("/community/hand-board/notice")
      .then((res) => res.data);
  },
};
