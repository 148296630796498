import { useEffect } from "react";
import DivStyle from "../components/style/div.component";

const TournamentAdmin = () => {
  const gameAdminHost = process.env.REACT_APP_GAME_ADMIN_URL
    ? process.env.REACT_APP_GAME_ADMIN_URL
    : "https://test-gameadmin.runnerrunner.co.kr";

  useEffect(() => {
    window.open(gameAdminHost, "_blank");
  }, []);

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <h2
        style={{
          color: "white",
          width: "auto",
          marginTop: "40px",
          marginLeft: "50px",
        }}
      >
        토너먼트 게임 관리
      </h2>
    </DivStyle>
  );
};
export default TournamentAdmin;
