import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Page from "../../components/table/paging";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
import CommnetTable from "../../components/table/commentTable";
import { IUserNowComment } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const UserNowComment = () => {
  const [dataList, setDataList] = useState<IUserNowComment[]>([]);
  const [searchDataList, setSearchDataList] = useState<IUserNowComment[]>([]);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const headers = [
    {
      text: "날짜 (최근 순)",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "작성자",
      align: "center",
      sortable: true,
      value: "nickName",
    },
    {
      text: "내용",
      align: "center",
      sortable: true,
      value: "message",
    },
    {
      text: "삭제",
      align: "center",
      sortable: false,
      value: "action",
    },
  ];
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  function truncateString(str: string) {
    // 최대 길이보다 문자열의 길이가 길 경우, 지정된 길이까지 자르고 '...' 추가
    if (str.length > 40) {
      return str.substring(0, 40) + "...";
    }
    // 그렇지 않은 경우, 원본 문자열 반환
    return str;
  }

  const removeComment = async (id: number) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeUserComment(id);
      if (res && res.status === 200) {
        alert("삭제 성공했습니다.");
        getDataList();
      }
      setIsLoading(false);
    }
  };
  const getDataList = async () => {
    const res = await AdminAPI.getUserComments();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IUserNowComment) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
        message: truncateString(item.message),
      }));
      console.log(updatedData);
      const sortedData = updatedData
        .slice()
        .sort((a: IUserNowComment, b: IUserNowComment) => b.id - a.id);
      setDataList(sortedData);
      const totalPages = Math.ceil(res.data.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(sortedData);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: IUserNowComment) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    getDataList();
  }, []);
  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
      >
        <h2 style={{ color: "white", width: "95%", marginTop: "40px" }}>
          댓글 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <CommnetTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchDataList}
            setDetail={removeComment}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchDataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
    </>
  );
};

export default UserNowComment;
