import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import FormInput from "./form_input";
import { FormProps } from "./interface/formInterface";

const GameForm = (props: FormProps) => {
  const { form, add, remove, mode, save } = props;
  return (
    <DivStyle
      backgroundColor="#1E1E1E"
      width="100%"
      height="calc(auto + 10px)"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "30px",
        }}
      >
        토너먼트 입장 설정
      </span>
      <div style={{ width: "90%", marginBottom: "20px" }}>
        <FormInput
          height="20px"
          fontSize="13px"
          value={form[0].value}
          label={form[0].label}
          setValue={form[0].setValue}
          width={form[0].width}
        ></FormInput>
      </div>
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "50px",
        }}
      >
        추천 친구 보상(1명 기준)
      </span>
      <div style={{ width: "90%", marginBottom: "20px" }}>
        <FormInput
          height="20px"
          fontSize="13px"
          value={form[1].value}
          label={form[1].label}
          setValue={form[1].setValue}
          width={form[1].width}
        ></FormInput>
      </div>
      <div style={{ width: "90%", marginBottom: "20px" }}>
        <FormInput
          height="20px"
          fontSize="13px"
          value={form[2].value}
          label={form[2].label}
          setValue={form[2].setValue}
          width={form[2].width}
        ></FormInput>
      </div>
      {mode ? (
        <div>
          <ButtonStyle
            onClick={() =>
              mode === "U" ? save() : mode === "S" ? save() : add()
            }
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#1976D2"
            borderRadius="5px"
            color="white"
          >
            {mode === "U" ? "저장" : mode === "S" ? "저장" : "등록"}
          </ButtonStyle>
          {mode === "U" ? (
            <ButtonStyle
              marginLeft="10px"
              onClick={() => remove()}
              marginBottom="30px"
              width="70px"
              height="40px"
              border="none"
              backgroundColor="#DD2C00"
              borderRadius="5px"
              color="white"
            >
              삭제
            </ButtonStyle>
          ) : null}
        </div>
      ) : null}
    </DivStyle>
  );
};

export default GameForm;
