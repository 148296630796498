export interface IBrandCompetition {
  id: number;
  image: string;
  title: string;
  description: string;
  lat: number;
  lon: number;
  prize: number;
  prizeUnit: string;
  place: string;
  phone: string;
  event: string;
  webUrl: string;
  startAt: string;
  endAt: string;
  buyIn: number;
  blindLevelUpTime: number;
  registerLimit: number;
  currentBlindLevel: number;
  starting: number;
  reEntry: number;
  player: number;
  entry: number;
  totalStack: number;
  averageStack: number;
  competitionType: string;
}

export interface ICompetition {
  id: number;
  title: string;
  type: "DOMESTIC" | "INTERNATIONAL" | "PUB";
  thumbnail: string;
  posters: string[];
  startDate: string;
  endDate: string;
  events: ICompetitionEvent[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface ICompetitionEvent {
  id?: number;
  name: string;
  buyIn: string;
  registrationEndLevelString: string;
  durations: string;
  startingStack: number;
  date: string;
}

export enum CompetitionRegion {
  SEOUL = "SEOUL",
  GYEONGGI = "GYEONGGI",
  INCHEON = "INCHEON",
  GANGWON = "GANGWON",
  JUNRA = "JUNRA",
  GYUNGNAM_BUSAN = "GYUNGNAM_BUSAN",
  GYUNGBUK_DAEGU = "DAEGU",
  CHUNGCHEONG = "CHUNGCHEONG",
  DAEJEON_SEJONG = "DAEJEON",
  JEJU = "JEJU",
  ASIA = "ASIA",
  AMERICA = "AMERICA",
  EUROPE = "EUROPE",
}
