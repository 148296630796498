import { useEffect, useState } from "react";
import API from "../../api/api";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { ITournamentRanking, ITournamentRankers } from "./interface/interface";
import { FormProps } from "../../components/form/interface/formInterface";
import SmallForm from "../../components/form/small_form";
import RankerTable from "../../components/table/RankerTable";

const TournamentRanking = () => {
  const [rankers, setRankers] = useState<ITournamentRankers[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [rankerCurrentPage, setRankerCurrentPage] = useState(1);
  const [rankerTotalPages, setRankerTotalPages] = useState(0);

  const [tournamentRankingList, setTournamentRankingList] = useState<
    ITournamentRankers[]
  >([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [groupId, setGroupId] = useState(0);
  const [startAt, setStartAt] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [mode, setMode] = useState("I");

  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "토너먼트명",
        width: "100%",
        type: "input",
      },
      {
        value: description,
        setValue: setDescription,
        label: "토너먼트 설명",
        width: "100%",
        type: "text",
      },
      {
        value: groupId,
        setValue: setGroupId,
        label: "그룹ID",
        width: "100%",
        type: "input",
      },
      {
        value: startAt,
        setValue: setStartAt,
        label: "토너먼트 시작일시",
        width: "100%",
        type: "input",
      },
    ],
  };

  const headers = [
    {
      text: "토너먼트명",
      align: "start",
      sortable: false,
      value: "titles",
      width: "320px",
    },
    {
      text: "그룹ID",
      align: "center",
      sortable: false,
      value: "groupId",
      width: "70px",
    },

    {
      text: "토너먼트 시작일시",
      align: "center",
      sortable: false,
      value: "startAt",
      width: "150px",
    },

    {
      text: "수상자",
      align: "center",
      sortable: false,
      value: "rankers",
      width: "70px",
    },
  ];

  const rankersHeaders = [
    {
      text: "순위",
      align: "center",
      sortable: false,
      value: "ranking",
    },
    {
      text: "아이디",
      align: "center",
      sortable: false,
      value: "user.id",
    },

    {
      text: "이메일",
      align: "start",
      sortable: false,
      value: "user.email",
    },

    {
      text: "닉네임",
      align: "center",
      sortable: false,
      value: "user.nickname",
    },
    {
      text: "핸드폰번호",
      align: "center",
      sortable: false,
      value: "user.phoneNumber",
    },
    {
      text: "성명",
      align: "center",
      sortable: false,
      value: "user.username",
    },
    {
      text: "계좌번호",
      align: "center",
      sortable: false,
      value: "user.accountInfo",
    },
  ];

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getRankers = async (start: string, end: string) => {
    const res = await API.getTournamentRankers(start, end);
    if (res?.status === 200) {
      const data: ITournamentRankers[] = res.data;
      const updatedData = data.map((item: ITournamentRankers) => ({
        ...item,
        startAt: formatDate(item.startAt),
      }));
      setRankers(updatedData);
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);
    }
  };

  useEffect(() => {
    const today = new Date();
    const pastTwoMonths = new Date(new Date().setMonth(today.getMonth() - 2));

    const formatDate = (date: Date) => {
      return date.toISOString().substring(0, 10);
    };
    const format_start = formatDate(pastTwoMonths);
    const format_end = formatDate(today);

    getRankers(format_start, format_end);
  }, []);

  const setDetail = (item: ITournamentRanking) => {
    setTournamentRankingList(item.rankers);
    setTitle(item.titles[0]);
    setDescription(item.descriptions[0]);
    setImageUrl(item.imageUrls[0]);
    setGroupId(item.groupId);
    setStartAt(item.startAt);
    setMode("U");
    const totalPages = Math.ceil(item.rankers.length / 10);
    setRankerTotalPages(totalPages);
  };

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="50%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "20px" }}>
          토너먼트 랭킹
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="10px"
          marginBottom="60px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={rankers}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={rankers}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      {mode === "U" ? (
        <DivStyle
          width="50%"
          height="auto"
          display="flex"
          flex_direction="column"
          align_items="center"
          setMobileForm={true}
        >
          <h2 style={{ color: "white", width: "auto", marginTop: "20px" }}>
            상세정보
          </h2>
          <DivStyle
            width="95%"
            display="flex"
            flex_direction="column"
            borderRadius="10px"
            marginTop="10px"
            marginBottom="20px"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              setMobile={true}
            >
              <DivStyle
                width="50%"
                display="felx"
                flex_direction="column"
                backgroundColor="#1E1E1E"
                setMobileForm={true}
              >
                <SmallForm form={formData.form} title={formData.title} />
              </DivStyle>
              <DivStyle
                marginLeft="20px"
                width="50%"
                height="auto"
                display="flex"
                flex_direction="column"
                backgroundColor="#1E1E1E"
                setMobileForm={true}
                justify_content="center"
                align_items="center"
              >
                <img
                  src={imageUrl}
                  alt="토너먼트"
                  style={{ width: "90%" }}
                ></img>
              </DivStyle>
            </DivStyle>
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="column"
              borderRadius="10px"
              marginTop="10px"
              marginBottom="60px"
              backgroundColor="#1E1E1E"
            >
              <RankerTable
                currentPage={rankerCurrentPage}
                dataList={tournamentRankingList}
                headers={rankersHeaders}
              />
              <Page
                currentPage={rankerCurrentPage}
                totalPages={rankerTotalPages}
                dataList={rankers}
                setCurrentPage={setRankerCurrentPage}
              />
            </DivStyle>
          </DivStyle>
        </DivStyle>
      ) : null}
    </DivStyle>
  );
};

export default TournamentRanking;
