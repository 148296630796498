import DivStyle from "../style/div.component";
import styled from "styled-components";
import { InputFormProps } from "./interface/formInterface";
import ButtonStyle from "../style/button.component";
const Gallery = styled(DivStyle)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const ImageWrapper = styled.div`
  flex: 1 0 25%; // 25% width for each image to fit 4 in a row
  max-width: 25%; // Ensure it does not grow beyond 25%
  box-sizing: border-box;
  padding: 5px;
  position: relative; // For absolute positioning of the delete button
`;
const FormImagesNotDelete = (props: InputFormProps) => {
  const { value, width } = props;
  const { value1 } = value;

  return Array.isArray(value1) && value1.length > 0 ? (
    <Gallery width={width}>
      {value1.map((src: string, index: number) => (
        <ImageWrapper key={index}>
          <img
            src={src}
            style={{ width: "100%", height: "80px" }}
            alt="이미지"
          />
        </ImageWrapper>
      ))}
    </Gallery>
  ) : null;
};

export default FormImagesNotDelete;
