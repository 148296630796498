import { useEffect, useState } from 'react'
import { IOpenChat } from './interface'
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../recoil/loading.atom';
import { FormProps } from '../../components/form/interface/formInterface';
import DivStyle from '../../components/style/div.component';
import YoutubeTable from '../../components/table/YoutubeTable';
import Page from '../../components/table/paging';
import SmallForm from '../../components/form/small_form';
import AdminAPI from '../../api/admin';
import API from '../../api/api';
import OpenChatTable from '../../components/table/OpenChatTable';

const OpenChat = () => {
  const [openChatList, setOpenChatList] = useState<IOpenChat[]>([]);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("V");

  const [id, setId] = useState(0);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("")
  const [ord, setOrd] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null)
  const formData: FormProps = {
    title: "상세보기",
    form: [
        {
            value: title,
            setValue: setTitle,
            label: "톡방 제목",
            type: "input",
            width: "100%",
        },
        {
          value: url,
          setValue: setUrl,
          label: "이동 링크",
          type: "input",
          width: "100%",
        },
        {
          value: selectedFile,
          setValue: setSelectedFile,
          label: "사진",
          width: "100%",
          type: "file",
        },
        {
          value: imageUrl,
          setValue: setImageUrl,
          label: "사진",
          width: "100%",
          type: "image",
        },
    ],
  };

  const headers = [
    {
      text: "순번",
      align: "center",
      sortable: false,
      value: "ord",
    },
    {
      text: "제목",
      align: "center",
      sortable: false,
      value: "title",
    },
  ];

  const setDetail = async (item: IOpenChat) => {
    setMode("U");
    setId(item.id);
    setTitle(item.title);
    setUrl(item.url);
    setOrd(item.ord);
    setImageUrl(item.image_url)
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setUrl("");
    setTitle("");
    setOrd(0);
    setImageUrl("")
  };

  const save = async() => {
    const body = {
      url,
      title,
    };
    const formData = new FormData();
    if(selectedFile){
      formData.append("image", selectedFile);
    }
    
    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (window.confirm("저장 하시겠습니까?")) {
        setIsLoading(true);
        try {
          const res_meta = await AdminAPI.updateOpenChat(id,formData);
        if (res_meta && res_meta.status === 200) {
          alert("저장되었습니다.");
          addForm();
          getOpenChats();
        }
          setIsLoading(false)
        }catch(e){
          alert("저장 실패하였습니다.");
          setIsLoading(false)
        }
    }
    setIsLoading(false);
  }

  const add = async() => {
    const body = {
      title,
      url,
  };
  const formData = new FormData();
  if (!selectedFile) {
    alert("이미지를 선택해주세요")
    return;
  }
  formData.append("image", selectedFile);
  formData.append(
    "data",
    new Blob([JSON.stringify(body)], {
      type: "application/json",
    })
  );
  setIsLoading(true);
  try {
    const res = await AdminAPI.registerOpenChat(formData);
    if (res && res.status === 200) {
        alert("신규 등록이 되었습니다.");
        getOpenChats();
        addForm();
    }
    setIsLoading(false);
  }catch(e){
    alert("등록 실패하였습니다.");
    setIsLoading(false);
  }
  }

  const remove = async() => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.removeOpenChat(id);
        if (res && res.status === 200) {
          alert("삭제 되었습니다.");
          getOpenChats();
          addForm();
        }
        setIsLoading(false);
      }catch(e){
        setIsLoading(false);
      }

    }
    setIsLoading(false);
  }

  const getOpenChats = async () => {
    const res = await API.getOpenChatList();
    if (res && res.status === 200) {
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);

      setOpenChatList(res.data);
    }
  };
  useEffect(() => {
    getOpenChats();
  }, []);

  return  <DivStyle
    style={{ overflow: "auto" }}
    backgroundColor="#121212"
    height="100vh"
    display="flex"
    flex_direction="row"
    marginTop="65px"
    setMobile={true}
  >
    <DivStyle
      width="66%"
      height="auto"
      display="flex"
      flex_direction="column"
      align_items="center"
      setMobileTable={true}
    >  
      <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>오픈채팅방 관리</h2>
      <DivStyle
        width="95%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="20px"
      >
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          justify_content="space-between"
          align_items="center"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
          </DivStyle>
        </DivStyle>

        <OpenChatTable
          headers={headers}
          currentPage={currentPage}
          dataList={openChatList}
          setDetail={setDetail}
          setDataList={setOpenChatList}
        />
        <Page
          currentPage={currentPage}
          totalPages={totalPages}
          dataList={openChatList}
          setCurrentPage={setCurrentPage}
        />
      </DivStyle>
    </DivStyle>
    <DivStyle
      width="33%"
      height="auto"
      display="flex"
      flex_direction="column"
      align_items="center"
      setMobileForm={true}
    >
      <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
      <DivStyle
        width="95%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="60px"
      >
        <SmallForm
          form={formData.form}
          title={formData.title}
          save={save}
          add={add}
          mode={mode}
          remove={remove}
        />
      </DivStyle>
    </DivStyle>
  </DivStyle>
}

export default OpenChat