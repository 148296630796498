import { useEffect, useState } from "react";
import AdminAPI from "../../api/admin";
import { NewsCategoryResponse } from "./interface";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import { FormProps } from "../../components/form/interface/formInterface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

const NewsCategory = () => {
  const [dataList, setDataList] = useState<NewsCategoryResponse[]>([]);
  const [searchDataList, setSearchDataList] = useState<NewsCategoryResponse[]>(
    []
  );

  const [mode, setMode] = useState("V");

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const setIsLoading = useSetRecoilState(loadingState);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getList = async () => {
    const res = await AdminAPI.getNewsCategory();
    if (res) {
      console.log(res);
      setDataList(res);
      const totalPages = Math.ceil(res.length / 10);
      setTotalPages(totalPages);
      setSearchDataList(res);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const formData: FormProps = {
    title: "폼",
    form: [
      {
        value: name,
        setValue: setName,
        label: "카테고리",
        type: "input",
        width: "100%",
      },
      {
        value: description,
        setValue: setDescription,
        label: "설명",
        type: "input",
        width: "100%",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "카테고리",
      align: "center",
      sortable: false,
      value: "name",
    },
    {
      text: "내용",
      align: "center",
      sortable: false,
      value: "description",
    },
    {
      text: "생성일",
      align: "start",
      sortable: false,
      value: "createdAt",
    },
  ];

  const setDetail = async (item: NewsCategoryResponse) => {
    setMode("U");
    setId(item.id);
    setName(item.name);
    setDescription(item.description);
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setName("");
    setDescription("");
  };
  const save = async () => {
    const body = {
      name,
      description,
    };

    if (window.confirm("저장 하시겠습니까?")) {
      setIsLoading(true);
      const res_meta = await AdminAPI.updateNewsCategory(body, id);
      if (res_meta) {
        alert("저장되었습니다.");
        addForm();
        getList();
      }
    }
    setIsLoading(false);
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.deleteNewsCategory(id);
      if (res) {
        alert("삭제 되었습니다.");
        getList();
        addForm();
      }
    }
    setIsLoading(false);
  };
  const add = async () => {
    const body = {
      name,
      description,
    };
    setIsLoading(true);
    const res = await AdminAPI.registerNewsCategory(body);
    if (res) {
      alert("신규 등록이 되었습니다.");
      getList();
      addForm();
    }
    setIsLoading(false);
  };
  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: NewsCategoryResponse) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          뉴스 카테고리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={searchDataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchDataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default NewsCategory;
