/* eslint-disable */
import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import API from "../../api/api";
import AdminAPI from "../../api/admin";
import ButtonStyle from "../../components/style/button.component";
import Page from "../../components/table/paging";
import Table from "../../components/table/Table";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { Header } from "../../components/table/interface/interface";
import {
  IFriendRecommend,
  IFriendRecommendUse,
  IMessage,
  IRewardRecord,
  IToPointAndRebuy,
  IUserCount,
  IUserPoints,
} from "./interface/interface";

const DashBoard = () => {
  const [dataList, setDataList] = useState<IUserCount[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const pageSize = 10;
  let totalCount = 0;
  let totalExitCount = 0;
  let totalDayExitCount = 0;
  let totalDailyRecommendCount = 0;
  const headers = [
    { text: "Date", align: "center", sortable: false, value: "date" },
    { text: "신규가입", align: "center", sortable: false, value: "userCount" },
    { text: "탈퇴", align: "center", sortable: false, value: "exitUserCount" },
    {
      text: "당일탈퇴",
      align: "center",
      sortable: false,
      value: "dayExitUserCount",
    },
    {
      text: "친구추천",
      align: "center",
      sortable: false,
      value: "dailyRecommendCount",
    },
    { text: "생성 GP", align: "center", sortable: false, value: "gameMoney" },
    {
      text: "소진 GP",
      align: "center",
      sortable: false,
      value: "useGameMoney",
    },
  ];
  const toPointAndRebuy = (rewardRecord: IRewardRecord): IToPointAndRebuy => {
    switch (rewardRecord.productName) {
      case "게임포인트 200,000 골드":
        return { gameMoney: 200000, rebuy: 0, date: "" };
      case "게임포인트 100,000 골드":
        return { gameMoney: 100000, rebuy: 0, date: "" };
      case "게임포인트 50,000 골드":
        return { gameMoney: 50000, rebuy: 0, date: "" };
      case "게임포인트 30,000 골드":
        return { gameMoney: 30000, rebuy: 0, date: "" };
      case "게임포인트 10,000 골드":
        return { gameMoney: 30000, rebuy: 0, date: "" };
      case "게임포인트 1,000 골드":
        return { gameMoney: 1000, rebuy: 0, date: "" };
      case "리바인권 1개":
        return { gameMoney: 0, rebuy: 1, date: "" };
      case "리바인권 2개":
        return { gameMoney: 0, rebuy: 2, date: "" };
      default:
        return { gameMoney: 0, rebuy: 0, date: "" };
    }
  };
  const getUserCount = async (): Promise<IUserCount[]> => {
    const startDate = "2023-11-13";
    const endDate = new Date().toISOString().slice(0, 10);
    const data = {
      startDate,
      endDate,
    };
    const [userCounts, exitList, dayExitList, dailyRecommendList] =
      await Promise.all([
        API.getUserCount(data),
        getExitUserCount(),
        getDayExitUserCount(),
        getDailyRecommendCount(),
      ]);
    const list = userCounts.sort(
      (a: IUserCount, b: IUserCount) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const start: Date = new Date(list[0].date);
    const end: Date = new Date(endDate);
    const oneDay = 24 * 60 * 60 * 1000;

    const count = Math.round((end.getTime() - start.getTime()) / oneDay);
    const sortedFullList: IUserCount[] = [
      {
        date: "Total (Avg.)",
        userCount: 0,
        exitUserCount: 0,
        dayExitUserCount: 0,
        dailyRecommendCount: 0,
        gameMoney: 0,
        useGameMoney: 0,
        point: 0,
        usePoint: 0,
      },
    ];

    for (let index = count; index >= 0; index--) {
      const currentDate = new Date(start.getTime() + index * oneDay);
      const currentDateStr = currentDate.toISOString().slice(0, 10);
      const existingItem = list.find(
        (item: IUserCount) => item.date === currentDateStr
      );
      const exitUserItem = exitList.find(
        (item: IUserCount) => item.date === currentDateStr
      );
      const dayExitUserItem = dayExitList.find(
        (item: IUserCount) => item.date === currentDateStr
      );
      const dailyRecommendItem = dailyRecommendList.find(
        (item: IUserCount) => item.date === currentDateStr
      );
      const exitUserCount = exitUserItem ? exitUserItem.userCount : 0;
      const dayExitUserCount = dayExitUserItem ? dayExitUserItem.userCount : 0;
      const dailyRecommendCount = dailyRecommendItem
        ? dailyRecommendItem.userCount
        : 0;
      if (existingItem) {
        existingItem.exitUserCount = exitUserCount;
        existingItem.dayExitUserCount = dayExitUserCount;
        existingItem.dailyRecommendCount = dailyRecommendCount;
        sortedFullList.push(existingItem);
      } else {
        sortedFullList.push({
          date: currentDateStr,
          userCount: 0,
          exitUserCount: exitUserCount,
          dayExitUserCount: dayExitUserCount,
          dailyRecommendCount: dailyRecommendCount,
          gameMoney: 0,
          useGameMoney: 0,
          point: 0,
          usePoint: 0,
        });
      }
    }

    totalCount = 0;
    totalExitCount = 0;
    totalDayExitCount = 0;
    totalDailyRecommendCount = 0;
    dailyRecommendList.map((data) => {
      totalDailyRecommendCount += +data.userCount;
      return totalDailyRecommendCount;
    });
    dayExitList.map((data) => {
      totalDayExitCount += +data.userCount;
      return totalDayExitCount;
    });
    exitList.map((data) => {
      totalCount += +data.userCount;
      totalExitCount += +data.userCount;
      return totalExitCount;
    });
    sortedFullList.map((data) => {
      totalCount += +data.userCount;
      return totalCount;
    });

    return sortedFullList;
  };

  const getExitUserCount = async (): Promise<IUserCount[]> => {
    const startDate = "2024-06-13";
    const endDate = new Date().toISOString().slice(0, 10);
    const data = {
      startDate,
      endDate,
    };
    const userCounts = (await API.getExitUserCount(data)).sort(
      (a: IUserCount, b: IUserCount) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    return userCounts;
  };

  const getDayExitUserCount = async (): Promise<IUserCount[]> => {
    const startDate = "2024-06-13";
    const endDate = new Date().toISOString().slice(0, 10);
    const data = {
      startDate,
      endDate,
    };
    const userCounts = (await API.getDayExitUserCount(data)).sort(
      (a: IUserCount, b: IUserCount) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    return userCounts;
  };

  const getDailyRecommendCount = async (): Promise<IUserCount[]> => {
    const startDate = "2024-06-13";
    const endDate = new Date().toISOString().slice(0, 10);
    const data = {
      startDate,
      endDate,
    };
    const userCounts = (await API.getDailyRecommendCount(data)).sort(
      (a: IUserCount, b: IUserCount) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    return userCounts;
  };

  const exportToExcel = async (headers: Header[], items: IUserCount[]) => {
    const today = new Date().toISOString().split("T")[0];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    worksheet.addRow(headers.map((header) => header.text));

    items.forEach((item: IUserCount | any) => {
      const rowValues = headers.map((header) => {
        if (header.value === "createdAt") {
          return item[header.value].split("T")[0];
        } else {
          return item[header.value];
        }
      });
      worksheet.addRow(rowValues);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "all_report" + "_" + today + ".xlsx");
  };
  const rewardRecordTlSlice = async () => {
    const res = await AdminAPI.getRewardRecords();
    return res.map((obj: IRewardRecord) => {
      const result: IToPointAndRebuy = toPointAndRebuy(obj);
      result.date = obj.receiveDate.slice(0, 10);
      return result;
    });
  };
  const numberFormat = (value: number) => {
    const NUMBER_FORMAT_REGX = /\B(?=(\d{3})+(?!\d))/g;
    return value.toString().replace(NUMBER_FORMAT_REGX, ",");
  };

  const getUserStatistics = async () => {
    try {
      const [
        userList,
        friendRecommendList,
        friendRecommendUseList,
        rewardRecordList,
        userPointList,
        messageList,
      ] = await Promise.all([
        getUserCount(),
        API.getFriendRecommend(),
        API.getFriendRecommendUse(),
        rewardRecordTlSlice(),
        AdminAPI.getUserPoint(),
        AdminAPI.getMessageList(),
      ]);

      let totalGameMoney = 800000000;
      let useGameMoney = 0;
      let totalRP = 0;
      let totalUseRP = 0;
      console.log(messageList.length);
      for (let index = 1; index < userList.length; index++) {
        const currentDateStr = userList[index].date;
        let currentGameMoney = 0;
        let currentUseMoney = 0;
        let currentRP = 0;
        let currentUseRP = 0;

        const currentDateFriendList =
          friendRecommendList?.filter(
            (item: IFriendRecommend) => item.date === currentDateStr
          ) || [];

        const currentDateRewardRecordList = rewardRecordList.filter(
          (item: IRewardRecord) => item.date === currentDateStr
        );
        const currentDateFriendRecommendUseList =
          friendRecommendUseList?.filter(
            (item: IFriendRecommendUse) => item.date === currentDateStr
          ) || [];
        const currentDateUserPointList =
          userPointList?.filter(
            (item: IUserPoints) => item.date === currentDateStr
          ) || [];
        const currentDateMessageList = messageList.filter(
          (item: IMessage) => item.date === currentDateStr
        );

        currentDateFriendList.forEach((obj: IFriendRecommend) => {
          currentGameMoney += obj.gameMoney;
        });
        currentDateRewardRecordList.forEach((obj: IRewardRecord) => {
          currentGameMoney += obj.gameMoney;
        });

        currentDateFriendRecommendUseList.forEach(
          (obj: IFriendRecommendUse) => {
            currentUseMoney += obj.gameMoney;
          }
        );

        currentDateUserPointList.forEach((obj: IUserPoints) => {
          currentRP += +obj.points + +obj.usePoints;
        });
        currentDateMessageList.forEach((obj: IMessage) => {
          currentUseRP += +obj.points;
        });

        userList[index].gameMoney = numberFormat(currentGameMoney);
        userList[index].useGameMoney = numberFormat(currentUseMoney);
        userList[index].point = numberFormat(currentRP);
        userList[index].usePoint = numberFormat(currentUseRP);
        totalGameMoney += currentGameMoney;
        useGameMoney += currentUseMoney;
        totalRP += currentRP;
        totalUseRP += currentUseRP;
      }

      userList[0].userCount = numberFormat(totalCount);
      userList[0].exitUserCount = numberFormat(totalExitCount);
      userList[0].dayExitUserCount = numberFormat(totalDayExitCount);
      userList[0].dailyRecommendCount = numberFormat(totalDailyRecommendCount);
      userList[0].gameMoney = numberFormat(totalGameMoney);
      userList[0].useGameMoney = numberFormat(useGameMoney);
      userList[0].point = numberFormat(totalRP);
      userList[0].usePoint = numberFormat(totalUseRP);
      setDataList(userList);
      const totalPages = Math.ceil(userList.length / pageSize);
      setTotalPages(totalPages);
      setDataList(userList);
    } catch (error) {
      console.log(error);
    }
  };

  const getPubGradesClosingInThreeDays = async () => {
    const res = await AdminAPI.getPubGradesClosingInThreeDays();

    if (res.length > 0) {
      alert(res + " 플랜 종료일 3일 이내로 남았습니다.");
    }
  };

  useEffect(() => {
    getUserStatistics();
    getPubGradesClosingInThreeDays();
  }, []);
  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
      >
        <h2 style={{ color: "white", width: "95%", marginTop: "40px" }}>
          RunnerRunner DashBoard
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonStyle
              fontWeight="700"
              backgroundColor="#272727"
              border="none"
              borderRadius="7px"
              marginTop="20px"
              width="85px"
              height="40px"
              color="white"
              marginRight="20px"
              onClick={() => exportToExcel(headers, dataList)}
            >
              TOEXCEL
            </ButtonStyle>
          </div>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
    </>
  );
};

export default DashBoard;
