import { apiRequest } from "../../utils/apiUtils";
import { DateRangeParams } from "../../types";

export const reportApi = {
  getUserCount: (params: DateRangeParams) =>
    apiRequest<any>("get", "/report/user_statistics", { params }),
  getExitUserCount: (params: DateRangeParams) =>
    apiRequest<any>("get", "/report/exit_users", { params }),
  getDayExitUserCount: (params: DateRangeParams) =>
    apiRequest<any>("get", "/report/day_exit_users", { params }),
  getDailyRecommendCount: (params: DateRangeParams) =>
    apiRequest<any>("get", "/report/daily_recommend", { params }),
};
