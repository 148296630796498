import { useEffect, useState } from "react";
import API from "../../api/api";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import Form from "../../components/form/form";
import AdminAPI from "../../api/admin";
import GroupDialog from "../../components/dialog/GroupDialog";
import ButtonStyle from "../../components/style/button.component";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { ITournament, ITournamentGroup } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const Tournament = () => {
  const [tournamentList, setTournamentList] = useState<ITournament[]>([]);
  const [searchTournamentList, setSearchTournamentList] = useState<
    ITournament[]
  >([]);
  const [title, setTitle] = useState("");
  const [tournamentId, setTournamentId] = useState(0);
  const [startAt, setStartAt] = useState("");
  const [description, setDescription] = useState("");
  const [groupId, setGroupId] = useState(0);
  const [startedAt, setStartedAt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tournamentGroup, setTournamentGroup] = useState("");
  const setIsLoading = useSetRecoilState(loadingState);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");

  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: tournamentGroup,
        setValue: setTournamentGroup,
        label: "토너먼트 그룹",
        width: "100%",
        type: "select",
        options: [
          {
            value: "하트",
          },
          {
            value: "스페이드",
          },
        ],
      },
      {
        value: title,
        setValue: setTitle,
        label: "토너먼트명",
        width: "100%",
        type: "input",
      },
      {
        value: isOpen,
        setValue: setIsOpen,
        label: "공개 여부",
        width: "100%",
        type: "check",
      },
      {
        value: groupId,
        setValue: setGroupId,
        label: "그룹ID 매핑",
        width: "100%",
        type: "search-group",
        setIsOpen: setDialogOpen,
        isOpen: dialogOpen,
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "썸네일 사진을 첨부해주세요.",
        width: "100%",
        type: "file",
      },
      {
        value: imageUrl,
        setValue: setImageUrl,
        label: "이미지",
        width: "100%",
        type: "image",
      },
      {
        value: description,
        setValue: setDescription,
        label: "토너먼트 설명",
        width: "100%",
        type: "text",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "토너먼트 그룹",
      align: "center",
      sortable: false,
      value: "tournamentGroup",
    },
    {
      text: "토너먼트명",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "그룹ID",
      align: "center",
      sortable: false,
      value: "groupId",
    },
    {
      text: "공개여부",
      align: "center",
      sortable: false,
      value: "isOpen",
    },
    {
      text: "시작일시",
      align: "center",
      sortable: false,
      value: "startAt",
    },
  ];

  const tournamentGroups: ITournamentGroup[] = [
    {
      type: "S",
      value: "스페이드",
    },
    {
      type: "H",
      value: "하트",
    },
  ];

  const tournamentGroupsType = (value: string) => {
    let rtn = "";
    if (value) {
      rtn =
        tournamentGroups.find(
          (element: ITournamentGroup) => element.value === value
        )?.type || "";
    }
    return rtn;
  };
  const addForm = () => {
    setMode("I");
    setTournamentId(0);
    setTitle("");
    setDescription("");
    setGroupId(0);
    setIsOpen(false);
    setStartAt("");
    setImageUrl("");
    setTournamentGroup("");
    setSelectedFile(null);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    if (title) {
      const group = tournamentGroupsType(tournamentGroup);
      let startAtFormat = "";
      if (startedAt) {
        startAtFormat = formatDate(startedAt);
      }

      const body = {
        title,
        description,
        groupId,
        isOpen,
        startAt,
        endAt: startAtFormat,
        tournamentGroup: group,
      };
      const formData: FormData = new FormData();
      if (selectedFile) {
        formData.append("image", selectedFile);
      } else {
        formData.append("image", "");
      }
      formData.append(
        "data",
        new Blob([JSON.stringify(body)], {
          type: "application/json",
        })
      );

      if (window.confirm("신규등록 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.registerTournament(formData);
        if (res) {
          if (res.status === 200) {
            alert("신규 등록 되었습니다.");
            getTournamentList();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      if (tournamentId) {
        const res = await AdminAPI.removeTournament(tournamentId);
        if (res) {
          if (res.status === 200) {
            alert("삭제되었습니다.");
            getTournamentList();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };

  const save = async () => {
    if (tournamentId > 0) {
      const group = tournamentGroupsType(tournamentGroup);
      const body = {
        id: tournamentId,
        title,
        description,
        groupId,
        startAt,
        isOpen,
        tournamentGroup: group,
      };
      console.log(body);
      let formData = null;
      if (selectedFile) {
        formData = new FormData();
        formData.append("addImageFile", selectedFile);
        formData.append(
          "deleteImage",
          new Blob(
            [
              JSON.stringify({
                tournamentId: tournamentId,
                deleteImageUrl: imageUrl,
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
      }

      if (formData === null) {
        if (window.confirm("수정 하시겠습니까?")) {
          setIsLoading(true);
          const res = await AdminAPI.updateTournamentMetaData(body);
          if (res) {
            if (res.status === 200) {
              alert("수정되었습니다.");
              getTournamentList();
              addForm();
            } else {
              alert("수정에 실패하였습니다.");
            }
          }
          setIsLoading(false);
        }
        return;
      }

      if (window.confirm("수정 하시겠습니까?")) {
        setIsLoading(true);
        const res_image = await AdminAPI.changeTournamentImage(formData);
        if (res_image) {
          if (res_image.status === 200) {
            const res = await AdminAPI.updateTournamentMetaData(body);
            if (res) {
              if (res.status === 200) {
                alert("수정되었습니다.");
                getTournamentList();
                addForm();
              } else {
                alert("수정에 실패하였습니다.");
              }
            }
          } else {
            alert("수정에 실패하였습니다.");
          }
          setIsLoading(false);
        }
      }
    }
  };

  const detail = (item: ITournament) => {
    setMode("U");
    setTournamentId(item.id);
    setTitle(item.title);
    setDescription(item.description);
    setGroupId(item.groupId);
    setIsOpen(item.isOpen);
    setStartAt(item.startAt);
    setImageUrl(item.imageUrl);
    setTournamentGroup(item.tournamentGroup);
    setSelectedFile(null);
  };
  const tournamentGroupsName = (value: string) => {
    let rtn = "";
    if (value) {
      rtn =
        tournamentGroups.find(
          (element: ITournamentGroup) => element.type === value
        )?.value || "";
    }
    return rtn;
  };

  const getTournamentList = async () => {
    const data = await API.getTournament();
    const updatedData = data.map((item: ITournament) => ({
      ...item,
      tournamentGroup: tournamentGroupsName(item.tournamentGroup),
      isOpen: item.isOpen.toString(),
      startAt: formatDate(item.startAt),
    }));
    const totalPages = Math.ceil(updatedData.length / 10);
    setTotalPages(totalPages);
    setTournamentList(updatedData);
    setSearchTournamentList(updatedData);
  };
  useEffect(() => {
    getTournamentList();
  }, []);

  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    if (!search.trim()) {
      setSearchTournamentList(tournamentList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(tournamentList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = tournamentList.filter((item: ITournament) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchTournamentList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          토너먼트 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchTournamentList}
            setDetail={detail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchTournamentList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <Form
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
      {dialogOpen ? (
        <GroupDialog
          setGroupId={setGroupId}
          setStartedAt={setStartAt}
          setIsOpen={setDialogOpen}
        />
      ) : null}
    </DivStyle>
  );
};

export default Tournament;
