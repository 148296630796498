import { adminApiRequest } from "../../utils/apiUtils";

export const TournamentAPI = {
  registerTournament: (formData: FormData) => {
    return adminApiRequest
      .post("/tournament/register", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  removeTournament: (tournamentId: number) => {
    return adminApiRequest
      .delete(`/tournament/exit?tournamentId=${tournamentId}`)
      .then((res) => res);
  },

  updateTournamentMetaData: (body: any) => {
    return adminApiRequest
      .put("/tournament/update-metadata", body)
      .then((res) => res);
  },

  changeTournamentImage: (formData: FormData) => {
    return adminApiRequest
      .put("/tournament/update-image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  getRankingPointLog: () => {
    return adminApiRequest.get("/tournament/logList").then((res) => res.data);
  },

  getRankingPoint: (type: string) => {
    return adminApiRequest
      .get(`/tournament/rankPointList?type=${type}`)
      .then((res) => res.data);
  },

  removeRankingPoint: (id: number) => {
    return adminApiRequest
      .delete(`/tournament/rankPoint?id=${id}`)
      .then((res) => res);
  },

  updateRankingPoint: (body: any) => {
    return adminApiRequest
      .put("/tournament/rankPoint", body)
      .then((res) => res);
  },

  registerRankingPoint: (body: any) => {
    return adminApiRequest
      .post("/tournament/rankPoint", body)
      .then((res) => res);
  },
};
