import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import AreaForm from "./form_area";
import FormCafeCheckInput from "./form_cafe_check";
import FormCategory from "./form_category";
import FormCheckInput from "./form_check";
import FormCheckDayInput from "./form_check_day";
import CouponListForm from "./form_coupon_list";
import FormDate from "./form_date_time";
import DoubleInputForm from "./form_double_input";
import FormEditor from "./form_editor";
import FormNoticeEditor from "./form_editor_notice";
import EmPloyerForm from "./form_employer";
import EmPloyerDashForm from "./form_employer_dash";
import FacilityForm from "./form_facility";
import FormFileInput from "./form_file";
import FormFilesVideoInput from "./form_file_video";
import FormFilesInput from "./form_files";
import FormImage from "./form_image";
import FormImages from "./form_images";
import FormImagesNotDelete from "./form_images_not_delete";
import FormImagesSort from "./form_images_sort";
import FormInput from "./form_input";
import FormInputButton from "./form_input_button";
import FormInputColor from "./form_input_color";
import FormInputDash from "./form_input_dash";
import LocationForm from "./form_location";
import LocationNotSearchForm from "./form_location_not_search";
import OperatingDaysForm from "./form_operating_day";
import PhoneForm from "./form_phone";
import PhoneDashForm from "./form_phone_dash";
import FormPrize from "./form_prize";
import PushCountForm from "./form_push_count";
import SearchForm from "./form_search";
import SearchGroupForm from "./form_search_group";
import FormSelect from "./form_select";
import FormTextArea from "./form_text";
import FormVideo from "./form_video";
import {
  FormProps,
  FormTempInterface,
  FormTypeProps,
} from "./interface/formInterface";

const FormTemp = (props: FormTempInterface) => {
  const { data } = props;
  const { value, label, setValue, width, type, options } = data;
  switch (type) {
    case "input":
      return (
        <FormInput
          height="20px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInput>
      );
    case "input-dash":
      return (
        <FormInputDash
          height="20px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInputDash>
      );
    case "text":
      return (
        <FormTextArea
          height="100px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormTextArea>
      );
    case "emp-form":
      return (
        <EmPloyerForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></EmPloyerForm>
      );
    case "input-color":
      return (
        <FormInputColor
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInputColor>
      );
    case "emp-dash-form":
      return (
        <EmPloyerDashForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></EmPloyerDashForm>
      );
    case "push-count":
      return (
        <PushCountForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        />
      );
    case "check":
      return (
        <FormCheckInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormCheckInput>
      );
    case "cafe-check":
      return (
        <FormCafeCheckInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormCafeCheckInput>
      );
    case "date":
      return (
        <FormDate
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormDate>
      );
    case "file":
      return (
        <FormFileInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          currentImageUrl={data.currentImageUrl} // 추가
        ></FormFileInput>
      );
    case "file-video":
      return (
        <FormFilesVideoInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormFilesVideoInput>
      );
    case "select":
      return (
        <FormSelect
          options={options}
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormSelect>
      );
    case "search-group":
      return (
        <SearchGroupForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          isOpen={data.isOpen}
          setIsOpen={data.setIsOpen}
        ></SearchGroupForm>
      );
    case "image":
      return (
        <FormImage
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImage>
      );
    case "video":
      return (
        <FormVideo
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormVideo>
      );
    case "phone-form":
      return (
        <PhoneForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></PhoneForm>
      );
    case "phone-dash-form":
      return (
        <PhoneDashForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></PhoneDashForm>
      );
    case "area-form":
      return (
        <AreaForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          options={options}
        ></AreaForm>
      );
    case "location-form":
      return (
        <LocationForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          isOpen={data.isOpen}
          setIsOpen={data.setIsOpen}
        ></LocationForm>
      );
    case "facility-form":
      return (
        <FacilityForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FacilityForm>
      );
    case "prize":
      return (
        <FormPrize
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormPrize>
      );
    case "double-input":
      return (
        <DoubleInputForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></DoubleInputForm>
      );
    case "operating-day-check":
      return (
        <FormCheckDayInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormCheckDayInput>
      );
    case "operating-day-form":
      return (
        <OperatingDaysForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></OperatingDaysForm>
      );
    case "images":
      return (
        <FormImages
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImages>
      );
    case "images-sort":
      return (
        <FormImagesSort
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        />
      );
    case "images-not-delete":
      return (
        <FormImagesNotDelete
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImagesNotDelete>
      );
    case "files":
      return (
        <FormFilesInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          currentImageUrls={data.currentImageUrls} // 추가
          multiple={data.multiple} // 추가
        ></FormFilesInput>
      );
    case "location-not-search-form":
      return (
        <LocationNotSearchForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></LocationNotSearchForm>
      );
    case "search-form":
      return (
        <SearchForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></SearchForm>
      );
    case "input-button":
      return (
        <FormInputButton
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInputButton>
      );
    case "editor":
      return (
        <FormEditor
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormEditor>
      );
    case "notice-editor":
      return (
        <FormNoticeEditor
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormNoticeEditor>
      );
    case "category":
      return (
        <FormCategory
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          options={options}
        ></FormCategory>
      );
    case "coupon-list":
      return (
        <CouponListForm
          value={value}
          label={label}
          width={width}
          setValue={setValue}
        />
      );
  }
  return null;
};
const SmallForm = (props: FormProps) => {
  const { form, title, add, remove, mode, save } = props;
  return (
    <DivStyle
      backgroundColor="#1E1E1E"
      width="100%"
      height="calc(auto + 10px)"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "30px",
        }}
      >
        {title}
      </span>
      {form.map((item: FormTypeProps, index: number) => (
        <div key={index} style={{ width: "90%", marginBottom: "20px" }}>
          <FormTemp data={item}></FormTemp>
        </div>
      ))}
      {mode
        ? mode !== "NU" &&
          mode !== "REPORT" && (
            <div>
              <ButtonStyle
                onClick={() =>
                  mode === "U" ? save() : mode === "S" ? save() : add()
                }
                marginBottom="30px"
                width="70px"
                height="40px"
                border="none"
                backgroundColor="#1976D2"
                borderRadius="5px"
                color="white"
              >
                {mode === "U"
                  ? "저장"
                  : mode === "S"
                  ? "저장"
                  : mode === "C"
                  ? "지급 취소"
                  : "등록"}
              </ButtonStyle>
              {mode === "U" ? (
                <ButtonStyle
                  marginLeft="10px"
                  onClick={() => remove()}
                  marginBottom="30px"
                  width="70px"
                  height="40px"
                  border="none"
                  backgroundColor="#DD2C00"
                  borderRadius="5px"
                  color="white"
                >
                  삭제
                </ButtonStyle>
              ) : null}
            </div>
          )
        : null}
      {mode === "NU" && (
        <ButtonStyle
          marginLeft="10px"
          onClick={() => remove()}
          marginBottom="30px"
          width="70px"
          height="40px"
          border="none"
          backgroundColor="#DD2C00"
          borderRadius="5px"
          color="white"
        >
          삭제
        </ButtonStyle>
      )}

      {mode === "REPORT" && (
        <div>
          <ButtonStyle
            marginLeft="10px"
            onClick={() => save()}
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#1976D2"
            borderRadius="5px"
            color="white"
          >
            처리
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            onClick={() => remove()}
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#DD2C00"
            borderRadius="5px"
            color="white"
          >
            삭제
          </ButtonStyle>
        </div>
      )}
    </DivStyle>
  );
};

export default SmallForm;
