import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Login from "./pages/Login";
import DashBoard from "./pages/DashBoard/DashBoard";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Main from "./components/Main";
import Tournament from "./pages/tournament/Tournament";
import TournamentRanking from "./pages/TournamentRanking/TournamentRanking";
import BrandCompetition from "./pages/brandCompetition/brandCompetition";
import Cafe from "./pages/cafe/cafe";
import PopupNotice from "./pages/PopupNotice/PopupNotice";
import Notice from "./pages/notice/notice";
import Faq from "./pages/faq/faq";
import Affiliate from "./pages/affiliate/affiliate";
import User from "./pages/user/user";
import AppUpdate from "./pages/appUpdate/appUpdate";
import TournamentAdmin from "./pages/tournamentAdmin";
import ReferralSales from "./pages/referralSales/referralSales";
import GameSetting from "./pages/gameSetting/gameSetting";
import Recruiting from "./pages/recruiting/recruiting";
import Advertisement from "./pages/adbertisement/advertisement";
import Roulette from "./pages/roulette/roulette";
import RouletteTicket from "./pages/roulette/rouletteTicket";
import RouletteDashboard from "./pages/roulette/rouletteDashboard";
import HoldemNowMessage from "./pages/holdemNow/holdemNowMessage";
import UserNow from "./pages/holdemNow/userNow";
import UserNowMessage from "./pages/holdemNow/userNowMessage";
import UserNowComment from "./pages/holdemNow/userNowComment";
import EventBanner from "./pages/eventBanner/eventBanner";
import PubReport from "./pages/report/Report";
import Youtube from "./pages/youtube/Youtube";
import OpenChat from "./pages/openChat/openChat";
import AdjustDashBoard from "./pages/adjust/Adjust";
import AutoRankingLog from "./pages/autoRankingLog/autoRankingLog";
import SetRankingPoint from "./pages/setRankingPoint/SetRankingPoint";
import UserRecruiting from "./pages/userRecruiting/UserRecruiting";
import Review from "./pages/review/Review";
import YoutubeChannel from "./pages/youtubeChannel/youtubeChannel";
import SecondHandMarket from "./pages/secondHandMarket/SecondHandMarket";
import SecondHandMarketNotice from "./pages/secondHandMarketNotice/SecondHandMarketNotice";
import SecondHandMarketComment from "./pages/secondHandMarketComment/SecondHandMarketComment";
import News from "./pages/news/News";
import NewsCategory from "./pages/news/NewsCategory";
import BoardNotice from "./pages/boardNotice";
import Article from "./pages/article";
import ArticleReport from "./pages/articleReport";
import ArticleCommentReport from "./pages/articleCommentReport";
import ArticleComment from "./pages/articleComment";
import SecondHandMarketReport from "./pages/secondHandMarketReport";
import SecondHandMarketCommentReport from "./pages/secondHandMarketCommentReport";
import UserJobOpeningReport from "./pages/userJobOpeningReport";
import Coupon from "./pages/coupon";
import GameCoupon from "./pages/gameCoupon";
import IssuedGameCoupon from "./pages/issuedGameCoupon";
import AttendanceTable from "./pages/attendanceTable";
import AttendanceHistory from "./pages/attendanceUser";
import AttendanceReward from "./pages/attendanceReward";
import PubGrade from "./pages/pubGrade";
import PubGradeLog from "./pages/pubGradeLog";
import PushCountLog from "./pages/pushCountLog";
function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>
          {process.env.ENV === "prod"
            ? "[LIVE]러너러너 운영툴"
            : "[TEST]러너러너 운영툴"}
        </title>
      </Helmet>

      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/" element={<DashBoard />} />
            <Route path="/tournament" element={<Tournament />} />
            <Route path="/tournamentRanking" element={<TournamentRanking />} />
            <Route path="/brandCompetition" element={<BrandCompetition />} />
            <Route path="/cafe" element={<Cafe />} />
            <Route path="/popupNotice" element={<PopupNotice />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/affiliate" element={<Affiliate />} />
            <Route path="/user" element={<User />} />
            <Route path="/appUpdate" element={<AppUpdate />} />
            <Route path="/tournamentAdmin" element={<TournamentAdmin />} />
            <Route path="/referralSales" element={<ReferralSales />} />
            <Route path="/gameSetting" element={<GameSetting />} />
            <Route path="/recruiting" element={<Recruiting />} />
            <Route path="/advertisement" element={<Advertisement />} />
            <Route path="/roulette" element={<Roulette />} />
            <Route path="/rouletteTicket" element={<RouletteTicket />} />
            <Route path="/rouletteDashboard" element={<RouletteDashboard />} />
            <Route path="/holdemNowMessage" element={<HoldemNowMessage />} />
            <Route path="/userNow" element={<UserNow />} />
            <Route path="/userNowMessage" element={<UserNowMessage />} />
            <Route path="/userNowComment" element={<UserNowComment />} />
            <Route path="/eventBanner" element={<EventBanner />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/report" element={<PubReport />} />
            <Route path="/youtube" element={<Youtube />} />
            <Route path="/openChat" element={<OpenChat />} />
            <Route path="/adjust" element={<AdjustDashBoard />} />
            <Route path="/autoRankingLog" element={<AutoRankingLog />} />
            <Route path="/setRankingPoint" element={<SetRankingPoint />} />
            <Route path="/user-recruiting" element={<UserRecruiting />} />
            <Route path="/review" element={<Review />} />
            <Route path="/youtube-channel" element={<YoutubeChannel />} />
            <Route path="/market-notice" element={<SecondHandMarketNotice />} />
            <Route path="/market" element={<SecondHandMarket />} />
            <Route
              path="/market-comment"
              element={<SecondHandMarketComment />}
            />
            <Route path="/news" element={<News />} />
            <Route path="/news-category" element={<NewsCategory />} />
            <Route path="/board-notice" element={<BoardNotice />} />
            <Route path="/article" element={<Article />} />
            <Route path="/article-comment" element={<ArticleComment />} />
            <Route path="/article-report" element={<ArticleReport />} />
            <Route
              path="/article-comment-report"
              element={<ArticleCommentReport />}
            />
            <Route
              path="/second-hand-market-report"
              element={<SecondHandMarketReport />}
            />
            <Route
              path="/second-hand-market-comment-report"
              element={<SecondHandMarketCommentReport />}
            />
            <Route
              path="/user-job-opening-report"
              element={<UserJobOpeningReport />}
            />
            <Route path="/coupon" element={<Coupon />} />
            <Route path="/game-coupon" element={<GameCoupon />} />
            <Route path="/issued-game-coupon" element={<IssuedGameCoupon />} />
            <Route path="/attendance-table" element={<AttendanceTable />} />
            <Route path="/attendance-history" element={<AttendanceHistory />} />
            <Route path="/attendance-reward" element={<AttendanceReward />} />
            <Route path="/pub-grade" element={<PubGrade />} />
            <Route path="/pub-grade-log" element={<PubGradeLog />} />
            <Route path="/push-count-log" element={<PushCountLog />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
