import DivStyle from "../../components/style/div.component";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import API from "../../api/api";
import { YoutubeChannelResponse } from "./interface";
import YoutubeChannelTable from "../../components/table/youtubeChannelTable";

const YoutubeChannel = () => {
  const [youtubeList, setYoutubeList] = useState<YoutubeChannelResponse[]>([]);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("V");

  const [id, setId] = useState(0);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [ord, setOrd] = useState(0);
  const [profileUrl, setProfileUrl] = useState("");

  const formData: FormProps = {
    title: "폼",
    form: [
      {
        value: name,
        setValue: setName,
        label: "채널 이름",
        type: "input",
        width: "100%",
      },
      {
        value: url,
        setValue: setUrl,
        label: "이동 경로",
        type: "input",
        width: "100%",
      },
      {
        value: profileUrl,
        setValue: setProfileUrl,
        label: "채널 프로필 이미지 URL",
        type: "input",
        width: "100%",
      },
      {
        value: profileUrl,
        setValue: setProfileUrl,
        label: "채널 프로필 이미지",
        type: "image",
        width: "50%",
      },
    ],
  };

  const headers = [
    {
      text: "순번",
      align: "center",
      sortable: false,
      value: "ord",
    },
    {
      text: "채널 이름",
      align: "center",
      sortable: false,
      value: "name",
    },
    {
      text: "생성일",
      align: "start",
      sortable: false,
      value: "createdAt",
    },
  ];

  const setDetail = async (item: YoutubeChannelResponse) => {
    setMode("U");
    setId(item.id);
    setName(item.name);
    setUrl(item.url);
    setOrd(item.ord);
    setProfileUrl(item.profileUrl);
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setUrl("");
    setName("");
    setOrd(0);
    setProfileUrl("");
  };
  const save = async () => {
    const body = {
      id,
      url,
      name,
      ord,
      profileUrl,
    };

    if (window.confirm("저장 하시겠습니까?")) {
      setIsLoading(true);
      const res_meta = await AdminAPI.updateYoutubeChannel(body);
      if (res_meta && res_meta.status === 200) {
        alert("저장되었습니다.");
        addForm();
        getYoutube();
      }
    }
    setIsLoading(false);
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeYoutubeChannel(id);
      if (res && res.status === 200) {
        alert("삭제 되었습니다.");
        getYoutube();
        addForm();
      }
    }
    setIsLoading(false);
  };
  const add = async () => {
    const body = {
      name,
      url,
      ord: youtubeList.length + 1,
      profileUrl,
    };

    setIsLoading(true);
    const res = await AdminAPI.registerYoutubeChannel(body);
    if (res && res.status === 200) {
      alert("신규 등록이 되었습니다.");
      getYoutube();
      addForm();
    }

    setIsLoading(false);
  };

  const getYoutube = async () => {
    const res = await API.getYoutubeChannelList();
    if (res && res.status === 200) {
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);

      setYoutubeList(res.data);
    }
  };
  useEffect(() => {
    getYoutube();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          유튜브 채널
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <YoutubeChannelTable
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={youtubeList}
            setDetail={setDetail}
            setDataList={setYoutubeList}
            getDatas={getYoutube}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={youtubeList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default YoutubeChannel;
