import { useEffect, useState } from "react";
import { SecondHandMarketResponse } from "./interface/SecondHandMarketResponse";
import { FormProps } from "../../components/form/interface/formInterface";
import API from "../../api/api";
import SmallForm from "../../components/form/small_form";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import AdminAPI from "../../api/admin";

const SecondHandMarket = () => {
  const [dataList, setDataList] = useState<SecondHandMarketResponse[]>([]);
  const [searchDataList, setSearchDataList] = useState<
    SecondHandMarketResponse[]
  >([]);

  const [mode, setMode] = useState("I");

  const [id, setId] = useState<number | null>(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [areaProvinceId, setAreaProvinceId] = useState(0);
  const [areaCityId, setAreaCityId] = useState(0);
  const [areaStreetId, setAreaStreetId] = useState(0);
  const [imageList, setImageList] = useState<string[]>([]);
  const [isOnSale, setIsOnSale] = useState(true);
  const [price, setPrice] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [userName, setUserName] = useState("");

  const [areaProvinces, setAreaProvinces] = useState([]);
  const [areaCities, setAreaCities] = useState([]);
  const [areaStreets, setAreaStreets] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const [selectedFile, setSelectedFile] = useState([]);
  const [deleteImages, setDeleteImage] = useState([]);

  const setIsLoading = useSetRecoilState(loadingState);
  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "제목",
        width: "100%",
        type: "input",
      },
      {
        value: {
          value1: areaProvinceId,
          value2: areaCityId,
          value3: areaStreetId,
          setValue2: setAreaCityId,
          setValue3: setAreaStreetId,
          options2: areaCities,
          options3: areaStreets,
          setOptions2: setAreaCities,
          setOptions3: setAreaStreets,
          label2: "군/구",
          label3: "동",
        },
        setValue: setAreaProvinceId,
        label: "시/도",
        width: "100%",
        type: "area-form",
        options: areaProvinces,
      },

      {
        value: price,
        setValue: setPrice,
        label: "가격",
        width: "100%",
        type: "input",
      },
      {
        value: message,
        setValue: setMessage,
        label: "내용",
        width: "100%",
        type: "text",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "사진",
        width: "100%",
        type: "files",
      },
      {
        value: {
          value1: imageList,
          setDeleteImage,
        },
        setValue: setImageList,
        label: "사진",
        width: "100%",
        type: "images",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "제목",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "작성자",
      align: "center",
      sortable: false,
      value: "username",
    },
    {
      text: "작성자 ID",
      align: "center",
      sortable: false,
      value: "textId",
    },
    {
      text: "내용",
      align: "center",
      sortable: false,
      value: "message",
    },
    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];

  const addForm = async () => {
    setId(0);
    setMode("I");
    setTitle("");
    setMessage("");
    setUserName("");
    setImageList([]);
    setIsOnSale(true);
    setPrice(0);
    setCreatedAt("");
    setAreaCityId(0);
    setAreaProvinceId(0);
    setAreaStreetId(0);
    const area_res = await API.getAreaProvince();
    if (area_res && area_res.status === 200) {
      setAreaProvinces(area_res.data);
    }
  };

  const save = async () => {
    if (!id) {
      return;
    }
    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("message", message);
      formData.append("areaProvinceId", areaProvinceId.toString());
      formData.append("areaCityId", areaCityId.toString());
      formData.append("areaStreetId", areaStreetId.toString());
      formData.append("price", price.toString());
      formData.append("onSale", isOnSale.toString());
      for (const img of selectedFile) {
        formData.append("imageList", img);
      }

      if (deleteImages.length > 0) {
        deleteImages.forEach((image) => {
          formData.append("deleteImageList", image);
        });
      }

      const res = await AdminAPI.updateMarket(formData, id);
      if (res && res.status === 200) {
        alert("수정되었습니다.");
        setSelectedFile([]);
        getMarkets();
        setIsLoading(false);
        addForm();
      } else {
        alert("수정에 실패하였습니다");
        setIsLoading(false);
      }
    }
  };

  const add = async () => {
    if (window.confirm("신규 등록 하시겠습니까?")) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("message", message);
      formData.append("areaProvinceId", areaProvinceId.toString());
      formData.append("areaCityId", areaCityId.toString());
      formData.append("areaStreetId", areaStreetId.toString());
      formData.append("price", price.toString());
      for (const img of selectedFile) {
        formData.append("imageList", img);
      }

      const res = await AdminAPI.registerMarket(formData);
      if (res && res.status === 200) {
        alert("신규 등록되었습니다.");
        setSelectedFile([]);
        getMarkets();
        setIsLoading(false);
        addForm();
      } else {
        alert("등록에 실패하였습니다");
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        const res = await AdminAPI.removeMarket(id);
        if (res) {
          if (res.status === 200) {
            alert("삭제되었습니다.");
            getMarkets();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };

  const setDetail = (item: SecondHandMarketResponse) => {
    setId(item.id);
    setMode("NU");
    setTitle(item.title);
    setMessage(item.message);
    setUserName(item.username);
    setImageList(item.imageList);
    setIsOnSale(item.onSale);
    setPrice(item.price);
    setCreatedAt(item.createdAt);
    setAreaCityId(item.areaCityId);
    setAreaProvinceId(item.areaProvinceId);
    setAreaStreetId(item.areaStreetId);
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getMarkets = async () => {
    const res = await API.getMarket();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: SecondHandMarketResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      console.log(updatedData);
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setDataList(updatedData);
      setSearchDataList(updatedData);
    }

    const area_res = await API.getAreaProvince();
    if (area_res && area_res.status === 200) {
      setAreaProvinces(area_res.data);
    }
  };

  useEffect(() => {
    getMarkets();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          중고 장터
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SecondHandMarket;
