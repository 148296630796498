import { useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import API from "../../api/api";
import AdminAPI from "../../api/admin";

interface AddRecommendProps {
  premiunCafes: [];
  setPremiunCafes: (value: any) => void;
  setIsOpen: (value: any) => void;
}
const AddRecommend = (props: AddRecommendProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState([]);
  const { setPremiunCafes, setIsOpen, premiunCafes } = props;

  const [search, setSearch] = useState("");
  const headers = [
    {
      text: "매장이름",
      align: "center",
      value: "cafeName",
      sortable: false,
    },
    {
      text: "이메일",
      align: "center",
      value: "email",
      sortable: false,
    },
    {
      text: "사업자등록번호",
      align: "center",
      value: "employerId",
      sortable: false,
    },
  ];

  const handleSearch = async () => {
    const res = await API.searchCafe(search);
    if (res && res.status === 200) {
      setDataList(res.data);
    }
  };

  const setData = async () => {
    if (dataList.length > 0 && selected !== "") {
      const data: any = dataList[parseInt(selected)];
      const isInPremiunCafes = premiunCafes.some((premiunCafe: any) => {
        return premiunCafe.cafe.id === data.id; // 가정: 객체들은 고유한 'id' 속성을 가지고 있음
      });
      if (isInPremiunCafes) {
        alert("이미 추가된 카페입니다.");
        return;
      }
      const res = await API.getCafeRecommended(data.id);
      if (res && res.status === 200) {
        if (res.data.cafe) {
          let data: any = res.data;
          data.cafeIndex = premiunCafes.length + 1;
          const pre: any = premiunCafes;
          pre.push(data);
          setPremiunCafes(pre);
          setIsOpen(false);
        } else {
          if (window.confirm("프리미엄 펍으로 등록하시겟습니까?")) {
            const res = await AdminAPI.registerRecommend(data.id);
            if (res) {
              if (res.status === 200) {
                const res_re = await API.getCafeRecommended(data.id);
                if (res_re && res_re.status === 200) {
                  let data: any = res_re.data;
                  data.cafeIndex = premiunCafes.length + 1;
                  const pre: any = premiunCafes;
                  pre.push(data);
                  setPremiunCafes(pre);
                  setIsOpen(false);
                }
              }
            }
          }
        }
      }
    }
  };
  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="#1E1E1E"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>카페 검색</h4>
      <DivStyle
        width="80%"
        display="flex"
        flex_direction="row"
        align_items="center"
      >
        <InputFormStyle
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></InputFormStyle>
        <div onClick={() => handleSearch()}>
          <Icon path={mdiMagnify} size={1} />
        </div>
      </DivStyle>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />

      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default AddRecommend;
