import { useEffect, useState } from "react";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";

const RankerTable = (props: TableProps) => {
  const [isWide, setIsWide] = useState(window.innerWidth > 700);
  const { headers, currentPage, dataList, setDetail } = props;
  const pageSize = 10; // 페이지당 표시할 항목 수
  const getDataForPage = (page: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataList.slice(startIndex, endIndex);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setIsWide(false);
      } else {
        setIsWide(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getNestedData(data: any, path: string) {
    const keys = path.split(".");
    let result = data;
    for (let key of keys) {
      result = result[key];
      if (result === undefined) break;
    }
    return result;
  }

  return isWide ? (
    <TableStyle marginTop="30px">
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
            }}
          >
            {headers.map((header) =>
              header.value.includes(".") ? (
                <td key={header.value}>{getNestedData(data, header.value)}</td>
              ) : (
                <td key={header.value}>{data[header.value]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  ) : (
    <TableStyle setMobile={true} marginTop="30px">
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr
            key={index}
            onClick={() => {
              if (setDetail) setDetail(data);
            }}
          >
            {headers.map((header) => (
              <td key={header.value}>
                <div>{header.text}</div>
                {header.value.includes(".") ? (
                  <div>{getNestedData(data, header.value)}</div>
                ) : (
                  <div>{data[header.value]}</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default RankerTable;
