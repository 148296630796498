import { Location, useLocation, useNavigate } from "react-router-dom";
import { IMenuItem } from "../../interface/menu.interface";
import { useEffect, useState } from "react";
import MenuStyle from "../style/menu.component";
import Icon from "@mdi/react";
import {
  mdiMenuDown,
  mdiApps,
  mdiMessageBulleted,
  mdiBookMultiple,
  mdiFormatListBulleted,
  mdiChartBar,
  mdiMaterialUi,
  mdiCards,
  mdiAccountCard,
  mdiCloudDownload,
  mdiStar,
  mdiNintendoGameBoy,
  mdiYoutube,
  mdiAdvertisements,
  mdiCircle,
  mdiDownload,
  mdiImageFilterFrames,
  mdiBullhorn,
  mdiChat,
  mdiMapMarkerStar,
  mdiShopping,
  mdiNewspaper,
} from "@mdi/js";

const MenuItem = (props: { menu: IMenuItem }) => {
  const { menu } = props;
  const { children } = menu;
  const location = useLocation();
  const navigate = useNavigate();
  const [isMainActive, setIsMainActive] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const iconMap: any = {
    mdiApps,
    mdiMessageBulleted,
    mdiBookMultiple,
    mdiFormatListBulleted,
    mdiChartBar,
    mdiMaterialUi,
    mdiCards,
    mdiAccountCard,
    mdiCloudDownload,
    mdiStar,
    mdiNintendoGameBoy,
    mdiYoutube,
    mdiAdvertisements,
    mdiCircle,
    mdiDownload,
    mdiImageFilterFrames,
    mdiBullhorn,
    mdiChat,
    mdiMapMarkerStar,
    mdiShopping,
    mdiNewspaper,
  };
  const iconPath = iconMap[menu.icon];
  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };
  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();
      return;
    }
    navigate(menu.path ? menu.path : "/");
  };
  const handleItemAction = (url: string | undefined) => {
    navigate(url ? url : "/");
  };
  const checkChildrenActive = (url: string | undefined) => {
    if (location.pathname === url) {
      return true;
    }
    return false;
  };
  const calculateIsActive = (url: Location) => {
    if (children) {
      setIsExpandable(true);
    }
    setIsMainActive(false);
    if (menu.path) {
      if (menu.path === url.pathname) {
        setIsMainActive(true);
      }
    }
  };
  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
  }, [location]);

  return (
    <>
      <MenuStyle
        onClick={() => {
          handleMainMenuAction();
        }}
        isMainActive={isMainActive}
        isMenuExtended={isMenuExtended}
      >
        <div>
          <Icon path={iconPath} size={1} />
        </div>
        <div>{props.menu.name}</div>

        {isExpandable ? (
          <Icon className="menu-icon" path={mdiMenuDown} size={1} />
        ) : (
          ""
        )}
      </MenuStyle>
      {isExpandable &&
        menu &&
        children &&
        isMenuExtended &&
        children.map((item) => (
          <MenuStyle
            isMainActive={checkChildrenActive(item.path)}
            onClick={() => {
              handleItemAction(item.path);
            }}
          >
            <div>
              <Icon path="" size={1} />
            </div>
            <div>{item.name}</div>
          </MenuStyle>
        ))}
    </>
  );
};
export default MenuItem;
