import { authApi } from "./auth";
import { cafeApi } from "./cafe";
import { youtubeApi } from "./youtube";
import { friendApi } from "./friend";
import { tournamentApi } from "./tournament";
import { areaApi } from "./area";
import { noticeApi } from "./notice";
import { reportApi } from "./report";
import { jobOpeningApi } from "./jobOpening";
import { advertisementApi } from "./advertisement";
import { communityApi } from "./community";
import { updateApi } from "./update";
import { marketApi } from "./market";
import { BoardApi } from "./article";

const API = {
  ...authApi,
  ...cafeApi,
  ...youtubeApi,
  ...friendApi,
  ...tournamentApi,
  ...areaApi,
  ...noticeApi,
  ...reportApi,
  ...jobOpeningApi,
  ...advertisementApi,
  ...communityApi,
  ...updateApi,
  ...marketApi,
  ...BoardApi,
};

export default API;
