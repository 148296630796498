import { useEffect, useState } from "react";
import DivStyle from "../style/div.component";
import Table from "../table/Table";
import Page from "../table/paging";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
import SmallForm from "../form/small_form";
import { FormProps } from "../form/interface/formInterface";
import API from "../../api/api";
import { ICafeMessage } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const HoldemNowUpdate = () => {
  const [dataList, setDataList] = useState<ICafeMessage[]>([]);
  const [searchDataList, setSearchDataList] = useState<ICafeMessage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const setIsLoading = useSetRecoilState(loadingState);
  const [pubId, setPubId] = useState(0);
  const [pubName, setPubName] = useState("");
  const [nowMessage, setNowMessage] = useState("");
  const [id, setId] = useState(0);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const formData: FormProps = {
    title: "삭제",
    form: [
      {
        value: pubName,
        setValue: setPubName,
        label: "펍명",
        width: "100%",
        type: "input-dash",
      },
      {
        value: id,
        setValue: setId,
        label: "Holdem NOW ID",
        width: "100%",
        type: "input-dash",
      },
      {
        value: nowMessage,
        setValue: setNowMessage,
        label: "내용을 입력해주세요. (최대 1000자)",
        width: "100%",
        type: "text",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "이미지",
        width: "100%",
        type: "files",
      },
      {
        value: {
          value1: imageUrls,
          setDeleteImage,
        },
        setValue: setImageUrls,
        label: "이미지",
        width: "50%",
        type: "images",
      },
    ],
  };
  const headers = [
    {
      text: "날짜 (최근 순)",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "내용",
      align: "center",
      sortable: true,
      value: "message",
    },
    {
      text: "펍 이름",
      align: "center",
      sortable: true,
      value: "pubName",
    },
    {
      text: "사용 포인트",
      align: "center",
      sortable: false,
      value: "points",
    },
  ];

  const save = async () => {
    if (id > 0) {
      if (window.confirm("저장 하시겠습니까?")) {
        setIsLoading(true);
        const body = {
          pubId,
          nowMessage,
          id,
        };
        const formData = new FormData();

        if (deleteImage.length > 0) {
          formData.append(
            "deleteImages",
            new Blob([JSON.stringify(deleteImage)], {
              type: "application/json",
            })
          );
        }
        formData.append(
          "data",
          new Blob([JSON.stringify(body)], {
            type: "application/json",
          })
        );

        if (selectedFile) {
          for (const img of selectedFile) {
            formData.append("coverImages", img);
          }
        }

        const res = await AdminAPI.updatePubMessage(formData);
        if (res && res.status === 200) {
          alert("저장 되었습니다.");
          addForm();
          getList();
          setIsLoading(false);
          return;
        } else {
          alert("가입되어 있지 않은 사용자입니다.");
          setIsLoading(false);
          return;
        }
      }
    }
  };

  const remove = async () => {
    if (id > 0) {
      if (window.confirm("삭제 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.removeMessage(id, pubId);
        if (res && res.status === 200) {
          alert("삭제되었습니다.");
          addForm();
          getList();
        }
        setIsLoading(false);
      }
    }
  };

  const setDetail = (item: ICafeMessage) => {
    setPubId(item.pubId);
    setPubName(item.pubName);
    setNowMessage(item.message);
    setId(item.id);
    console.log(item.imageList);
    setImageUrls(item.imageList);
    setSelectedFile([]);
    setDeleteImage([]);
  };
  const addForm = () => {
    setPubId(0);
    setPubName("");
    setNowMessage("");
    setId(0);
    setImageUrls([]);
    setSelectedFile([]);
    setDeleteImage([]);
  };
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getList = async () => {
    const res = await API.getAllMessage();
    if (res && res.status === 200) {
      const updatedData = res.data
        .filter((item: ICafeMessage) => item.messageType === "PUB")
        .map((item: ICafeMessage) => ({
          ...item,
          createdAt: formatDate(item.createdAt),
        }));

      setDataList(updatedData);
      const totalPages = Math.ceil(res.data.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(updatedData);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: ICafeMessage) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <DivStyle
      width="100%"
      backgroundColor="#121212"
      height="auto"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="70%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="60px"
        setMobileTable={true}
      >
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          justify_content="end"
          align_items="center"
        >
          <DivStyle
            width="50%"
            display="flex"
            justify_content="flex-end"
            flex_direction="row"
            align_items="center"
            marginRight="20px"
            marginTop="20px"
          >
            <InputFormStyle
              onChange={(e) => handleSearch(e.target.value)}
            ></InputFormStyle>
            <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
          </DivStyle>
        </DivStyle>
        <Table
          headers={headers}
          currentPage={currentPage}
          dataList={searchDataList}
          setDetail={setDetail}
        />
        <Page
          currentPage={currentPage}
          totalPages={totalPages}
          dataList={searchDataList}
          setCurrentPage={setCurrentPage}
        />
      </DivStyle>
      <DivStyle
        width="40%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            remove={remove}
            mode={"NU"}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};
export default HoldemNowUpdate;
