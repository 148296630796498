import { apiRequest } from "../../utils/apiUtils";

export const friendApi = {
  getFriendRecommend: () =>
    apiRequest<any[]>("get", "/friend-recommend/allList", {
      fullResponse: true,
    }).then((response) => {
      if (response && "data" in response) {
        return response.data.map((obj) => ({
          ...obj,
          date: obj.createDt.slice(0, 10),
        }));
      }
      return null;
    }),
  getFriendRecommendUse: () =>
    apiRequest<any[]>("get", "/friend-recommend-use/allList", {
      fullResponse: true,
    }).then((response) => {
      if (response && "data" in response) {
        return response.data.map((obj) => ({
          ...obj,
          date: obj.createDt.slice(0, 10),
        }));
      }
      return null;
    }),
  registerFriendRecommend: (body: any) =>
    apiRequest<any>("post", "/friend-recommend/adminRegister", {
      data: body,
      fullResponse: true,
    }),
  removeFriendRecommend: (body: any) =>
    apiRequest<any>("post", "/friend-recommend/remove", {
      data: body,
      fullResponse: true,
    }),
  getFriendRecommends: () =>
    apiRequest<any>("get", "/friend-recommend/allList", { fullResponse: true }),
  getFriendRecommendsById: (id: number) =>
    apiRequest<any>("get", `/friend-recommend/allListByUserId/${id}`, {
      fullResponse: true,
    }),
};
