/* eslint-disable */
import { Outlet } from "react-router-dom";
import Header from "./Header";
import MenuSidebar from "./MenuSidebar";
import DivStyle from "./style/div.component";
import styled, { keyframes } from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { sideState } from "../recoil/side.atom";
import { loadingState } from "../recoil/loading.atom";

interface SideProps {
  isOpen: boolean;
}
const Overlay = styled.div`
  @media screen and (max-width: 500px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
`;
const MainContent = styled.div<SideProps>`
  margin-left: ${(props) => (props.isOpen ? "250px" : "0px")};
  transition: margin-left 0.3s ease;
  width: 100%;

  @media screen and (max-width: 500px) {
    margin-left: 0px;
    pointer-events: ${(props) => (props.isOpen ? "none" : "auto")};
  }
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 로딩 오버레이 스타일
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // 반투명 검정색
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// 스피너 스타일
const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3); // 회색 테두리
  border-radius: 50%;
  border-top-color: #fff; // 흰색 부분
  width: 50px;
  height: 50px;
  animation: ${rotate} 1s linear infinite;
`;

const Main = () => {
  const [isOpen, setIsOpen] = useRecoilState(sideState);
  const isLoding = useRecoilValue(loadingState);
  const handleBackdropClick = (event: any) => {
    if (window.innerWidth <= 500 && isOpen) {
      setIsOpen(false); // 사이드바가 열려있을 때 백드롭 클릭시 닫기
    }
  };
  return (
    <DivStyle
      width="100%"
      display="flex"
      flex_direction="row"
      backgroundColor="black"
    >
      {isLoding && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <MenuSidebar />
      {isOpen && <Overlay onClick={handleBackdropClick} />}
      <MainContent isOpen={isOpen} onClick={handleBackdropClick}>
        <Header />
        <Outlet />
      </MainContent>
    </DivStyle>
  );
};

export default Main;
