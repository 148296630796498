import { adminApiRequest } from "../../utils/apiUtils";

export const SearchAPI = {
  searchCafes: (name: string, page: number) => {
    return adminApiRequest
      .get("/cafe/findCafe", {
        params: {
          name,
          page,
          size: 10,
        },
      })
      .then((res) => res);
  },
};
