import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import SmallForm from "../../components/form/small_form";
import { AttendanceTableResponse } from "../../api/admin/attendance";

const AttendanceTable = () => {
  const [list, setList] = useState<AttendanceTableResponse[]>([]);
  const [id, setId] = useState<number | null>(null);
  const [startAt, setStartAt] = useState("");
  const [closeAt, setCloseAt] = useState("");
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");

  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: startAt,
        setValue: setStartAt,
        label: "시작일시",
        width: "100%",
        type: "date",
      },
      {
        value: closeAt,
        setValue: setCloseAt,
        label: "종료일시",
        width: "100%",
        type: "date",
      },
    ],
  };

  const headers = [
    {
      text: "id",
      align: "start",
      sortable: false,
      value: "id",
    },
    {
      text: "시작일시",
      align: "center",
      sortable: false,
      value: "startAt",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "closeAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(null);
    setStartAt("");
    setCloseAt("");
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function formatJsonDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    const body = {
      startAt: formatJsonDate(startAt),
      closeAt: formatJsonDate(closeAt),
    };

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerAttendanceTable(body);
        if (res) {
          alert("신규 등록 되었습니다.");
          getTables();
          addForm();
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (!id) {
      alert("설정할 테이블을 선택해주세요.");
      return;
    }
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeAttendanceTable(id);
      if (res) {
        alert("삭제 되었습니다.");
        getTables();
        addForm();
      }
      setIsLoading(false);
    }
  };

  const save = async () => {
    if (!id) {
      alert("설정할 테이블을 선택해주세요.");
      return;
    }
    const body = {
      startAt: formatJsonDate(startAt),
      closeAt: formatJsonDate(closeAt),
    };

    setIsLoading(true);
    try {
      const res = await AdminAPI.updateAttendanceTable(id, body);
      if (res) {
        alert("저장 되었습니다.");
        getTables();
        addForm();
        setIsLoading(false);
      }
    } catch (e: any) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const setDetails = (item: AttendanceTableResponse) => {
    setMode("U");
    setId(item.id);
    setStartAt(item.startAt);
    setCloseAt(item.closeAt);
  };

  const getTables = async () => {
    const res = await AdminAPI.getAttendanceTable();
    if (res) {
      const updatedData = res.map((item: AttendanceTableResponse) => ({
        ...item,
        closeAt: formatDate(item.closeAt),
        startAt: formatDate(item.startAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setList(updatedData);
    }
  };
  useEffect(() => {
    getTables();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          출석 테이블
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={list}
            setDetail={setDetails}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={list}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default AttendanceTable;
