import { useState } from "react";
import InputFormStyle from "../style/input_form.component";
import TextAreaForm from "../style/textarea.component";
import { InputFormProps } from "./interface/formInterface";

const FormTextArea = (props: InputFormProps) => {
  const { value, setValue, label, width, height, fontSize } = props;
  return (
    <div
      style={{
        width,
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      {value ? (
        <label style={{ color: "#FFFFFFB3", fontSize: "14px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "14px" }}>{label}</label>
      )}
      <TextAreaForm
        fontSize={fontSize}
        placeholder={label}
        width="100%"
        height={height}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      ></TextAreaForm>
    </div>
  );
};

export default FormTextArea;
