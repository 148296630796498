import React, { useEffect, useState } from "react";
import { InputFormProps } from "./interface/formInterface";
import SelectFormStyle from "../style/select.component";

interface CategoryOption {
  name: string;
  [key: string]: any;
}

const FormCategory: React.FC<InputFormProps> = ({
  value,
  label,
  width,
  setValue,
  options,
}) => {
  const [category, setCategory] = useState<string>("");

  useEffect(() => {
    if (value && typeof value === "object" && "name" in value) {
      setCategory(value.name);
    } else if (typeof value === "string") {
      setCategory(value);
    } else if (options && options.length > 0) {
      const firstOption = options[0] as CategoryOption;
      setCategory(firstOption.name);
      setValue(firstOption);
    }
  }, [value, options, setValue]);

  return (
    <div
      style={{
        width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ width: "33%", display: "flex", flexDirection: "column" }}>
        <SelectFormStyle
          value={category}
          name="select"
          width="100%"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedName = e.target.value;
            const item = options?.find(
              (option: CategoryOption) => option.name === selectedName
            );
            if (item) {
              setCategory(item.name);
              setValue(item);
            }
          }}
        >
          <option value="" disabled>
            {label}
          </option>
          {options?.map((item: CategoryOption, index: number) => (
            <option value={item.name} key={index}>
              {item.name}
            </option>
          ))}
        </SelectFormStyle>
      </div>
    </div>
  );
};

export default FormCategory;
