import { useEffect, useState } from "react";
import DivStyle from "../style/div.component";
import Table from "../table/Table";
import Page from "../table/paging";
import API from "../../api/api";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
import SmallForm from "../form/small_form";
import { FormProps } from "../form/interface/formInterface";
import { IHoldemNowCafe } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const HoldemNowUpload = () => {
  const [dataList, setDataList] = useState<IHoldemNowCafe[]>([]);
  const [searchDataList, setSearchDataList] = useState<IHoldemNowCafe[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const setIsLoading = useSetRecoilState(loadingState);
  const [cafeId, setCafeId] = useState(0);
  const [cafeName, setCafeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [employerId, setEmployerId] = useState("");
  const [email, setEmail] = useState("");
  const [isAffiliated, setIsAffiliated] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [vcn, setVcn] = useState("");

  const [contents, setContents] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const formData: FormProps = {
    title: "수동 등록",
    form: [
      {
        value: cafeName,
        setValue: setCafeName,
        label: "펍명",
        width: "100%",
        type: "input-dash",
      },
      {
        value: ownerName,
        setValue: setOwnerName,
        label: "대표자",
        width: "100%",
        type: "input-dash",
      },
      {
        value: {
          value1: employerId,
          value2: isAffiliated,
          value3: isRecommended,
        },
        setValue: setEmployerId,
        label: "사업자 등록번호",
        width: "100%",
        type: "emp-dash-form",
      },
      {
        value: {
          value1: phoneNumber,
          value2: vcn,
          label2: "안심번호",
        },
        setValue: setPhoneNumber,
        label: "연락처",
        width: "100%",
        type: "phone-dash-form",
      },
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        width: "100%",
        type: "input-dash",
      },
      {
        value: contents,
        setValue: setContents,
        label: "내용을 입력해주세요. (최대 1000자)",
        width: "100%",
        type: "text",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "이미지",
        width: "100%",
        type: "files",
      },
    ],
  };
  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "펍명",
      align: "center",
      sortable: false,
      value: "pubName",
    },
    {
      text: "대표자",
      align: "center",
      sortable: false,
      value: "ownerName",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "주소",
      align: "center",
      sortable: false,
      value: "newAddress",
    },

    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "사업자등록번",
      align: "center",
      sortable: false,
      value: "employerId",
    },
  ];

  const setDetail = async (item: IHoldemNowCafe) => {
    setCafeId(item.id);
    setCafeName(item.pubName);
    setOwnerName(item.ownerName);
    setIsAffiliated(item.affiliated);
    setEmployerId(item.employerId);
    setPhoneNumber(item.phoneNumber);
    setVcn(item.vcn);
    setEmail(item.email);
    setIsRecommended(item.recommended);
  };
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getList = async () => {
    const res = await API.getAllCafe();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IHoldemNowCafe) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      setDataList(updatedData);
      const totalPages = Math.ceil(res.data.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(updatedData);
    }
  };

  const add = async () => {
    if (cafeId > 0) {
      if (window.confirm("신규 등록 하시겠습니까?")) {
        setIsLoading(true);
        const body = {
          cafeId,
          nowMessage: contents,
        };
        const formData = new FormData();

        formData.append(
          "data",
          new Blob([JSON.stringify(body)], {
            type: "application/json",
          })
        );

        if (selectedFile) {
          for (const img of selectedFile) {
            formData.append("coverImages", img);
          }

          const res = await AdminAPI.uploadPubMessage(formData);
          if (res && res.status === 200) {
            alert("신규 등록되었습니다.");
            setContents("");
            setSelectedFile([]);
            getList();
            setIsLoading(false);
            return;
          } else {
            alert("가입되어 있지 않은 사용자입니다.");
            setIsLoading(false);
            return;
          }
        }
        const res = await AdminAPI.uploadPubMessageWithOut(formData);
        if (res && res.status === 200) {
          alert("신규 등록되었습니다.");
          setContents("");
          getList();
          setIsLoading(false);
        } else {
          alert("가입되어 있지 않은 사용자입니다.");
          setIsLoading(false);
          return;
        }
      }
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: IHoldemNowCafe) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <DivStyle
      width="100%"
      backgroundColor="#121212"
      height="auto"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="70%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="60px"
        setMobileTable={true}
      >
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          justify_content="end"
          align_items="center"
        >
          <DivStyle
            width="50%"
            display="flex"
            justify_content="flex-end"
            flex_direction="row"
            align_items="center"
            marginRight="20px"
            marginTop="20px"
          >
            <InputFormStyle
              onChange={(e) => handleSearch(e.target.value)}
            ></InputFormStyle>
            <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
          </DivStyle>
        </DivStyle>
        <Table
          headers={headers}
          currentPage={currentPage}
          dataList={searchDataList}
          setDetail={setDetail}
        />
        <Page
          currentPage={currentPage}
          totalPages={totalPages}
          dataList={searchDataList}
          setCurrentPage={setCurrentPage}
        />
      </DivStyle>
      <DivStyle
        width="40%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            mode={"I"}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};
export default HoldemNowUpload;
