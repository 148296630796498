import DateFormStyle from "../style/date.component";
import { InputFormProps } from "./interface/formInterface";

const DateForm = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <label style={{ color: "white", fontSize: "15px", marginRight: "15px" }}>
        {label} :
      </label>
      <DateFormStyle
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        type="date"
        width={width}
      ></DateFormStyle>
    </div>
  );
};

export default DateForm;
