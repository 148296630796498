"use client";
import Icon from "@mdi/react";
import DivStyle from "./style/div.component";
import { mdiLogout, mdiMenu } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loginState } from "../recoil/auth.atom";
import { sideState } from "../recoil/side.atom";

const Header = () => {
  const navigate = useNavigate();
  const setIsLoggedIn = useSetRecoilState(loginState);
  const [isOpen, setIsOpen] = useRecoilState(sideState);
  const signOut = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <DivStyle
      position="fixed"
      display="flex"
      flex_direction="row"
      width="100%"
      height="65px"
      backgroundColor="#272727"
      align_items="center"
      justify_content="space-between"
    >
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Icon
          path={mdiMenu}
          size={0.9}
          style={{ color: "white", marginLeft: "25px" }}
        />
      </div>

      <label
        onClick={() => {
          signOut();
        }}
        style={{ marginRight: "25px" }}
      >
        <Icon path={mdiLogout} size={0.9} style={{ color: "white" }} />
      </label>
    </DivStyle>
  );
};

export default Header;
