/* eslint-disable */
import styled from "styled-components";
import Props from "./interface/props";

const TableStyle = styled.table<Props>`
  width: ${({ width }) => width || ""};
  border-collapse: collapse;
  margin-top: ${({ marginTop }) => marginTop || ""};

  tr {
    border-bottom: 1px solid #393939;
    &:hover {
      background-color: #616161;
    }
  }
  th {
    height: 46px;
    color: #ffffffb2;
    font-size: 10.5px;
  }
  td {
    color: white;
    font-size: 12.5px;
    height: 46px;
  }
  text-align: center;
  @media screen and (max-width: 700px) {
    ${({ setMobile }) =>
      setMobile
        ? `    td {
      font-weight: bold;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    td div {
      height: auto;
      text-align: center;
      margin-left: 15px;
      margin-right: 15px;
    }`
        : ""};
  }
`;

export default TableStyle;
