// src/api/admin/competition.api.ts
import { adminApiRequest } from "../../utils/apiUtils";

export interface CompetitionEventRequest {
  name: string;
  buyIn: string;
  registrationEndLevelString: string;
  durations: string;
  startingStack: number;
  date: string;
  competitionTournamentId: number;
}

export const CompetitionAPI = {
  getCompetitions: (): any => {
    return adminApiRequest.get("/competition-tournaments").then((res) => res);
  },
  getCompetition: (id: number) => {
    return adminApiRequest
      .get(`/competition-tournaments/${id}`)
      .then((res) => res);
  },

  registerCompetition: (formData: FormData) => {
    return adminApiRequest
      .post("/competition-tournaments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
  registerCompetitionEvent: (request: CompetitionEventRequest) => {
    return adminApiRequest
      .post("/competition-events", request)
      .then((res) => res);
  },
  updateCompetition: (id: number, formData: FormData) => {
    return adminApiRequest
      .put(`/competition-tournaments/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  deleteCompetition: (id: number) => {
    return adminApiRequest
      .delete(`/competition-tournaments/${id}`)
      .then((res) => res);
  },
};
