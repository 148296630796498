import styled from "styled-components";
import Props from "./interface/props";

const TextAreaForm = styled.textarea<Props>`
  height: ${({ height }) => height || "200px"};
  width: ${({ width }) => width || "100%"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  border: none;
  border-bottom: 1px solid white;
  background-color: rgba(255, 255, 255, 0);
  resize: vertical;
  color: white;
  &:focus {
    outline: none;
    border-bottom-color: #007bff;
  }

  @media (max-width: 300px) {
    width: 90%;
  }
`;

export default TextAreaForm;
