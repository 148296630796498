import { useEffect } from "react";
import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import FormCheckInput from "./form_check";
import FormDate from "./form_date_time";
import EmPloyerForm from "./form_employer";
import FormFileInput from "./form_file";
import FormImage from "./form_image";
import FormInput from "./form_input";
import FormPrize from "./form_prize";
import SearchGroupForm from "./form_search_group";
import FormSelect from "./form_select";
import FormTextArea from "./form_text";
import {
  FormProps,
  FormTempInterface,
  FormTypeProps,
} from "./interface/formInterface";
import PhoneForm from "./form_phone";
import FormOrder from "./form_order";

const FormTemp = (props: FormTempInterface) => {
  const { data } = props;
  const { value, label, setValue, width, type, options } = data;
  switch (type) {
    case "input":
      return (
        <FormInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInput>
      );
    case "prize":
      return (
        <FormPrize
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormPrize>
      );

    case "text":
      return (
        <FormTextArea
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormTextArea>
      );
    case "check":
      return (
        <FormCheckInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormCheckInput>
      );
    case "date":
      return (
        <FormDate
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormDate>
      );
    case "file":
      return (
        <FormFileInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormFileInput>
      );
    case "select":
      return (
        <FormSelect
          options={options}
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormSelect>
      );
    case "search-group":
      return (
        <SearchGroupForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          isOpen={data.isOpen}
          setIsOpen={data.setIsOpen}
        ></SearchGroupForm>
      );
    case "image":
      return (
        <FormImage
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImage>
      );
    case "emp-form":
      return (
        <EmPloyerForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></EmPloyerForm>
      );
    case "phone-form":
      return (
        <PhoneForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></PhoneForm>
      );
    case "order-form":
      return (
        <FormOrder
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormOrder>
      );
  }
  return null;
};
const Form = (props: FormProps) => {
  const { form, title, add, remove, mode, save } = props;

  return (
    <DivStyle
      backgroundColor="#1E1E1E"
      width="100%"
      height="calc(auto + 10px)"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "30px",
        }}
      >
        {title}
      </span>
      {form.map((item: FormTypeProps, index: number) => (
        <div key={index} style={{ width: "90%", marginBottom: "20px" }}>
          <FormTemp data={item}></FormTemp>
        </div>
      ))}
      <div>
        <ButtonStyle
          onClick={() => (mode === "U" ? save() : add())}
          marginBottom="30px"
          width="70px"
          height="40px"
          border="none"
          backgroundColor="#1976D2"
          borderRadius="5px"
          color="white"
        >
          {mode === "U" ? "저장" : "등록"}
        </ButtonStyle>
        {mode === "U" ? (
          <ButtonStyle
            marginLeft="10px"
            onClick={() => remove()}
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#DD2C00"
            borderRadius="5px"
            color="white"
          >
            삭제
          </ButtonStyle>
        ) : null}
      </div>
    </DivStyle>
  );
};

export default Form;
