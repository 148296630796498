import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import { InputFormProps } from "./interface/formInterface";
import { useCallback, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import AdminAPI from "../../api/admin";
import API from "../../api/api";

const FormNoticeEditor = (props: InputFormProps) => {
  const { value, setValue, label } = props;
  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (
      editorRef.current &&
      editorRef.current.getInstance().getHTML() !== value
    ) {
      editorRef.current.getInstance().setHTML(value);
    }
  }, [value]);

  const handleChange = useCallback(() => {
    if (editorRef.current) {
      const htmlValue = editorRef.current.getInstance().getHTML();
      setValue(htmlValue);
    }
  }, [setValue]);

  const uploadImage = async (blob: Blob) => {
    const formData = new FormData();
    formData.append("file", blob);

    try {
      const response = await API.uploadArticleImage(formData);
      if (!response) {
        return;
      }
      const imageUrl = response.data; // 서버에서 반환된 이미지 URL
      return imageUrl;
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  };
  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.getInstance();

      editorInstance.removeHook("addImageBlobHook");
      editorInstance.addHook("addImageBlobHook", async (blob, callback) => {
        try {
          const imageUrl = await uploadImage(blob);
          callback(imageUrl, "alt text"); // 이미지 URL과 alt 텍스트 설정
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      });
    }
  }, []);
  return (
    <div>
      <Editor
        initialValue={value}
        previewStyle="vertical"
        height="800px"
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        ref={editorRef}
        onChange={handleChange}
        linkAttributes={{
          target: "_blank",
          rel: "noopener noreferrer",
        }}
      />
    </div>
  );
};

export default FormNoticeEditor;
