import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import InputFormStyle from "../style/input_form.component";
import TextAreaForm from "../style/textarea.component";
import { FormProps } from "./interface/formInterface";

const UserJobForm = (props: FormProps) => {
  const { form, remove, mode, save, title, add } = props;
  return (
    <DivStyle
      style={{
        overflowY: "auto",
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="400px"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        {title}
      </span>
      <div style={{ width: "90%", marginBottom: "10px" }}>
        <InputFormStyle
          placeholder="펍 이름"
          value={form[0].value}
          onChange={(e) => {
            form[0].setValue(e.target.value);
          }}
        />
      </div>

      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          1.주소
        </span>
        <InputFormStyle
          placeholder="주소"
          value={form[1].value}
          onChange={(e) => {
            form[1].setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          2.채용 상태
        </span>
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          marginTop="10px"
        >
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[2].value === "EMPLOYING" ? "#184DA0" : "#666666"
            }
            width="70px"
            height="30px"
            color="white"
            onClick={() => form[2].setValue("EMPLOYING")}
          >
            채용 중
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={form[2].value === "DONE" ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => form[2].setValue("DONE")}
          >
            완료
          </ButtonStyle>
        </DivStyle>
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          3. 구인 분야
        </span>
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          marginTop="10px"
        >
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[3].value.value1 === "DEALER" && form[3].value.value2 === ""
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            color="white"
            onClick={() => {
              form[3].setValue("DEALER");
              form[3].value.setValue2("");
            }}
          >
            딜러
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[3].value.value1 === "PRACTICE_DEALER" &&
              form[3].value.value2 === ""
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => {
              form[3].setValue("PRACTICE_DEALER");
              form[3].value.setValue2("");
            }}
          >
            연습딜러
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[3].value.value1 === "SERVANT" && form[3].value.value2 === ""
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => {
              form[3].setValue("SERVANT");
              form[3].value.setValue2("");
            }}
          >
            서빙
          </ButtonStyle>
        </DivStyle>
        <InputFormStyle
          value={form[3].value.value2}
          onChange={(e) => {
            if (e.target.value === "") {
              form[3].setValue("ETC");
            }
            form[3].value.setValue2(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          4. 근무 요일 및 시간
        </span>
        <TextAreaForm
          placeholder="협의"
          height="120px"
          value={form[4].value}
          onChange={(e) => form[4].setValue(e.target.value)}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          5.나이 제한
        </span>
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          marginTop="10px"
        >
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={form[5].value.length === 0 ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => form[5].setValue([])}
          >
            무관
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            border="none"
            borderRadius="5px"
            backgroundColor={form[5].value.includes(20) ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => {
              let data = [...form[5].value];
              if (!data.includes(20)) {
                if (data.length === 2) {
                  data.shift();
                }
                data.push(20);
              } else {
                data = data.filter((value: number) => value !== 20);
              }
              form[5].setValue(data);
            }}
          >
            20대
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            border="none"
            borderRadius="5px"
            backgroundColor={form[5].value.includes(30) ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => {
              let data = [...form[5].value];
              if (!data.includes(30)) {
                if (data.length === 2) {
                  data.shift();
                }
                data.push(30);
              } else {
                data = data.filter((value: number) => value !== 30);
              }
              form[5].setValue(data);
            }}
          >
            30대
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            border="none"
            borderRadius="5px"
            backgroundColor={form[5].value.includes(40) ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => {
              let data = [...form[5].value];
              if (!data.includes(40)) {
                if (data.length === 2) {
                  data.shift();
                }
                data.push(40);
              } else {
                data = data.filter((value: number) => value !== 40);
              }
              form[5].setValue(data);
            }}
          >
            40대
          </ButtonStyle>
        </DivStyle>
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          6.성별
        </span>
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          marginTop="10px"
        >
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[6].value === "NO_MATTER" ? "#184DA0" : "#666666"
            }
            width="70px"
            height="30px"
            color="white"
            onClick={() => form[6].setValue("NO_MATTER")}
          >
            무관
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            border="none"
            borderRadius="5px"
            backgroundColor={form[6].value === "MALE" ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => form[6].setValue("MALE")}
          >
            남
          </ButtonStyle>
          <ButtonStyle
            marginLeft="10px"
            border="none"
            borderRadius="5px"
            backgroundColor={form[6].value === "FEMALE" ? "#184DA0" : "#666666"}
            width="70px"
            height="30px"
            color="white"
            onClick={() => form[6].setValue("FEMALE")}
          >
            여
          </ButtonStyle>
        </DivStyle>
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          7.시급
        </span>
        <InputFormStyle
          value={form[7].value}
          onChange={(e) => form[7].setValue(e.target.value)}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          8.복리후생
        </span>
        {/* <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          marginTop="10px"
        >
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[8].value.value1 === "없음" ? "#184DA0" : "#666666"
            }
            width="70px"
            height="30px"
            color="white"
            onClick={() => {
              form[8].setValue("없음");
              form[8].value.setValue2("");
            }}
          >
            없음
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[8].value.value1
                .split(",")
                .some((item: any) => item.trim() === "숙식")
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => form[8].setValue(form[8].value.value1 + ", 숙식")}
          >
            숙식
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[8].value.value1
                .split(",")
                .some((item: any) => item.trim() === "식사")
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => form[8].setValue(form[8].value.value1 + ", 식사")}
          >
            식사
          </ButtonStyle>
          <ButtonStyle
            border="none"
            borderRadius="5px"
            backgroundColor={
              form[8].value.value1
                .split(",")
                .some((item: any) => item.trim() === "교통비")
                ? "#184DA0"
                : "#666666"
            }
            width="70px"
            height="30px"
            marginLeft="10px"
            color="white"
            onClick={() => form[8].setValue(form[8].value.value1 + ", 교통비")}
          >
            교통비
          </ButtonStyle>
        </DivStyle> */}
        <InputFormStyle
          placeholder=", 로 구분지어주세요"
          value={form[8].value}
          onChange={(e) => {
            form[8].setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          9.연락처
        </span>
        <InputFormStyle
          placeholder="연락처"
          value={form[9].value}
          onChange={(e) => {
            form[9].setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          10.이메일
        </span>
        <InputFormStyle
          placeholder="이메일"
          value={form[10].value}
          onChange={(e) => {
            form[10].setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          11.짧은 소개
        </span>
        <TextAreaForm
          placeholder="짧은 소개"
          height="120px"
          value={form[11].value}
          onChange={(e) => form[11].setValue(e.target.value)}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          12.종료 날짜
        </span>
        <InputFormStyle
          placeholder="종료일"
          value={form[12].value}
          onChange={(e) => {
            form[12].setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            width: "100%",
            fontSize: "15px",
            color: "#FFFFFFB3",
            marginTop: "20px",
          }}
        >
          13.추가 정보
        </span>
        <TextAreaForm
          placeholder="설명"
          height="120px"
          value={form[13].value}
          onChange={(e) => form[13].setValue(e.target.value)}
        />
      </div>
      {mode ? (
        <div>
          <ButtonStyle
            onClick={() => (mode === "U" ? save() : add())}
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#1976D2"
            borderRadius="5px"
            color="white"
          >
            {mode === "U" ? "저장" : mode === "S" ? "저장" : "등록"}
          </ButtonStyle>
          {mode === "U" ? (
            <ButtonStyle
              marginLeft="10px"
              onClick={() => remove()}
              marginBottom="30px"
              width="120px"
              height="40px"
              border="none"
              backgroundColor="#DD2C00"
              borderRadius="5px"
              color="white"
            >
              가맹점 구인 삭제
            </ButtonStyle>
          ) : null}
        </div>
      ) : null}
    </DivStyle>
  );
};

export default UserJobForm;
