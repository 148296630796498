import { useEffect, useState } from "react";
import { NewsCategoryResponse, NewsResponse } from "./interface";
import AdminAPI from "../../api/admin";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { FormProps } from "../../components/form/interface/formInterface";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";

const News = () => {
  const [dataList, setDataList] = useState<NewsResponse[]>([]);
  const [searchDataList, setSearchDataList] = useState<NewsResponse[]>([]);

  const [categoryList, setCategoryList] = useState<NewsCategoryResponse[]>([]);

  const [mode, setMode] = useState("I");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const [selectedFile, setSelectedFile] = useState(null);

  const setIsLoading = useSetRecoilState(loadingState);

  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [contentHtml, setContentHtml] = useState("");
  const [mainImageKey, setMainImageKey] = useState("");
  const [category, setCateGory] = useState<NewsCategoryResponse | null>(null);
  const [authorName, setAuthorName] = useState("");
  const [published, setPublished] = useState(false);
  const [link, setLink] = useState("");

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "제목",
        width: "100%",
        type: "input",
      },
      {
        value: published,
        setValue: setPublished,
        label: "게시",
        width: "100%",
        type: "check",
      },
      {
        value: link,
        setValue: setLink,
        label: "출처 링크",
        width: "100%",
        type: "input",
      },
      {
        value: category,
        setValue: setCateGory,
        options: categoryList,
        label: "카테고리",
        width: "100%",
        type: "category",
      },
      {
        value: authorName,
        setValue: setAuthorName,
        label: "작성자",
        width: "100%",
        type: "input",
      },
      {
        value: contentHtml,
        setValue: setContentHtml,
        label: "내용",
        width: "100%",
        type: "editor",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "사진",
        width: "100%",
        type: "file",
      },
      {
        value: mainImageKey,
        setValue: setMainImageKey,
        label: "사진",
        width: "25%",
        type: "image",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "제목",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "작성자",
      align: "center",
      sortable: false,
      value: "authorName",
    },
    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "게시",
      align: "center",
      sortable: false,
      value: "published",
    },
  ];

  const addForm = async () => {
    setId(0);
    setMode("I");
    setTitle("");
    setContentHtml("");
    setAuthorName("");
    setCateGory(null);
    setMainImageKey("");
    setSelectedFile(null);
    setPublished(false);
    setLink("");
  };

  const save = async () => {
    if (!id) {
      return;
    }
    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);
      const body = {
        id,
        title,
        contentHtml,
        category,
        authorName,
        isPublished: published,
        link,
      };
      console.log(body);
      const formData = new FormData();
      formData.append(
        "news",
        new Blob([JSON.stringify(body)], {
          type: "application/json",
        })
      );
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      const result = await AdminAPI.updateNews(formData, id);
      if (result) {
        alert("수정되었습니다.");
        addForm();
        getNews();
      }
      setIsLoading(false);
    }
  };

  const add = async () => {
    if (window.confirm("신규 등록 하시겠습니까?")) {
      setIsLoading(true);
      const body = {
        title,
        contentHtml,
        category,
        authorName,
        isPublished: published,
        link,
      };
      if (!selectedFile) {
        alert("썸네일 이미지를 선택해주세요.");
        return;
      }
      const formData = new FormData();
      formData.append(
        "data",
        new Blob([JSON.stringify(body)], {
          type: "application/json",
        })
      );
      formData.append("image", selectedFile);

      const res = await AdminAPI.registerNews(formData);
      if (res) {
        alert("등록되었습니다.");
        addForm();
        getNews();
      }
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        const res = await AdminAPI.deleteNews(id);
        if (res) {
          alert("삭제되었습니다.");
          getNews();
          addForm();
        }
        setIsLoading(false);
      }
    }
  };

  const setDetail = (item: NewsResponse) => {
    setId(item.id);
    setMode("U");
    setTitle(item.title);
    setContentHtml(item.contentHtml);
    setAuthorName(item.authorName);
    setCateGory(item.category);
    setMainImageKey(item.mainImageKey);
    setSelectedFile(null);
    setPublished(item.published);
    setLink(item.link);
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getNews = async () => {
    const news = await AdminAPI.getNews();
    const newsCategory_res = await AdminAPI.getNewsCategory();
    if (!newsCategory_res) {
      return;
    }
    setCategoryList(newsCategory_res);
    if (!news) {
      return;
    }
    const updatedData = news.map((item: NewsResponse) => ({
      ...item,
      createdAt: formatDate(item.createdAt),
    }));
    const totalPages = Math.ceil(updatedData.length / 10);
    setTotalPages(totalPages);
    setDataList(updatedData);
    setSearchDataList(updatedData);
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="column"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="100%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          홀덤 뉴스
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="100%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default News;
