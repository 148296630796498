import styled from "styled-components";

interface menuInterface {
  isMainActive: boolean;
  isMenuExtended?: boolean;
}

const MenuStyle = styled.div<menuInterface>`
  background-color: ${(props) => (props.isMainActive ? "#696969" : "")};
  width: 100%;
  user-select: none;
  color: ${(props) => (props.isMenuExtended ? "#FFC107" : "white")};
  padding: 16px 0px;

  font-size: 14px;
  display: flex;
  &:hover {
    background-color: ${(props) =>
      props.isMenuExtended
        ? "rgba(255, 193, 7, 0.2)"
        : "rgba(105, 105, 105, 0.4)"};
  }
  div {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-icon {
    position: absolute;
    right: 10px;
    transition: transform 0.3s ease; /* 회전 효과를 부드럽게 만들기 위해 트랜지션 추가 */
    transform: ${(props) => (props.isMenuExtended ? "rotate(180deg)" : "none")};
  }
`;

export default MenuStyle;
