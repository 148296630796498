import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import AreaForm from "./form_area";
import FormCheckInput from "./form_check";
import FormDate from "./form_date_time";
import EmPloyerForm from "./form_employer";
import FacilityForm from "./form_facility";
import FormFileInput from "./form_file";
import FormFilesInput from "./form_files";
import FormImage from "./form_image";
import FormImages from "./form_images";
import FormImagesNotDelete from "./form_images_not_delete";
import FormInput from "./form_input";
import FormInputDash from "./form_input_dash";
import LocationForm from "./form_location";
import LocationNotSearchForm from "./form_location_not_search";
import PhoneForm from "./form_phone";
import SearchGroupForm from "./form_search_group";
import FormSelect from "./form_select";
import FormTextArea from "./form_text";
import {
  FormProps,
  FormTempInterface,
  FormTypeProps,
} from "./interface/formInterface";

const FormTemp = (props: FormTempInterface) => {
  const { data } = props;
  const { value, label, setValue, width, type, options } = data;
  switch (type) {
    case "input":
      return (
        <FormInput
          height="20px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInput>
      );
    case "input-dash":
      return (
        <FormInputDash
          height="20px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormInputDash>
      );
    case "text":
      return (
        <FormTextArea
          height="100px"
          fontSize="13px"
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormTextArea>
      );
    case "emp-form":
      return (
        <EmPloyerForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></EmPloyerForm>
      );
    case "check":
      return (
        <FormCheckInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormCheckInput>
      );
    case "date":
      return (
        <FormDate
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormDate>
      );
    case "file":
      return (
        <FormFileInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormFileInput>
      );
    case "select":
      return (
        <FormSelect
          options={options}
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormSelect>
      );
    case "search-group":
      return (
        <SearchGroupForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          isOpen={data.isOpen}
          setIsOpen={data.setIsOpen}
        ></SearchGroupForm>
      );
    case "image":
      return (
        <FormImage
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImage>
      );
    case "phone-form":
      return (
        <PhoneForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></PhoneForm>
      );
    case "area-form":
      return (
        <AreaForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          options={options}
        ></AreaForm>
      );
    case "location-form":
      return (
        <LocationForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
          isOpen={data.isOpen}
          setIsOpen={data.setIsOpen}
        ></LocationForm>
      );
    case "facility-form":
      return (
        <FacilityForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FacilityForm>
      );
    case "images":
      return (
        <FormImages
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImages>
      );
    case "images-not-delete":
      return (
        <FormImagesNotDelete
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormImagesNotDelete>
      );
    case "files":
      return (
        <FormFilesInput
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></FormFilesInput>
      );
    case "location-not-search-form":
      return (
        <LocationNotSearchForm
          value={value}
          label={label}
          setValue={setValue}
          width={width}
        ></LocationNotSearchForm>
      );
  }
  return null;
};
const ReferralForm = (props: FormProps) => {
  const { form, title, add, mode, save } = props;
  return (
    <DivStyle
      backgroundColor="#1E1E1E"
      width="100%"
      height="calc(auto + 10px)"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "18px",
          color: "#FFFFFFB3",
          marginTop: "30px",
        }}
      >
        {title}
      </span>
      {form.map((item: FormTypeProps, index: number) => (
        <div key={index} style={{ width: "90%", marginBottom: "20px" }}>
          <FormTemp data={item}></FormTemp>
          <DivStyle
            width="100%"
            marginTop="20px"
            display="flex"
            align_items="center"
            justify_content="center"
          >
            {item.label === "성별" && mode === "U" && (
              <ButtonStyle
                onClick={() => add()}
                width="100px"
                height="40px"
                border="none"
                backgroundColor="#1976D2"
                borderRadius="5px"
                color="white"
              >
                레퍼럴 생성
              </ButtonStyle>
            )}
          </DivStyle>
        </div>
      ))}
      {mode === "U" ? (
        <div>
          <ButtonStyle
            onClick={() => save()}
            marginBottom="30px"
            width="70px"
            height="40px"
            border="none"
            backgroundColor="#1976D2"
            borderRadius="5px"
            color="white"
          >
            복사
          </ButtonStyle>
        </div>
      ) : null}
    </DivStyle>
  );
};

export default ReferralForm;
