import { useState } from "react";
import Category from "../../components/category";
import DivStyle from "../../components/style/div.component";
import FriendSetting from "../../components/gameSetting/setting";
import FriendReward from "../../components/gameSetting/reward";
import FriendWithdraw from "../../components/gameSetting/withdraw";
import FriendDeduct from "../../components/gameSetting/deduct";

const GameSetting = () => {
  const [selectTab, setSelectTab] = useState(0);
  const tabs = [
    {
      text: "입장/친구 설정",
      value: 0,
    },
    {
      text: "친구보상 현황",
      value: 1,
    },
    {
      text: "친구보상 수동 출금 내역",
      value: 2,
    },
    {
      text: "펍 차감 내역",
      value: 3,
    },
  ];
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      width="100%"
      height="100vh"
      backgroundColor="#121212"
      display="flex"
      flex_direction="column"
      marginTop="65px"
      setMobile={true}
      align_items="center"
      marginBottom="40px"
    >
      <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
        입장 설정 및 친구 보상
      </h2>
      <Category tabs={tabs} selectTab={selectTab} setSelectTab={setSelectTab} />
      {selectTab === 0 && <FriendSetting />}
      {selectTab === 1 && <FriendReward />}
      {selectTab === 2 && <FriendWithdraw />}
      {selectTab === 3 && <FriendDeduct />}
    </DivStyle>
  );
};

export default GameSetting;
