import { useEffect, useState, useRef } from "react";
import DivStyle from "../style/div.component";
import InputStyle from "../style/input.component";
import { InputFormProps } from "./interface/formInterface";

const OperatingDaysForm = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  const [isOpen, setIsOpen] = useState(false);
  const facilityList = [
    {
      id: 1,
      type: "월",
      name: "월",
    },
    {
      id: 2,
      type: "화",
      name: "화",
    },
    {
      id: 3,
      type: "수",
      name: "수",
    },
    {
      id: 4,
      type: "목",
      name: "목",
    },
    {
      id: 5,
      type: "금",
      name: "금",
    },
    {
      id: 6,
      type: "토",
      name: "토",
    },
    {
      id: 7,
      type: "일",
      name: "일",
    },
  ];

  const node = useRef<HTMLDivElement>(null);
  const inputNode = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      node.current &&
      !node.current.contains(event.target as Node) &&
      inputNode.current &&
      !inputNode.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    setIsOpen(!isOpen);
  };

  const getTypeName = (type: string) => {
    const facility = facilityList.find((facility) => facility.type === type);
    return facility ? facility.name : undefined;
  };

  const checkValue = (data: any) => {
    if (data.length === 7) {
      value.setValue(true);
    } else {
      value.setValue(false);
    }
  };

  useEffect(() => {
    checkValue(value.value);
  }, []);

  const handleCheckboxChange = (option: any) => {
    let data;
    if (value.value.some((item: any) => item === option.type)) {
      data = value.value.filter((item: any) => item !== option.type);
      setValue(data);
    } else {
      data = [...value.value, option.type];
      setValue(data);
    }
    checkValue(data);
  };

  const checkBoxValue = (option: any) => {
    return value.value.some((item: any) => item === option.type);
  };

  const viewValue = () => {
    const names = value.value.map((item: any) => getTypeName(item));
    return names.join(", ");
  };

  return (
    <DivStyle width={width} position="relative">
      <InputStyle
        ref={inputNode}
        style={{ color: "white" }}
        value={viewValue()}
        width={width}
        type="text"
        placeholder={label}
        onClick={() => handleInputClick()}
      />
      {isOpen && (
        <DivStyle
          ref={node}
          style={{
            top: "100%",
            left: "0",
            zIndex: "100",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            padding: "10px",
          }}
          display="flex"
          flex_direction="column"
          position="absolute"
          backgroundColor="#1E1E1E"
          border="1px solid #ccc"
          borderRadius="4px"
          width="100%"
          height="200px"
        >
          {facilityList.map((option) => (
            <label
              key={option.name}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ height: "40px" }}
                type="checkbox"
                checked={checkBoxValue(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <span style={{ color: "white", fontWeight: "bold" }}>
                {option.name}
              </span>
            </label>
          ))}
        </DivStyle>
      )}
    </DivStyle>
  );
};
export default OperatingDaysForm;
