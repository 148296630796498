import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import DivStyle from "../style/div.component";
import ButtonStyle from "../style/button.component";
import { InputFormProps } from "./interface/formInterface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import AdminAPI from "../../api/admin";

const Gallery = styled(DivStyle)`
  display: flex;
  gap: 10px;
  max-width: 100%; /* Scrollable container width */
  overflow-x: auto; /* Enable horizontal scroll */
  padding: 10px;
  border: 1px solid #ccc;
  white-space: nowrap; /* Prevent wrapping */
`;

const ImageWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  min-width: 150px; /* Minimum width for each item */
`;

const FormImagesSort = (props: InputFormProps) => {
  const { value, setValue, width } = props;
  const { value1, setDeleteImage, cafeId } = value;
  const setLoading = useSetRecoilState(loadingState);
  const updateDisplayOrder = (
    items: { id: number; imageUrl: string; displayOrder: number }[]
  ) => {
    return items.map((item, index) => ({
      ...item,
      displayOrder: index + 1,
    }));
  };

  const handleDelete = (index: number) => {
    const targetImage = value1[index].imageUrl;
    const newValue = value1.filter((_: any, i: number) => i !== index);
    setValue(updateDisplayOrder(newValue));
    setDeleteImage((prev: any) => [...prev, targetImage]);
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const reorderedItems: {
      id: number;
      imageUrl: string;
      displayOrder: number;
    }[] = Array.from(value1);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    const updateData = updateDisplayOrder(reorderedItems);
    setLoading(true);
    try {
      await AdminAPI.sortCafeImages(updateData, cafeId);
      setValue(updateData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return Array.isArray(value1) && value1.length > 0 ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="gallery" direction="horizontal">
        {(provided) => (
          <Gallery
            ref={provided.innerRef}
            {...provided.droppableProps}
            width={width}
          >
            {value1.map(
              (
                src: { id: number; imageUrl: string; displayOrder: number },
                index: number
              ) => (
                <Draggable
                  key={src.id}
                  draggableId={String(src.id)}
                  index={index}
                >
                  {(provided) => (
                    <ImageWrapper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img
                        src={src.imageUrl}
                        style={{
                          width: "100%",
                          height: "80px",
                          objectFit: "cover",
                        }}
                        alt="이미지"
                      />
                      <ButtonStyle
                        border="none"
                        backgroundColor="red"
                        color="white"
                        height="30px"
                        borderRadius="5px"
                        onClick={() => handleDelete(index)}
                      >
                        삭제
                      </ButtonStyle>
                    </ImageWrapper>
                  )}
                </Draggable>
              )
            )}
            {provided.placeholder}
          </Gallery>
        )}
      </Droppable>
    </DragDropContext>
  ) : null;
};

export default FormImagesSort;
