import { apiRequest } from "../../utils/apiUtils";

export const jobOpeningApi = {
  getJobOpening: () =>
    apiRequest<any>("post", "/job-opening/list", {
      data: { lat: "0", lon: "0" },
      fullResponse: true,
    }),
  removeJobOpening: (id: number) =>
    apiRequest<any>("delete", "/job-opening/delete", {
      params: { id },
      fullResponse: true,
    }),
  saveJobOpening: (body: any) =>
    apiRequest<any>("put", "/job-opening/update", {
      data: body,
      fullResponse: true,
    }),
  addJobOpening: (body: any) =>
    apiRequest<any>("post", "/job-opening/register", {
      data: body,
      fullResponse: true,
    }),
};
