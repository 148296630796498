import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import AdminAPI from "../../api/admin";
import DateForm from "../../components/form/form_date";
import { IRewardRecord, ITicket } from "./interface/interface";
const RouletteDashboard = () => {
  const [date, setDate] = useState<string>("");
  const [adViewersCount, setAdViewersCount] = useState(0);
  const [ticketRecipientsCount, setTicketRecipientsCount] = useState(0);
  const [ticketAdCount, setTicketAdCount] = useState(0);
  const [ticketRedeemedCount, setTicketRedeemedCount] = useState(0);
  const [ticketsUsedCount, setTicketsUsedCount] = useState(0);
  const [points200000, setPoints200000] = useState(1);
  const [points100000, setPoints100000] = useState(2);
  const [points50000, setPoints50000] = useState(6);
  const [points30000, setPoints30000] = useState(12);
  const [points10000, setPoints10000] = useState(94);
  const [points1000, setPoints1000] = useState(1011);
  const [rebuyTickets2, setRebuyTickets2] = useState(7);
  const [rebuyTickets1, setRebuyTickets1] = useState(5);
  const [totalPointsSpent, setTotalPointsSpent] = useState(3011000);

  const numberFormat = (value: number) => {
    const NUMBER_FORMAT_REGX = /\B(?=(\d{3})+(?!\d))/g;
    return value.toString().replace(NUMBER_FORMAT_REGX, ",");
  };
  const countNumbers = (data: number[]) => {
    const counts: number[] = [];
    data.forEach((num: number) => {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    });
    return counts;
  };

  const getPointsToAdd = (productId: number) => {
    // productId에 따라 더할 값을 반환
    switch (productId) {
      case 1:
        return 50000;
      case 2:
        return 30000;
      case 3:
        return 10000;
      case 4:
        return 1000;
      case 5:
        return 200000;
      case 6:
        return 100000;
      default:
        return 0;
    }
  };

  const calculateTotalPoints = (records: IRewardRecord[]) => {
    return records.reduce((total: number, record: IRewardRecord) => {
      const pointsToAdd = getPointsToAdd(record.productId);
      return total + pointsToAdd;
    }, 0);
  };

  function formatCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const getDatas = async () => {
    if (date !== "") {
      const res = await AdminAPI.getRewardRecordByDate(date);
      if (res && res.status === 200) {
        const data = res.data;
        setTotalPointsSpent(calculateTotalPoints(data));
        const counts = countNumbers(
          data.map((record: IRewardRecord) => record.productId)
        );
        setPoints200000(counts[5] || 0);
        setPoints100000(counts[6] || 0);
        setPoints50000(counts[1] || 0);
        setPoints30000(counts[2] || 0);
        setPoints10000(counts[3] || 0);
        setPoints1000(counts[4] || 0);
        setRebuyTickets1(counts[8] || 0);
        setRebuyTickets2(counts[7] || 0);
      }

      const res_ticket = await AdminAPI.getTicketByDate(date);
      if (res_ticket && res_ticket.status === 200) {
        const data = res_ticket.data;
        const adList = data.filter(
          (ticket: ITicket) => ticket.ticketType !== "LOGIN"
        );
        setAdViewersCount(
          new Set(adList.map((ticket: ITicket) => ticket.userId)).size
        );
        setTicketRecipientsCount(
          new Set(data.map((ticket: ITicket) => ticket.userId)).size
        );
        setTicketRedeemedCount(data.length);
        setTicketAdCount(adList.length);
        setTicketsUsedCount(
          data.filter((ticket: ITicket) => ticket.used).length
        );
      }
    } else {
      const today = formatCurrentDate();
      setDate(today);
    }
  };
  useEffect(() => {
    getDatas();
  }, [date]);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="column"
      align_items="center"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        setMobileTable={true}
        width="80%"
        display="flex"
        flex_direction="column"
        align_items="center"
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          데일리 룰렛 지표
        </h2>
        <DivStyle
          width="100%"
          height="80px"
          display="flex"
          flex_direction="row"
          justify_content="center"
          align_items="center"
          backgroundColor="#272727"
        >
          <DivStyle>
            <DateForm
              value={date}
              setValue={setDate}
              width="200px"
              label="날짜"
            />
          </DivStyle>
        </DivStyle>
        <DivStyle
          color="white"
          width="100%"
          display="flex"
          flex_direction="column"
          marginTop="30px"
          marginLeft="20px"
        >
          <div>광고 본 사람: {numberFormat(adViewersCount)}</div>
          <div>티켓 받은 사람: {numberFormat(ticketRecipientsCount)}</div>
          <div>광고로 받은 티켓: {numberFormat(ticketAdCount)}</div>
          <div>총 받은 티켓: {numberFormat(ticketRedeemedCount)}</div>
          <div>티켓 쓴 수: {numberFormat(ticketsUsedCount)}</div>
          <div>
            당첨 현황:
            <ul>
              <li>20만 포인트: {points200000}</li>
              <li>10만 포인트: {points100000}</li>
              <li>5만 포인트: {points50000}</li>
              <li>3만 포인트: {points30000}</li>
              <li>1만 포인트: {points10000}</li>
              <li>1천 포인트: {numberFormat(points1000)}</li>
              <li>리바인권 2개: {rebuyTickets2}</li>
              <li>리바인권 1개: {numberFormat(rebuyTickets1)}</li>
            </ul>
          </div>
          <div>총 나간 포인트: {numberFormat(totalPointsSpent)}</div>
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default RouletteDashboard;
