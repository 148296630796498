import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import API from "../../api/api";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import SmallForm from "../../components/form/small_form";
import {
  GameCouponResponse,
  IssuedGameCouponResponse,
} from "../../api/admin/gameCoupon";

const IssuedGameCoupon = () => {
  const [list, setList] = useState<GameCouponResponse[]>([]);
  const [issuedList, setIssuedList] = useState<IssuedGameCouponResponse[]>([]);
  const [id, setId] = useState(0);
  const [count, setCount] = useState(0);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");

  const typeList = [
    {
      id: 0,
      name: "GAMEPOINT",
    },
  ];
  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: issuedList,
        setValue: setIssuedList,
        label: "쿠폰 리스트",
        width: "100%",
        type: "coupon-list",
      },
      {
        value: count,
        setValue: setCount,
        label: "발행량",
        width: "100%",
        type: "input",
      },
    ],
  };

  const headers = [
    {
      text: "id",
      align: "start",
      sortable: false,
      value: "id",
    },
    {
      text: "타이틀",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "값",
      align: "center",
      sortable: false,
      value: "value",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "closedAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(0);
    setCount(0);
    setIssuedList([]);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function formatJsonDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    if (!id || id === 0) {
      alert("발행할 쿠폰을 선택해주세요.");
    }
    const body = {
      couponId: id,
      count: +count,
    };

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.issuedGameCoupon(body);
        if (res) {
          alert("신규 등록 되었습니다.");
          getCoupons();
          addForm();
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    }
  };
  const getIssuedCoupon = async (couponId: number) => {
    if (couponId === 0) return;
    setIsLoading(true);
    const res = await AdminAPI.getIssuedGameCoupon(couponId);
    setIssuedList(res);
    setIsLoading(false);
  };
  const setDetails = (item: GameCouponResponse) => {
    setId(item.id);
    setCount(0);
    getIssuedCoupon(item.id);
  };

  const getCoupons = async () => {
    const res = await AdminAPI.getGameCoupon();
    if (res) {
      const updatedData = res.map((item: GameCouponResponse) => ({
        ...item,
        closedAt: formatDate(item.closedAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      console.log(updatedData);
      setList(updatedData);
    }
  };
  useEffect(() => {
    getCoupons();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          쿠폰 발행
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={list}
            setDetail={setDetails}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={list}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default IssuedGameCoupon;
