import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { BASE_URL, ADMIN_URL, NEST_ADMIN_URL } from "../config";
import { TokenManager } from "./tokenManager";

export type ApiResponse<T> = T | AxiosResponse<T> | null;

interface ApiRequestOptions {
  data?: any;
  params?: any;
  headers?: any;
  fullResponse?: boolean;
  requiresAuth?: boolean;
}

const createApiRequest =
  (baseUrl: string) =>
  async <T>(
    method: string,
    endpoint: string,
    options: ApiRequestOptions = {}
  ): Promise<ApiResponse<T>> => {
    const {
      data,
      params,
      headers = {},
      fullResponse = false,
      requiresAuth = true,
    } = options;
    const token = TokenManager.getToken();

    if (requiresAuth && !token) return null;

    const config: AxiosRequestConfig = {
      method,
      url: `${baseUrl}${endpoint}`,
      headers: {
        ...(requiresAuth && token && { Authorization: `Bearer ${token}` }),
        ...headers,
      },
      data,
      params,
    };

    try {
      const response: AxiosResponse<T> = await axios(config);
      return fullResponse ? response : response.data;
    } catch (error) {
      console.error("API request failed:", error);
      return null;
    }
  };

export const apiRequest = createApiRequest(BASE_URL);
export const adminApiRequest = axios.create({
  baseURL: ADMIN_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export const nestAdminApiRequest = axios.create({
  baseURL: NEST_ADMIN_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
