import { useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import API from "../../api/api";
interface SellerProps {
  setNewIsOpen: (value: any) => void;
  setIsOpen: (value: any) => void;
  setCafe: any;
}
const SearchCafe = (props: SellerProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState([]);
  const { setIsOpen, setNewIsOpen, setCafe } = props;

  const [search, setSearch] = useState("");
  const headers = [
    {
      text: "매장이름",
      align: "center",
      value: "cafeName",
      sortable: false,
    },
    {
      text: "이메일",
      align: "center",
      value: "email",
      sortable: false,
    },
    {
      text: "사업자등록번호",
      align: "center",
      value: "employerId",
      sortable: false,
    },
  ];

  const getList = async () => {
    const res = await API.searchCafes(search);
    if (res && res.status === 200) {
      setDataList(res.data);
    }
  };

  const setData = () => {
    if (dataList.length > 0 && selected !== "") {
      const data = dataList[parseInt(selected)];
      setCafe(data);
      setNewIsOpen(false);
      setIsOpen(true);
    } else {
      alert("선택해주세요.");
    }
  };

  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>카페 검색</h4>
      <DivStyle
        width="80%"
        display="flex"
        flex_direction="row"
        align_items="center"
      >
        <InputFormStyle
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getList();
            }
          }}
        ></InputFormStyle>
        <div onClick={() => getList()}>
          <Icon path={mdiMagnify} size={1} />
        </div>
      </DivStyle>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />
      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setNewIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SearchCafe;
