// PosterManager.tsx
import React from "react";

interface PosterManagerProps {
  posters: string[];
  posterFiles: File[];
  setPosterFiles: (files: File[]) => void;
  currentPosterUrls: string[];
  setCurrentPosterUrls: (urls: string[]) => void;
}

const PosterManager: React.FC<PosterManagerProps> = ({
  posters,
  posterFiles,
  setPosterFiles,
  currentPosterUrls,
  setCurrentPosterUrls,
}) => {
  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    dropIndex: number
  ) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData("text/plain"));
    if (dragIndex === dropIndex) return;

    // 현재 포스터 URL 순서 변경
    const newUrlOrder = [...currentPosterUrls];
    const [removedUrl] = newUrlOrder.splice(dragIndex, 1);
    newUrlOrder.splice(dropIndex, 0, removedUrl);
    setCurrentPosterUrls(newUrlOrder);

    // 포스터 파일 순서도 같이 변경
    if (posterFiles.length > 0) {
      const newFileOrder = [...posterFiles];
      const [removedFile] = newFileOrder.splice(dragIndex, 1);
      newFileOrder.splice(dropIndex, 0, removedFile);
      setPosterFiles(newFileOrder);
    }
  };

  const handleRemove = (index: number) => {
    const newUrls = [...currentPosterUrls];
    newUrls.splice(index, 1);
    setCurrentPosterUrls(newUrls);

    if (posterFiles.length > 0) {
      const newFiles = [...posterFiles];
      newFiles.splice(index, 1);
      setPosterFiles(newFiles);
    }
  };

  return (
    <div
      style={{
        width: "95%",
        backgroundColor: "#1E1E1E",
        borderRadius: "10px",
        padding: "20px",
        marginBottom: "30px",
      }}
    >
      <h3
        style={{
          color: "white",
          margin: "0 0 20px 0",
        }}
      >
        포스터 관리 (드래그하여 순서 변경)
      </h3>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {currentPosterUrls.map((url, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            style={{
              backgroundColor: "#2A2A2A",
              position: "relative",
              width: "150px",
              height: "200px",
              borderRadius: "5px",
              cursor: "move",
            }}
          >
            <img
              src={url}
              alt={`포스터 ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "5px",
              }}
            />
            <button
              onClick={() => handleRemove(index)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                width: "24px",
                height: "24px",
                backgroundColor: "rgba(221, 44, 0, 0.8)",
                color: "white",
                border: "none",
                borderRadius: "12px",
                fontSize: "12px",
                padding: 0,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              ×
            </button>
            <div
              style={{
                position: "absolute",
                bottom: "5px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "white",
                padding: "2px 8px",
                borderRadius: "10px",
                fontSize: "12px",
              }}
            >
              {index + 1}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PosterManager;
