import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import API from "../../api/api";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { IEventBanner } from "./interface/interface";
import SmallForm from "../../components/form/small_form";

const EventBanner = () => {
  const [bannerList, setBannerList] = useState<IEventBanner[]>([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [url, setUrl] = useState("");
  const [startAt, setStartAt] = useState("");
  const [closeAt, setCloseAt] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");
  const [detail, setDetail] = useState("");
  const [type, setType] = useState<null | any>(null);

  const [detailFile, setDetailFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const typeList = [
    {
      id: 1,
      name: "HOME",
    },
    {
      id: 2,
      name: "EVENT",
    },
  ];
  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "제목",
        width: "100%",
        type: "input",
      },
      {
        value: type,
        setValue: setType,
        options: typeList,
        label: "배너타입",
        width: "100%",
        type: "category",
      },
      {
        value: startAt,
        setValue: setStartAt,
        label: "시작일시",
        width: "100%",
        type: "date",
      },
      {
        value: closeAt,
        setValue: setCloseAt,
        label: "종료일시",
        width: "100%",
        type: "date",
      },
      {
        value: description,
        setValue: setDescription,
        label: "배너 링크",
        width: "100%",
        type: "text",
      },
      {
        value: link,
        setValue: setLink,
        label: "상세보기에서 이동하는 링크",
        width: "100%",
        type: "input",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "사진",
        width: "100%",
        type: "file",
      },
      {
        value: url,
        setValue: setUrl,
        label: "이미지",
        width: "100%",
        type: "image",
      },
      {
        value: detailFile,
        setValue: setDetailFile,
        label: "사진",
        width: "100%",
        type: "file",
      },
      {
        value: detail,
        setValue: setDetail,
        label: "이미지",
        width: "100%",
        type: "image",
      },
      {
        value: backgroundColor,
        setValue: setBackgroundColor,
        label: "배경색",
        width: "100%",
        type: "input-color",
      },
    ],
  };

  const headers = [
    {
      text: "id",
      align: "start",
      sortable: false,
      value: "id",
    },
    {
      text: "타이틀",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "링크",
      align: "center",
      sortable: false,
      value: "description",
    },
    {
      text: "시작일시",
      align: "center",
      sortable: false,
      value: "startAt",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "closeAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(0);
    setTitle("");
    setDescription("");
    setLink("");
    setUrl("");
    setBackgroundColor("");
    setStartAt("");
    setCloseAt("");
    setDetail("");
    setDetailFile(null);
    setSelectedFile(null);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function formatJsonDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    const body = {
      title,
      description,
      backgroundColor,
      link,
      startAt: formatJsonDate(startAt),
      closeAt: formatJsonDate(closeAt),
      type: type.name,
    };
    console.log(body);
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (!selectedFile || !detailFile) {
      alert("이미지를 선택해주세요.");
      return;
    }
    if (detailFile) {
      formData.append("detail", detailFile);
    }
    formData.append("bannerImage", selectedFile);
    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerEventBanner(formData);
        if (res && res.status === 200) {
          alert("신규 등록 되었습니다.");
          getNoticeList();
          addForm();
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeEventBanner(id);
      if (res && res.status === 200) {
        alert("삭제 되었습니다.");
        getNoticeList();
        addForm();
      }
      setIsLoading(false);
    }
  };

  const save = async () => {
    const body = {
      title,
      description,
      backgroundColor,
      link,
      startAt: formatJsonDate(startAt),
      closeAt: formatJsonDate(closeAt),
      type: type.name,
    };
    console.log(body);
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (selectedFile) {
      formData.append("bannerImage", selectedFile);
    }
    if (detailFile) {
      formData.append("detail", detailFile);
    }
    setIsLoading(true);
    try {
      const res = await AdminAPI.updateEventBanner(id, formData);
      if (res && res.status === 200) {
        alert("저장 되었습니다.");
        getNoticeList();
        addForm();
        setIsLoading(false);
      }
    } catch (e: any) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const setDetails = (item: IEventBanner) => {
    setMode("U");
    setId(item.id);
    setTitle(item.title);
    setDescription(item.description);
    setStartAt(item.startAt);
    setCloseAt(item.closeAt);
    setLink(item.link);
    setUrl(item.url);
    setBackgroundColor(item.backgroundColor);
    setSelectedFile(null);
    setDetail(item.detail ? item.detail : "");
    setDetailFile(null);
    setType({ id: 1, name: item.type } || null);
  };

  const getNoticeList = async () => {
    const res = await API.getEventBanner();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IEventBanner) => ({
        ...item,
        closeAt: formatDate(item.closeAt),
        startAt: formatDate(item.startAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setBannerList(updatedData);
    }
  };
  useEffect(() => {
    getNoticeList();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          이벤트 배너 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={bannerList}
            setDetail={setDetails}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={bannerList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default EventBanner;
