import { useState } from "react";
import Category from "../../components/category";
import DivStyle from "../../components/style/div.component";
import UserNowUpdate from "../../components/userNowMessage/userNowUpdate";
import UserNowLog from "../../components/userNowMessage/userNowLog";
import UserNow from "../../components/userNowMessage/userNow";

const UserNowMessage = () => {
  const [selectTab, setSelectTab] = useState(0);
  const tabs = [
    {
      text: "유저 NOW 등록 현황",
      value: 0,
    },
    {
      text: "유저 NOW 삭제",
      value: 1,
    },
    {
      text: "유저 NOW 로그",
      value: 2,
    },
  ];
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      width="100%"
      height="100vh"
      backgroundColor="#121212"
      display="flex"
      flex_direction="column"
      marginTop="65px"
      setMobile={true}
      align_items="center"
      marginBottom="40px"
    >
      <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
        러너 포인트
      </h2>
      <Category tabs={tabs} selectTab={selectTab} setSelectTab={setSelectTab} />
      {selectTab === 0 && <UserNow />}
      {selectTab === 1 && <UserNowUpdate />}
      {selectTab === 2 && <UserNowLog />}
    </DivStyle>
  );
};

export default UserNowMessage;
