import { useState } from "react";
import API from "../api/api";
import ButtonStyle from "../components/style/button.component";
import DivStyle from "../components/style/div.component";
import InputStyle from "../components/style/input.component";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const res = await API.login({
        textId: email,
        password: password,
        loginType: "local",
      });
      if (res.data.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        navigate("/");
      } else {
        setErrorMsg("로그인 실패");
      }
    } catch (error) {
      setErrorMsg("로그인 실패");
    }
  };
  return (
    <DivStyle
      backgroundColor="gray"
      height="100vh"
      display="flex"
      flex_direction="column"
      justify_content="center"
      align_items="center"
    >
      <DivStyle
        width="700px"
        height="auto"
        display="flex"
        flex_direction="column"
        justify_content="center"
        align_items="center"
      >
        <div>
          <h1 style={{ color: "white" }}>러너러너 관리자</h1>
          <h4
            className="text-des"
            style={{ color: "black", marginBottom: "20px" }}
          >
            러너러너에서 홀덤펍을 관리하세요.
          </h4>
        </div>

        <DivStyle
          display="flex"
          flex_direction="column"
          justify_content="center"
          align_items="center"
          borderRadius="14px"
          backgroundColor="white"
          width="500px"
        >
          <h4 className="text-des" style={{ color: "#d9001b" }}>
            {errorMsg}
          </h4>
          <DivStyle
            display="flex"
            flex_direction="column"
            align_items="center"
            justify_content="center"
          >
            <InputStyle
              margin_bottom="30px"
              margin_top="50px"
              width="300px"
              height="50px"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
            />
            <InputStyle
              margin_bottom="40px"
              width="300px"
              height="50px"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
            <ButtonStyle
              width="200px"
              backgroundColor="#1976D2"
              color="white"
              fontSize="16px"
              fontWeight="bold"
              marginBottom="50px"
              border="none"
              borderRadius="10px"
              onClick={() => {
                handleLogin();
              }}
            >
              Login
            </ButtonStyle>
          </DivStyle>
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Login;
