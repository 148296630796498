import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import AdminAPI from "../../api/admin";
interface RankingLogResponse {
  id: number;
  userId: number;
  nickname: string;
  success: boolean;
  beforeGamePoint: number;
  afterGamePoint: number;
  gamePoint: number;
  ranking: number;
  groupId: number;
  createdAt: string;
}
const AutoRankingLog = () => {
  const [dataList, setDataList] = useState<RankingLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const headers = [
    { text: "지급일", align: "center", sortable: false, value: "createdAt" },
    { text: "userId", align: "center", sortable: false, value: "userId" },
    { text: "닉네임", align: "center", sortable: false, value: "nickname" },
    { text: "성공여부", align: "center", sortable: false, value: "success" },
    {
      text: "이전 포인트",
      align: "center",
      sortable: false,
      value: "beforeGamePoint",
    },
    {
      text: "이후 포인트",
      align: "center",
      sortable: false,
      value: "afterGamePoint",
    },
    {
      text: "지급 포인트",
      align: "center",
      sortable: false,
      value: "gamePoint",
    },
    { text: "순위", align: "center", sortable: false, value: "ranking" },
    { text: "groupId", align: "center", sortable: false, value: "groupId" },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getDatas = async () => {
    const res = await AdminAPI.getRankingPointLog();
    if (res) {
      const updatedData = res.map((item: RankingLogResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.length / 10);
      setTotalPages(totalPages);
      setDataList(updatedData);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);
  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
      >
        <h2 style={{ color: "white", width: "95%", marginTop: "40px" }}>
          RunnerRunner DashBoard
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
    </>
  );
};

export default AutoRankingLog;
