import { useEffect, useState } from "react";
import AdminAPI from "../../api/admin";
import { ReviewResponse } from "./interface";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import { FormProps } from "../../components/form/interface/formInterface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

const Review = () => {
  const [dataList, setDataList] = useState<ReviewResponse[]>([]);
  const [searchDataList, setSearchDataList] = useState<ReviewResponse[]>([]);

  const [mode, setMode] = useState("V");

  const [id, setId] = useState(0);
  const [userName, setUserName] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [pubId, setPubId] = useState(0);
  const [message, setMessage] = useState("");
  const [imageList, setImageList] = useState<string[]>([]);
  const [score, setScore] = useState(0);
  const [createdAt, setCreatedAt] = useState("");

  const [selectedFile, setSelectedFile] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const setIsLoading = useSetRecoilState(loadingState);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getList = async () => {
    const res = await AdminAPI.getPubReviews();
    if (res) {
      console.log(res.data);
      setDataList(res.data);
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);
      setSearchDataList(res.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const formData: FormProps = {
    title: "폼",
    form: [
      {
        value: pubId,
        setValue: setPubId,
        label: "펍 ID",
        type: "input",
        width: "100%",
      },
      {
        value: userName,
        setValue: setMessage,
        label: "닉네임",
        type: "input-dash",
        width: "100%",
      },
      {
        value: score,
        setValue: setScore,
        label: "별점",
        type: "input",
        width: "100%",
      },
      {
        value: message,
        setValue: setMessage,
        label: "내용",
        type: "text",
        width: "100%",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "사진",
        width: "100%",
        type: "files",
      },
      {
        value: {
          value1: imageList,
          setDeleteImage,
        },
        setValue: setImageList,
        label: "사진",
        width: "100%",
        type: "images",
      },
    ],
  };

  const headers = [
    {
      text: "펍ID",
      align: "center",
      sortable: false,
      value: "pubId",
    },
    {
      text: "펍",
      align: "center",
      sortable: false,
      value: "cafeName",
    },
    {
      text: "별점",
      align: "center",
      sortable: false,
      value: "score",
    },
    {
      text: "내용",
      align: "center",
      sortable: false,
      value: "message",
    },
    {
      text: "생성일",
      align: "start",
      sortable: false,
      value: "createdAt",
    },
  ];

  const setDetail = async (item: ReviewResponse) => {
    setMode("U");
    setId(item.id);
    setPubId(item.pubId);
    setImageList(item.imageList);
    setMessage(item.message);
    setScore(item.score);
    setUserName(item.userName);
    setUserProfile(item.userProfile);
    setSelectedFile([]);
    setDeleteImage([]);
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setPubId(0);
    setUserName("");
    setScore(0);
    setUserProfile("");
    setMessage("");
    setSelectedFile([]);
    setDeleteImage([]);
    setImageList([]);
  };
  const save = async () => {
    const body = {
      id,
      message,
      pubId,
      score,
    };
    const formData = new FormData();
    for (const img of selectedFile) {
      formData.append("imageList", img);
    }
    if (deleteImage.length > 0) {
      formData.append(
        "deleteImages",
        new Blob([JSON.stringify(deleteImage)], {
          type: "application/json",
        })
      );
    }

    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (window.confirm("저장 하시겠습니까?")) {
      setIsLoading(true);
      const res_meta = await AdminAPI.updateReview(formData, id);
      if (res_meta && res_meta.status === 200) {
        alert("저장되었습니다.");
        addForm();
        getList();
      }
    }
    setIsLoading(false);
  };

  const remove = async () => {
    // if (window.confirm("삭제 하시겠습니까?")) {
    //   setIsLoading(true);
    //   const res = await AdminAPI.removeYoutubeChannel(id);
    //   if (res && res.status === 200) {
    //     alert("삭제 되었습니다.");
    //     getYoutube();
    //     addForm();
    //   }
    // }
    // setIsLoading(false);
  };
  const add = async () => {
    const body = {
      message,
      pubId,
      score,
    };
    // setIsLoading(true);
    // const res = await AdminAPI.registerYoutubeChannel(body);
    // if (res && res.status === 200) {
    //   alert("신규 등록이 되었습니다.");
    //   getYoutube();
    //   addForm();
    // }
    // setIsLoading(false);
  };
  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: ReviewResponse) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          리뷰 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={searchDataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchDataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Review;
