import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";
import ButtonStyle from "../style/button.component";

const PremiumTable = (props: TableProps) => {
  const {
    headers,
    currentPage,
    dataList,
    setDataList,
    setDeleteList,
    deleteList,
  } = props;
  const pageSize = 50; // 페이지당 표시할 항목 수

  const getDataForPage = (page: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataList.slice(startIndex, endIndex);
  };
  function getNestedData(data: any, path: string) {
    const keys = path.split(".");
    let result = data;
    for (let key of keys) {
      result = result[key];
      if (result === undefined) break;
    }
    return result;
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return; // dropped outside the list

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) {
      return; // 위치가 같으면 아무것도 하지 않음
    }

    const updatedItems: any = Array.from(dataList);
    const [reorderedItem] = updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(destinationIndex, 0, reorderedItem);

    if (setDataList) {
      setDataList(updatedItems);
    }

    // 드래그 방향에 따라 함수 호출
    if (destinationIndex > sourceIndex) {
      down(sourceIndex, destinationIndex); // 아래로 이동
    } else {
      up(sourceIndex, destinationIndex); // 위로 이동
    }
  };

  function up(sourceIndex: number, destinationIndex: number) {
    // 데이터 리스트를 복사하여 불변성을 유지
    const updatedData: any = [...dataList];

    // sourceIndex의 원소를 destinationIndex로 이동
    const itemToMove = updatedData[sourceIndex];
    updatedData.splice(sourceIndex, 1); // 원래 위치에서 제거
    updatedData.splice(destinationIndex, 0, itemToMove); // 새 위치에 삽입

    // 인덱스 조정을 위해 전체 배열을 업데이트
    for (
      let i = Math.min(sourceIndex, destinationIndex);
      i <= Math.max(sourceIndex, destinationIndex);
      i++
    ) {
      updatedData[i].cafeIndex = i + 1; // cafeIndex를 배열의 현재 위치에 맞춰 조정
    }

    // 교환된 데이터로 상태 업데이트
    if (setDataList) {
      setDataList(updatedData);
    }
  }

  function down(sourceIndex: number, destinationIndex: number) {
    const updatedData: any = [...dataList];

    const itemToMove = updatedData[sourceIndex];
    updatedData.splice(sourceIndex, 1);
    updatedData.splice(destinationIndex, 0, itemToMove);

    for (
      let i = Math.min(sourceIndex, destinationIndex);
      i <= Math.max(sourceIndex, destinationIndex);
      i++
    ) {
      updatedData[i].cafeIndex = i + 1;
    }

    if (setDataList) {
      setDataList(updatedData);
    }
  }

  const remove = (id: number) => {
    const itemIndex = dataList.findIndex((item: any) => item.id === id);
    const itemToDelete: any = dataList[itemIndex];
    const updatedDataList: any = dataList.filter((item: any) => item.id !== id);

    for (let i = itemIndex; i < updatedDataList.length; i++) {
      updatedDataList[i].cafeIndex = i + 1;
    }

    if (setDataList) {
      setDataList(updatedDataList);
    }

    if (itemToDelete && setDeleteList && deleteList) {
      if (!deleteList?.some((item: any) => item.id === itemToDelete.id)) {
        itemToDelete.cafeIndex = null;
        const newList: any = [...deleteList, itemToDelete];
        setDeleteList(newList);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <TableStyle
            marginTop="10px"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <thead>
              <tr>
                {headers.map((header: Header) => (
                  <th key={header.value}>{header.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getDataForPage(currentPage).map((data: any, index: number) => (
                <Draggable
                  key={index}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {headers.map((header) =>
                        header.value === "id" ? (
                          <td key={header.value}>
                            <ButtonStyle
                              width="70px"
                              height="30px"
                              border="none"
                              backgroundColor="red"
                              color="white"
                              borderRadius="5px"
                              onClick={() => remove(data.id)}
                            >
                              삭제
                            </ButtonStyle>
                          </td>
                        ) : header.value.includes(".") ? (
                          <td key={header.value}>
                            {getNestedData(data, header.value)}
                          </td>
                        ) : (
                          <td key={header.value}>{data[header.value]}</td>
                        )
                      )}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </TableStyle>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default PremiumTable;
