import { useEffect, useState } from "react";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";
import ButtonStyle from "../style/button.component";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import AdminAPI from "../../api/admin";

const CommnetTable = (props: TableProps) => {
  const [isWide, setIsWide] = useState(window.innerWidth > 700);
  const { headers, currentPage, dataList, setDetail } = props;
  const pageSize = 10; // 페이지당 표시할 항목 수
  const getDataForPage = (page: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataList.slice(startIndex, endIndex);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setIsWide(false);
      } else {
        setIsWide(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isWide ? (
    <TableStyle marginTop="30px">
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr key={index}>
            {headers.map((header) =>
              header.value !== "action" ? (
                <td key={header.value}>{data[header.value]}</td>
              ) : null
            )}
            <td>
              <ButtonStyle
                height="40px"
                width="60px"
                border="none"
                backgroundColor="#272727"
                borderRadius="5px"
                onClick={() => setDetail(data.id)}
              >
                <Icon path={mdiDelete} size={0.7} style={{ color: "white" }} />
              </ButtonStyle>
            </td>
          </tr>
        ))}
      </tbody>
    </TableStyle>
  ) : (
    <TableStyle setMobile={true} marginTop="30px">
      <tbody>
        {getDataForPage(currentPage).map((data: any, index: number) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header.value}>
                <div>{header.text}</div>
                {header.value !== "action" ? (
                  <td key={header.value}>{data[header.value]}</td>
                ) : (
                  <ButtonStyle
                    height="40px"
                    width="60px"
                    border="none"
                    backgroundColor="#272727"
                    borderRadius="5px"
                    onClick={() => setDetail(data.id)}
                  >
                    <Icon
                      path={mdiDelete}
                      size={0.7}
                      style={{ color: "white" }}
                    />
                  </ButtonStyle>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default CommnetTable;
