import { apiRequest } from "../../utils/apiUtils";
import { LoginParams } from "../../types";

export const authApi = {
  login: (params: LoginParams) =>
    apiRequest<any>("post", "/auth/login", {
      data: params,
      fullResponse: true,
      requiresAuth: false,
    }),
  getInfo: () => apiRequest<any>("get", "/auth/detail", { fullResponse: true }),
  exitUser: (id: number) =>
    apiRequest<any>("delete", "/auth/exit", {
      params: { userId: id },
      fullResponse: true,
    }),
  findByNickName: (nickName: string) =>
    apiRequest<any>("get", "/auth/findByNickname", {
      params: { nickname: nickName },
      fullResponse: true,
    }),
  verfiyNickName: (nickName: string) =>
    apiRequest<{ verified: boolean }>("get", "/auth/verified/nickname", {
      params: { nickname: nickName },
    }).then((response) => {
      if (response && typeof response === "object" && "verified" in response) {
        return response.verified;
      }
      return false;
    }),
};
