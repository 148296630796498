import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginState } from "../recoil/auth.atom";

const PublicRoute = () => {
  const isLoggedIn = useRecoilValue(loginState);
  const isAuthenticated = isLoggedIn || localStorage.getItem("token");

  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
