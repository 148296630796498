import { useEffect, useState } from "react";
import { UserJobOpeningResponse } from "./interface";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import { UserState } from "../../recoil/user.atom";
import { IUser } from "../user/interface/interface";
import DivStyle from "../../components/style/div.component";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import UserJobForm from "../../components/form/user_job_form";

const UserRecruiting = () => {
  const [dataList, setDataList] = useState<UserJobOpeningResponse[]>([]);
  const [searchDataList, setSearchDataList] = useState<
    UserJobOpeningResponse[]
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const user: any | IUser = useRecoilValue(UserState);
  const [id, setId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [cafeName, setCafeName] = useState("");
  const [recruitType, setRecruitType] = useState("");
  const [state, setState] = useState("");
  const [place, setPlace] = useState("");
  const [recruitTypeEtc, setRecruitTypeEtc] = useState("");
  const [workingDaysTime, setWorkingDaysTime] = useState("");
  const [ageLimit, setAgeLimit] = useState<number[]>([]);
  const [gender, setGender] = useState("");
  const [hourlyPay, setHourlyPay] = useState(0);
  const [benefits, setBenefits] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [endDate, setEndDate] = useState<string | null>("");
  const [employmentValue, setEmploymentValue] = useState("");
  const [benefitValue, setBenefitValue] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const pageSize = 10;

  const setIsLoading = useSetRecoilState(loadingState);

  const [mode, setMode] = useState("I");

  const formData: FormProps = {
    title: "유저 구인 등록",
    form: [
      {
        value: cafeName,
        setValue: setCafeName,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: place,
        setValue: setPlace,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: state,
        setValue: setState,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: {
          value1: recruitType,
          value2: employmentValue,
          setValue2: setEmploymentValue,
        },
        setValue: setRecruitType,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: workingDaysTime,
        setValue: setWorkingDaysTime,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: ageLimit,
        setValue: setAgeLimit,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: gender,
        setValue: setGender,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: hourlyPay,
        setValue: setHourlyPay,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: benefits,
        setValue: setBenefits,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: contactInfo,
        setValue: setContactInfo,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: email,
        setValue: setEmail,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: description,
        setValue: setDescription,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: endDate,
        setValue: setEndDate,
        label: "",
        width: "100%",
        type: "",
      },
      {
        value: additionalInfo,
        setValue: setAdditionalInfo,
        label: "",
        width: "100%",
        type: "",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "유저 ID",
      align: "center",
      sortable: false,
      value: "userId",
    },
    {
      text: "유저 ID",
      align: "center",
      sortable: false,
      value: "textId",
    },
    {
      text: "펍 이름",
      align: "center",
      sortable: false,
      value: "cafeName",
    },
    {
      text: "접수기간",
      align: "center",
      sortable: false,
      value: "endDate",
    },
    {
      text: "구인분야",
      align: "center",
      sortable: false,
      value: "recruitType",
    },
    {
      text: "성별",
      align: "center",
      sortable: false,
      value: "gender",
    },
    {
      text: "주소",
      align: "center",
      sortable: false,
      value: "place",
    },
    {
      text: "시급",
      align: "center",
      sortable: false,
      value: "hourlyPay",
    },
    {
      text: "채용상태",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];

  const addForm = () => {
    setIsNewOpen(true);
    setId(0);
    setUserId(0);
    setCafeName("");
    setPlace("");
    setRecruitType("");
    setRecruitTypeEtc("");
    setEmploymentValue("");
    setState("");
    setAgeLimit([]);
    setWorkingDaysTime("");
    setHourlyPay(0);
    setBenefits("");
    setBenefitValue("");
    setContactInfo("");
    setEmail("");
    setDescription("");
    setEndDate("");
    setGender("무관");
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: UserJobOpeningResponse) => {
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getList = async () => {
    const res = await AdminAPI.getUserJobOpening();
    if (res) {
      const updatedData = res.map((item: UserJobOpeningResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      setDataList(updatedData);
      const totalPages = Math.ceil(res.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(updatedData);
    }
  };

  const setting = (item: UserJobOpeningResponse) => {
    setMode("U");
    setId(item.id);
    setUserId(item.userId);
    setCafeName(item.cafeName);
    setPlace(item.place);
    setState(item.state);
    setRecruitType(item.recruitType);
    setRecruitTypeEtc(item.recruitTypeEtc);
    setWorkingDaysTime(item.workingDaysTime);
    setAgeLimit(item.ageLimits);
    setHourlyPay(item.hourlyPay);
    setGender(item.gender);
    setBenefits(item.benefits);
    setContactInfo(item.contactInfo);
    setEmail(item.email);
    setAdditionalInfo(item.additionalInfo);
    setDescription(item.description);
    setEndDate(item.endDate === "상시" ? null : item.endDate);
  };

  const save = async () => {
    const body = {
      id,
      userId,
      cafeName,
      state,
      recruitType,
      recruitTypeEtc,
      ageLimits: ageLimit,
      gender,
      workingDaysTime: workingDaysTime === "" ? "협의" : workingDaysTime,
      hourlyPay: hourlyPay === 0 ? null : hourlyPay,
      place: place,
      benefits,
      contactInfo,
      email,
      description,
      isPermanentRecruitment: endDate === "",
      endDate,
      additionalInfo,
    };
    if (window.confirm("저장 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.updateUserJobOpening(body);
      if (res && res.status === 200) {
        alert("저장되었습니다.");
        setIsOpen(false);
        getList();
      } else {
        alert("저장 실패하였습니다.");
      }
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.deleteUserJobOpening(id);
      if (res && res.status === 200) {
        alert("삭제되었습니다.");
        setIsOpen(false);
        getList();
      } else {
        alert("삭제 실패하였습니다.");
      }
      setIsLoading(false);
    }
    setIsOpen(false);
  };

  const add = async () => {
    const body = {
      userId: user.id,
      state,
      cafeName,
      recruitType,
      recruitTypeEtc,
      ageLimits: ageLimit,
      gender,
      workingDaysTime: workingDaysTime === "" ? "협의" : workingDaysTime,
      hourlyPay: hourlyPay === 0 ? null : hourlyPay,
      place: place,
      benefits,
      contactInfo,
      email,
      description,
      isPermanentRecruitment: endDate === "",
      endDate,
      additionalInfo,
    };
    if (window.confirm("추가 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.registerUserJobOpening(body);
      if (res && res.status === 200) {
        alert("추가 되었습니다.");
        setIsOpen(false);
        getList();
      } else {
        alert("추가 실패하였습니다.");
      }
      setIsOpen(false);
      setIsNewOpen(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        width="100%"
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
        onClick={() => {
          if (isOpen) setIsOpen(false);
        }}
      >
        <DivStyle
          width="90%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchDataList}
            setDetail={setting}
            setIsOpen={setIsOpen}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchDataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="90%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
        {isNewOpen ? (
          <UserJobForm
            add={add}
            save={save}
            remove={remove}
            mode={mode}
            form={formData.form}
            title={formData.title}
          />
        ) : null}
      </DivStyle>
      {isOpen ? (
        <UserJobForm
          add={add}
          save={save}
          remove={remove}
          mode={mode}
          form={formData.form}
          title={formData.title}
        />
      ) : null}
    </>
  );
};

export default UserRecruiting;
