import { adminApiRequest } from "../../utils/apiUtils";

export interface AttendanceTableRequest {
  startAt: string;
  closeAt: string;
}

export interface AttendanceRewardRequest {
  value: number;
  tableId: number;
}

export interface AttendanceTableResponse {
  startAt: string;
  closeAt: string;
  id: number;
  createdAt: string;
}

export interface AttendanceRewardResponse {
  createdAt: string;
  id: number;
  tableId: number;
  value: number;
}

export interface AttendanceUserDto {
  value: number;
  nickname: string;
  createdAt: string;
}

export interface AttendanceUserResponse {
  total: number;
  data: AttendanceUserDto[];
}
export const AdminAttendanceAPI = {
  registerAttendanceTable: (request: AttendanceTableRequest) => {
    return adminApiRequest.post("/attendance", request).then((res) => res);
  },
  getAttendanceTable: (): Promise<AttendanceTableResponse[]> => {
    return adminApiRequest.get("/attendance").then((res) => res.data);
  },
  updateAttendanceTable: (id: number, request: AttendanceTableRequest) => {
    return adminApiRequest
      .put(`/attendance/${id}`, request)
      .then((res) => res.data);
  },
  removeAttendanceTable: (id: number) => {
    return adminApiRequest.delete(`/attendance/${id}`).then((res) => res.data);
  },
  registerAttendanceReward: (request: AttendanceRewardRequest) => {
    return adminApiRequest
      .post("/attendance/reward", request)
      .then((res) => res);
  },
  removeAttendanceReward: (id: number) => {
    return adminApiRequest
      .delete(`/attendance/reward/${id}`)
      .then((res) => res.data);
  },
  updateAttendanceReward: (id: number, request: AttendanceRewardRequest) => {
    return adminApiRequest
      .put(`/attendance/reward/${id}`, request)
      .then((res) => res.data);
  },
  getAttendanceReward: (
    tableId: number
  ): Promise<AttendanceRewardResponse[]> => {
    return adminApiRequest
      .get(`/attendance/reward/${tableId}`)
      .then((res) => res.data);
  },
  createAutoReward: (tableId: number) => {
    return adminApiRequest
      .post(`/attendance/rewards/${tableId}`)
      .then((res) => res.data);
  },
  getAttendanceUser: (
    page: number,
    limit: number,
    keyword: string
  ): Promise<AttendanceUserResponse> => {
    return adminApiRequest
      .get(
        `/attendance/user-reward?page=${page}&limit=${limit}&keyword=${keyword}`
      )
      .then((res) => res.data);
  },
};
