import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import API from "../../api/api";
import SmallForm from "../../components/form/small_form";
import AdminAPI from "../../api/admin";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { IUser } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import UserPage from "../../components/table/userPaing";
import CafePagingTable from "../../components/table/CafePagingTable";
import GAMEAPI from "../../api/game";
import FormInput from "../../components/form/form_input";
import ButtonStyle from "../../components/style/button.component";
import FormNickNameInput from "../../components/form/form_nickname";
interface IRole {
  type: string;
  value: string;
}
const User = () => {
  const [userList, setUserList] = useState<IUser[]>([]);
  const [searchUserList, setSearchUserList] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("V");
  const setIsLoading = useSetRecoilState(loadingState);

  const [userId, setUserId] = useState(0);
  const [username, setUserName] = useState("");
  const [nickname, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [accountInfo, setAccountInfo] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [point, setPoint] = useState(0);
  const [rebuyinTicket, setRebuyinTicket] = useState(0);

  const [checkNickName, setCheckNickName] = useState(true);
  const [changeNickName, setChangeNickName] = useState("");

  const roles: IRole[] = [
    {
      type: "ROLE_USER",
      value: "일반유저",
    },
    {
      type: "ROLE_SELLER",
      value: "판매자",
    },
    {
      type: "ROLE_ADMIN",
      value: "어드민",
    },
    {
      type: "ROLE_DEALER",
      value: "딜러",
    },
  ];
  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        type: "input-dash",
        width: "100%",
      },
      {
        value: username,
        setValue: setEmail,
        label: "사용자명",
        type: "input-dash",
        width: "100%",
      },
      {
        value: nickname,
        setValue: setEmail,
        label: "닉네임",
        type: "input-dash",
        width: "100%",
      },
      {
        value: birthDay,
        setValue: setEmail,
        label: "생년월일",
        type: "input-dash",
        width: "100%",
      },
      {
        value: phoneNumber,
        setValue: setEmail,
        label: "핸드폰번호",
        type: "input-dash",
        width: "100%",
      },
      {
        value: gender,
        setValue: setEmail,
        label: "성별",
        type: "input-dash",
        width: "100%",
      },
      {
        value: accountInfo,
        setValue: setEmail,
        label: "계좌번호",
        type: "input-dash",
        width: "100%",
      },
      {
        value: role,
        setValue: setRole,
        options: [
          {
            value: "어드민",
          },
          {
            value: "판매자",
          },
          {
            value: "일반유저",
          },
          {
            value: "딜러",
          },
        ],
        label: "권한",
        type: "select",
        width: "100%",
      },
      {
        value: point,
        setValue: setPoint,
        label: "게임포인트",
        type: "input-dash",
        width: "100%",
      },
      {
        value: rebuyinTicket,
        setValue: setRebuyinTicket,
        label: "리바인권",
        type: "input-dash",
        width: "100%",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "아이디",
      align: "start",
      sortable: false,
      value: "textId",
    },
    {
      text: "사용자명",
      align: "center",
      sortable: false,
      value: "username",
    },
    {
      text: "닉네임",
      align: "center",
      sortable: false,
      value: "nickname",
    },
    {
      text: "생년월일",
      align: "center",
      sortable: false,
      value: "birthday",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "권한",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "가입일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "가입 방식",
      align: "center",
      sortable: false,
      value: "type",
    },
  ];
  const setDetail = async (item: IUser) => {
    setMode("U");
    setEmail(item.textId);
    setUserId(item.id);
    setUserName(item.username);
    setNickName(item.nickname);
    setChangeNickName(item.nickname);
    setPhoneNumber(item.phoneNumber);
    setBirthDay(item.birthDay);
    setRole(item.role);
    setGender(item.gender);
    setAccountInfo(item.accountInfo);
    const user_game_data = await GAMEAPI.getPointAndRebuyIn(item.id);
    setPoint(user_game_data.point);
    setRebuyinTicket(user_game_data.rebuyinTicket);
    setCheckNickName(true);
  };
  const addForm = () => {
    setMode("V");
    setEmail("");
    setUserId(0);
    setUserName("");
    setNickName("");
    setChangeNickName("");
    setPhoneNumber("");
    setBirthDay("");
    setRole("");
    setGender("");
    setAccountInfo("");
    setPoint(0);
    setRebuyinTicket(0);
    setCheckNickName(true);
  };
  const RoleType = (value: string) => {
    let rtn = "";
    if (value) {
      rtn = roles.find((element: IRole) => element.value === value)?.type || "";
    }
    return rtn;
  };
  const save = async () => {
    if (window.confirm("저장 하시겠습니까?")) {
      if (userId > 0) {
        setIsLoading(true);
        const res = await AdminAPI.updateRole(userId, RoleType(role));
        if (res) {
          if (res.status === 200) {
            alert("저장 되었습니다.");
            getUsers();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };
  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (userId > 0) {
        setIsLoading(true);
        const res = await API.exitUser(userId);
        if (res) {
          if (res.status === 200) {
            alert("삭제 되었습니다.");
            getUsers();
            addForm();
          }
        }
        setIsLoading(false);
      }
    }
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function formatBirthDay(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const RoleName = (value: string) => {
    let rtn = "";
    if (value) {
      rtn = roles.find((element: IRole) => element.type === value)?.value || "";
    }
    return rtn;
  };
  const getUsers = async () => {
    const res = await AdminAPI.getUsers(0);
    if (res && res.status === 200) {
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        role: RoleName(item.role),
        birthday: formatBirthDay(item.birthday),
        createdAt: formatDate(item.createdAt),
        type: getRegisterType(item),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setUserList(updatedData);
      setSearchUserList(updatedData);
    }
  };
  const updateNickName = async () => {
    if (userId === 0) {
      alert("유저를 선택해주세요.");
      return;
    }
    console.log(changeNickName.length);
    if (changeNickName.length < 2 || changeNickName.length > 7) {
      alert("2~7자를 입력하세요.");
      return;
    }
    setIsLoading(true);
    const result = await AdminAPI.updateNickName(userId, changeNickName);
    if (result?.data.code === 4004) {
      alert("중복된 닉네임입니다.");
    } else if (result?.data.code === 200) {
      alert("수정되었습니다.");
      addForm();
      getUsers();
    }
    setIsLoading(false);
  };
  const getRegisterType = (user: IUser) => {
    if (user.appleId) return "apple";
    if (user.kakaoId) return "kakao";
    if (user.naverId) return "naver";
    if (user.googleId) return "google";
    return "normal";
  };
  const handleSearch = async (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
    setIsSearch(true);
    if (!search.trim()) {
      setIsSearch(false);
      getUsers();
      return;
    }

    const res = await AdminAPI.findUsers(search, currentPage - 1);
    if (res && res.status === 200) {
      console.log(res.data.userList);
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        role: RoleName(item.role),
        birthday: formatBirthDay(item.birthday),
        createdAt: formatDate(item.createdAt),
        type: getRegisterType(item),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setSearchUserList(updatedData);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          사용자 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <CafePagingTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchUserList}
            setDetail={setDetail}
          />
          <UserPage
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchUserList}
            setCurrentPage={setCurrentPage}
            count={totalCount}
            setDataList={setUserList}
            setSearchDataList={setSearchUserList}
            isSearch={isSearch}
            search={search}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <span
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "18px",
              color: "#FFFFFFB3",
              marginTop: "30px",
            }}
          >
            닉네임 변경
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormNickNameInput
              value={changeNickName}
              setValue={setChangeNickName}
              label="닉네임"
              width="70%"
              fontSize="13px"
            />
            <ButtonStyle
              marginLeft="10px"
              onClick={async () => {
                if (changeNickName.length < 2 || changeNickName.length > 7) {
                  alert("2~7자를 입력하세요.");
                  return;
                }
                if (checkNickName) {
                  const result = await API.verfiyNickName(changeNickName);
                  setCheckNickName(!result);
                  if (!result) {
                    alert("중복된 닉네임입니다.");
                  }
                } else {
                  updateNickName();
                }
              }}
              width="70px"
              height="40px"
              border="none"
              backgroundColor="#1976D2"
              borderRadius="5px"
              color="white"
            >
              {checkNickName ? "중복확인" : "수정하기"}
            </ButtonStyle>
          </div>
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default User;
