import { adminApiRequest } from "../../utils/apiUtils";

export const FaqAPI = {
  updateFaq: (body: any) => {
    return adminApiRequest.put("/faq/update", body).then((res) => res);
  },

  removeFaq: (id: number) => {
    return adminApiRequest.delete(`/faq/delete?id=${id}`).then((res) => res);
  },

  registerFaq: (body: any) => {
    return adminApiRequest.post("/faq/register", body).then((res) => res);
  },
};
