import { adminApiRequest } from "../../utils/apiUtils";

export const OpenChatAPI = {
  sortOpenChat: (body: any) => {
    return adminApiRequest
      .post("/community/open-chat/sort", body)
      .then((res) => res);
  },

  removeOpenChat: (id: number) => {
    return adminApiRequest
      .delete(`/community/open-chat?id=${id}`)
      .then((res) => res);
  },

  updateOpenChat: (id: number, body: any) => {
    return adminApiRequest
      .put(`/community/open-chat?id=${id}`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  registerOpenChat: (body: any) => {
    return adminApiRequest
      .post("/community/open-chat", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },
};
