import { adminApiRequest } from "../../utils/apiUtils";

export const BrandCompetitionAPI = {
  getBrandCompetitions: () => {
    return adminApiRequest.get("/brand-competition/list").then((res) => res);
  },

  registerBrandCompetition: (formData: FormData) => {
    return adminApiRequest
      .post("/brand-competition/register", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  removeBrandCompetition: (id: number) => {
    return adminApiRequest
      .delete(`/brand-competition/exit?id=${id}`)
      .then((res) => res);
  },

  updateBrandCompetitionMetaData: (body: any) => {
    return adminApiRequest
      .put("/brand-competition/update-metadata", body)
      .then((res) => res);
  },

  ChangeBrandCompetitionImage: (formData: FormData) => {
    return adminApiRequest
      .put("/brand-competition/update-image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },
};
