import { useState } from "react";
import Category from "../../components/category";
import DivStyle from "../../components/style/div.component";
import UserUse from "../../components/userNow/userUse";
import UserChargeBoard from "../../components/userNow/userChargeBoard";
import UserCharge from "../../components/userNow/userCharge";

const UserNow = () => {
  const [selectTab, setSelectTab] = useState(0);
  const tabs = [
    {
      text: "러너 포인트 사용 현황",
      value: 0,
    },
    {
      text: "러너 포인트 충전 현황",
      value: 1,
    },
    {
      text: "러너 포인트 수동 입금",
      value: 2,
    },
  ];
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      width="100%"
      height="100vh"
      backgroundColor="#121212"
      display="flex"
      flex_direction="column"
      marginTop="65px"
      setMobile={true}
      align_items="center"
      marginBottom="40px"
    >
      <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
        러너 포인트
      </h2>
      <Category tabs={tabs} selectTab={selectTab} setSelectTab={setSelectTab} />
      {selectTab === 0 && <UserUse />}
      {selectTab === 1 && <UserChargeBoard />}
      {selectTab === 2 && <UserCharge />}
    </DivStyle>
  );
};

export default UserNow;
