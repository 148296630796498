import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";
import FormCafeCheckInput from "./form_cafe_check";
import { useState } from "react";
import ButtonStyle from "../style/button.component";
import AdminAPI from "../../api/admin";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const PushCountForm = (props: InputFormProps) => {
  const { value, label, width, setValue } = props;
  const [count, setCount] = useState(0);
  const setLoading = useSetRecoilState(loadingState);
  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label style={{ color: "white", fontSize: "12px" }}>남은 횟수</label>

        <InputFormStyle
          width="100%"
          value={value.pushCount}
          placeholder={label}
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label style={{ color: "white", fontSize: "12px" }}>
          푸시 추가할 값(-입력시 빼기 가능)
        </label>

        <InputFormStyle
          width="100%"
          value={count === 0 ? "" : count}
          type="number"
          placeholder={"추가할 값"}
          onChange={(e) => {
            setCount(+e.target.value);
          }}
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ButtonStyle
          width="70px"
          height="40px"
          border="none"
          backgroundColor="#1976D2"
          borderRadius="50px"
          color="white"
          onClick={async () => {
            const request = {
              id: value.cafeId,
              count,
            };
            setLoading(true);
            try {
              await AdminAPI.calcGradePushCount(request);
              alert("수정되었습니다");
              const result = value.pushCount + count;
              setValue(result);
              setCount(0);
              setLoading(false);
            } catch (e) {
              alert("펍 등급이 부족합니다.");
              setLoading(false);
            }
          }}
        >
          수정하기
        </ButtonStyle>
      </div>
    </div>
  );
};

export default PushCountForm;
