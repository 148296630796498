import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import API from "../../api/api";
import SmallForm from "../../components/form/small_form";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import AdminAPI from "../../api/admin";
import { ArticleCommentReportResponse } from "../../api/admin/article";
enum ReportType {
  ADVERTISING = "ADVERTISING",
  OBSCENE = "OBSCENE",
  ABUSIVE = "ABUSIVE",
  VIOLENCE = "VIOLENCE",
  OTHER = "OTHER",
}
const ArticleCommentReport = () => {
  const [dataList, setDataList] = useState<ArticleCommentReportResponse[]>([]);

  const [mode, setMode] = useState("I");

  const [id, setId] = useState<number | null>(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [reportNickname, setReportNickname] = useState("");
  const [content, setContent] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const ReportTypeToKorean: Record<ReportType, string> = {
    [ReportType.ADVERTISING]: "상업적 광고",
    [ReportType.OBSCENE]: "음란물",
    [ReportType.ABUSIVE]: "욕설",
    [ReportType.VIOLENCE]: "폭력",
    [ReportType.OTHER]: "기타",
  };

  const setIsLoading = useSetRecoilState(loadingState);
  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: type,
        setValue: setType,
        label: "신고 분류",
        width: "100%",
        type: "input-dash",
      },
      {
        value: description,
        setValue: setDescription,
        label: "신고 내용",
        width: "100%",
        type: "text",
      },
      {
        value: reportNickname,
        setValue: setReportNickname,
        label: "신고 닉네임",
        width: "100%",
        type: "input-dash",
      },
      {
        value: content,
        setValue: setContent,
        label: "내용",
        width: "100%",
        type: "input-dash",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "댓글 ID",
      align: "center",
      sortable: false,
      value: "comment.id",
    },
    {
      text: "신고 분류",
      align: "center",
      sortable: false,
      value: "type",
    },
    {
      text: "신고자",
      align: "center",
      sortable: false,
      value: "reportNickname",
    },
    {
      text: "처리 여부",
      align: "center",
      sortable: false,
      value: "complete",
    },
    {
      text: "삭제 여부",
      align: "center",
      sortable: false,
      value: "delete",
    },
    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];

  const addForm = async () => {
    setId(0);
    setMode("I");
    setType("");
    setContent("");
    setDescription("");
    setReportNickname("");
  };

  const save = async () => {
    if (window.confirm("댓글 삭제 처리 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        const res = await AdminAPI.acceptBoardCommentReport(id);
        if (res) {
          alert("삭제되었습니다.");
          getReports();
          addForm();
        }
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        const res = await AdminAPI.removeBoardCommentReport(id);
        if (res) {
          alert("삭제되었습니다.");
          getReports();
          addForm();
        }
        setIsLoading(false);
      }
    }
  };

  const setDetail = (item: ArticleCommentReportResponse) => {
    setId(item.id);
    setMode("REPORT");
    setType(item.type);
    setDescription(item.description);
    setContent(item.comment.content);
    setReportNickname(item.reportNickname);
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getReports = async () => {
    const res = await AdminAPI.getArticleCommentReport();
    if (res) {
      const updatedData = res.map((item: ArticleCommentReportResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
        type: ReportTypeToKorean[item.type as ReportType],
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setDataList(updatedData);
    }
  };

  useEffect(() => {
    getReports();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          핸드게시판 댓글 신고
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            save={save}
            form={formData.form}
            title={formData.title}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default ArticleCommentReport;
