// form_file.tsx
import { useState } from "react";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiCamera } from "@mdi/js";
import { InputFormProps } from "./interface/formInterface";
import DivStyle from "../style/div.component";

interface FormFileInputProps extends InputFormProps {
  currentImageUrl?: string;
}

const FormFileInput = (props: FormFileInputProps) => {
  const { value, setValue, label, width, currentImageUrl } = props;
  return (
    <DivStyle width={width} display="flex" flex_direction="column">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span style={{ color: "white", marginRight: "7px" }}>{label}</span>
        <label htmlFor="input">
          <Icon
            path={mdiCamera}
            size={1}
            style={{ color: "white", marginRight: "7px" }}
          />
        </label>

        <InputFormStyle
          onChange={(e: any) => {
            setValue(e.target.files[0]);
          }}
          id="input"
          fontSize="14px"
          width="350px"
          type="file"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      {currentImageUrl && (
        <DivStyle marginTop="10px">
          <img
            src={currentImageUrl}
            alt="Current thumbnail"
            style={{ maxWidth: "200px", maxHeight: "200px" }}
          />
        </DivStyle>
      )}
    </DivStyle>
  );
};

export default FormFileInput;
