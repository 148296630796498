import styled from "styled-components";
import Props from "./interface/props";

const ButtonStyle = styled.button<Props>`
  font-weight: ${({ fontWeight }) => fontWeight || "normal"}
  font-size: ${({ fontSize }) => fontSize || "14px"};
  border: ${({ border }) => border || ""};
  border-radius: ${({ borderRadius }) => borderRadius || ""};
  padding: ${({ padding }) => padding || ""};
  width: ${({ width }) => width || ""};
  height: ${({ height }) => height || "50px"};
  color: ${({ color }) => color || "black"};
  background-color: ${({ backgroundColor }) => backgroundColor || ""};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
  margin-left: ${({ marginLeft }) => marginLeft || "0"};
  margin-right: ${({ marginRight }) => marginRight || "0"};
  margin-top: ${({ marginTop }) => marginTop || "0"};
  position: ${({ position }) => position || ""};
  right: ${({ right }) => right || ""};

  ${({ isSelect }) => (isSelect ? "background-color: #184DA0" : "")};
`;

export default ButtonStyle;
