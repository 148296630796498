import { apiRequest } from "../../utils/apiUtils";

export const youtubeApi = {
  getYoutubeList: (type: string) =>
    apiRequest<any>("get", "/youtube/list", {
      params: { type },
      fullResponse: true,
    }),
  getYoutubeChannelList: () =>
    apiRequest<any>("get", "/youtube-channel/list", { fullResponse: true }),
};
