import styled from "styled-components";

const SideBarWrap = styled.div`
  background: linear-gradient(to top, #2c2d32 20%, #3f4045 80%);
  height: 100%;
  width: 250px;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  overflow-y: auto;
  &.open {
    transform: translateX(0%);
  }

  @media screen and (max-width: 500px) {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: -250px &.open {
      left: 0;
    }
  }
`;

export default SideBarWrap;
