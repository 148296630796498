import { AdjustAPI } from "./adjust";
import { AdvertisementAPI } from "./advertisement";
import { AffiliateAPI } from "./affiliate";
import { AppUpdateAPI } from "./appUpdate";
import { AdminBoardAPI } from "./article";
import { AdminAttendanceAPI } from "./attendance";
import { BrandCompetitionAPI } from "./brandCompetition";
import { CafeAPI } from "./cafe";
import { CommentAPI } from "./comment";
import { CompetitionAPI } from "./competition";
import { CouponAPI } from "./coupon";
import { EventAPI } from "./event";
import { FaqAPI } from "./faq";
import { GameCouponAPI } from "./gameCoupon";
import { JobOpeningAPI } from "./jobOpening";
import { NewsAPI } from "./news";
import { NoticeAPI } from "./notice";
import { OpenChatAPI } from "./openChat";
import { PopupNoticeAPI } from "./popUpNotice";
import { PubGradeApi } from "./pub-grade";
import { PubMessageAPI } from "./pubMessage";
import { ReviewAPI } from "./review";
import { RouletteAPI } from "./roulette";
import { SearchAPI } from "./search";
import { SecondHandMarketAPI } from "./secondHandMarket";
import { TournamentAPI } from "./tournament";
import { UserAPI } from "./user";
import { UserMessageAPI } from "./userMessage";
import { YoutubeAPI } from "./youtube";

const AdminAPI = {
  ...CompetitionAPI,
  ...RouletteAPI,
  ...BrandCompetitionAPI,
  ...CafeAPI,
  ...FaqAPI,
  ...JobOpeningAPI,
  ...PopupNoticeAPI,
  ...TournamentAPI,
  ...UserAPI,
  ...SecondHandMarketAPI,
  ...EventAPI,
  ...AffiliateAPI,
  ...AdvertisementAPI,
  ...YoutubeAPI,
  ...OpenChatAPI,
  ...NoticeAPI,
  ...NewsAPI,
  ...CommentAPI,
  ...AdjustAPI,
  ...AppUpdateAPI,
  ...SearchAPI,
  ...ReviewAPI,
  ...UserMessageAPI,
  ...PubMessageAPI,
  ...AdminBoardAPI,
  ...CouponAPI,
  ...GameCouponAPI,
  ...AdminAttendanceAPI,
  ...PubGradeApi,
};
export default AdminAPI;
