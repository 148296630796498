import DivStyle from "./style/div.component";
import TabStyle from "./style/tab.component";
interface ICategory {
  tabs: ITab[];
  selectTab: number;
  setSelectTab: (value: number) => void;
}
interface ITab {
  text: string;
  value: number;
}
const Category = (props: ICategory) => {
  const { tabs, selectTab, setSelectTab } = props;
  return (
    <TabStyle
      backgroundColor="#272727"
      width="95%"
      height="60px"
      display="flex"
      flex_direction="row"
      marginTop="20px"
      setMobile={true}
      justify_content="center"
    >
      {tabs.map((item: ITab, index: number) => (
        <DivStyle
          className={item.value === selectTab ? "select" : "tab"}
          style={{ textAlign: "center", userSelect: "none" }}
          height="96%"
          display="flex"
          align_items="center"
          color="#FFFFFF99"
          marginLeft="10px"
          marginRight="10px"
          onClick={() => setSelectTab(item.value)}
        >
          {item.text}
        </DivStyle>
      ))}
    </TabStyle>
  );
};

export default Category;
