import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import { AdjustResponse } from "./interface";
import AdminAPI from "../../api/admin";
import ButtonStyle from "../../components/style/button.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";

interface DashBoard {
  date: string;
  SignUpCount: number;
  RecommendCount: number;
}
interface DailyDashboard {
  trackerName: string;
  count: number;
}
const AdjustDashBoard = () => {
  const [adjustList, setAdjustList] = useState<AdjustResponse[]>([]);
  const [adjustDashBoard, setAdjustDashBoard] = useState<DashBoard[]>([]);
  const [dailyDashboard, setDailyDashBoard] = useState<DailyDashboard[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const headers = [
    {
      text: "날짜",
      align: "center",
      sortable: false,
      value: "date",
    },
    {
      text: "회원가입 수",
      align: "center",
      sortable: false,
      value: "SignUpCount",
    },
    {
      text: "추천 수",
      align: "center",
      sortable: false,
      value: "RecommendCount",
    },
  ];
  const headers2 = [
    {
      text: "trackerName",
      align: "center",
      sortable: false,
      value: "trackerName",
    },
    {
      text: "Count",
      align: "center",
      sortable: false,
      value: "count",
    },
  ];
  const setDetail = (item: DashBoard) => {
    const filteredData = adjustList.filter(
      (event) =>
        event.createdAt.split(" ")[0] === item.date &&
        (event.eventName === "Signup" ||
          event.eventName === "Register_recommend")
    );
    const trackerNameCount = filteredData.reduce(
      (acc: { [key: string]: number }, event) => {
        const { trackerName } = event;
        if (acc[trackerName]) {
          acc[trackerName] += 1;
        } else {
          acc[trackerName] = 1;
        }
        return acc;
      },
      {}
    );

    const dailyData = Object.keys(trackerNameCount).map((trackerName) => ({
      trackerName,
      count: trackerNameCount[trackerName],
    }));
    console.log(dailyData);
    setDailyDashBoard(dailyData);
  };
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getDashBoard = async () => {
    const result = await AdminAPI.getAdjustDashBoard();
    if (result) {
      const updatedData = result.data.map((item: AdjustResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const SignUpByDate = updatedData
        .filter(
          (event: any) =>
            event.eventName === "Signup" ||
            event.eventName === "Register_recommend"
        )
        .reduce((acc: any, event: any) => {
          const date = event.createdAt.split(" ")[0];
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});
      const RecommendByDate = updatedData
        .filter((event: any) => event.eventName === "Register_recommend")
        .reduce((acc: any, event: any) => {
          const date = event.createdAt.split(" ")[0];
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});
      const combinedData = Object.keys(SignUpByDate)
        .map((date) => ({
          date,
          SignUpCount: SignUpByDate[date],
          RecommendCount: RecommendByDate[date] || 0,
        }))
        .sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      setAdjustDashBoard(combinedData);
      const totalPages = Math.ceil(combinedData.length / 10);
      setAdjustList(updatedData);
      setTotalCount(combinedData.length);
      setTotalPages(totalPages);
    }
  };
  useEffect(() => {
    getDashBoard();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="50%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          Adjust 대시보드
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={adjustDashBoard}
            setDetail={setDetail}
          />
          <Page
            count={totalCount}
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={adjustDashBoard}
            setDataList={setAdjustDashBoard}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="50%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          Tracker
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <Table
            headers={headers2}
            currentPage={1}
            dataList={dailyDashboard}
            size={50}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default AdjustDashBoard;
