import { useRecoilValue } from "recoil";
import { sideState } from "../recoil/side.atom";
import SideBarWrap from "./style/side.component";
import { IMenuItem, MENU } from "../interface/menu.interface";
import MenuItem from "./Menu/MenuItem";

const MenuSidebar = () => {
  const isOpen = useRecoilValue(sideState);

  return (
    <SideBarWrap className={isOpen ? "open" : ""}>
      <h5
        style={{
          width: "100%",
          color: "#4fbc60",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        RunnerRunner Admin
      </h5>

      {MENU.map((menu: IMenuItem, index: number) => (
        <MenuItem key={index} menu={menu}></MenuItem>
      ))}
    </SideBarWrap>
  );
};

export default MenuSidebar;
