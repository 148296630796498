import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiCamera } from "@mdi/js";
import { InputFormProps } from "./interface/formInterface";

const FormFilesVideoInput = (props: InputFormProps) => {
  const { setValue, width } = props;
  return (
    <div
      style={{
        width: width,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <label htmlFor="input">
        <Icon
          path={mdiCamera}
          size={1}
          style={{ color: "white", marginRight: "7px" }}
        />
      </label>

      <InputFormStyle
        onChange={(e: any) => {
          setValue(e.target.files[0]);
        }}
        id="input"
        fontSize="14px"
        width="500px"
        type="file"
        accept=".mp4"
        multiple
      />
    </div>
  );
};

export default FormFilesVideoInput;
