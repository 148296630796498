// PrivateRoute.js
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import API from "../api/api";
import { useRecoilState } from "recoil";
import { loginState } from "../recoil/auth.atom";
import { UserState } from "../recoil/user.atom";

const PrivateRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [user, setUser] = useRecoilState(UserState);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const checkInfo = async () => {
        try {
          const res = await API.getInfo();
          if (res.status === 200 && res.data.role === "ROLE_ADMIN") {
            setUser(res.data);
            setIsLoggedIn(true);
          } else {
            setIsChecking(false);
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            alert("관리자가 아닙니다.");
          }
        } catch (error) {
          setIsChecking(false);
          localStorage.removeItem("token");
          alert("로그인이 만료되었습니다.");
        }
        setIsChecking(false);
      };
      checkInfo();
    } else {
      setIsChecking(false);
    }
  }, [isLoggedIn]);

  if (isChecking) {
    return <div>Loading...</div>;
  }
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
