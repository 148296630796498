import axios from "axios";

const GAME_URL = process.env.REACT_APP_GAME_API_URL ? process.env.REACT_APP_GAME_API_URL : "https://gameapi.runnerrunner.co.kr"

const GAMEAPI = {
  getTournamentList() {
    const token = localStorage.getItem("token");
    return axios
      .get(GAME_URL + "/api/get_tournament_list", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => res);
  },
  getJoinSetting() {
    const token = localStorage.getItem("token");
    return axios
      .get(GAME_URL + "/api/get_join_setting", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res);
  },
  saveGameSetting(body: any) {
    const token = localStorage.getItem("token");
    return axios
      .post(GAME_URL + "/api/set_join_setting", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res);
  },
  addPointRebuyin(body: any) {
    const token = localStorage.getItem("token");
    return axios
      .post(GAME_URL + "/api/add_point_rebuyin", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res);
  },
  getPointAndRebuyIn(id: number) {
    return axios
      .get(GAME_URL + `/api/get_user_ticket_info?userId=${id}`)
      .then((res) => res.data.user);
  },
};
export default GAMEAPI;
