import styled from "styled-components";
import Props from "./interface/props";

const DivStyle = styled.div<Props>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "rgba(255, 255, 255, 0)"};
  border-radius: ${({ borderRadius }) => borderRadius || ""};
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
  margin-left: ${({ marginLeft }) => marginLeft || ""};
  margin-right: ${({ marginRight }) => marginRight || ""};
  margin-top: ${({ marginTop }) => marginTop || ""};
  display: ${({ display }) => display || "flex"};
  flex-direction: ${({ flex_direction }) => flex_direction || ""};
  justify-content: ${({ justify_content }) => justify_content || ""};
  align-items: ${({ align_items }) => align_items || ""};
  flex-wrap: ${({ flex_wrap }) => flex_wrap || ""}; // 추가
  gap: ${({ gap }) => gap || ""}; // 추가
  width: ${({ width }) => width || ""};
  height: ${({ height }) => height || ""};
  color: ${({ color }) => color || ""};
  padding: ${({ padding }) => padding || ""};
  font-size: ${({ fontSize }) => fontSize || ""};
  position: ${({ position }) => position || ""};
  .tab {
    &:hover {
      background-color: #27272750;
    }
    @media screen and (max-width: 700px) {
      height: 50px;
      justify-content: center;
      width: 90%;
    }
  }
  .select {
    color: #1976d2;
    border-bottom: 3px solid #1976d2;
    &:hover {
      background-color: #1976d250;
    }
    @media screen and (max-width: 700px) {
      height: 50px;
      justify-content: center;
      width: 90%;
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: ${({ setMobile }) => (setMobile ? "column" : "")};
    width: ${({ setMobileTable }) => (setMobileTable ? "100%" : "")};
    width: ${({ setMobileForm }) => (setMobileForm ? "100%" : "")};
    margin-left: ${({ setMobileForm }) => (setMobileForm ? "0px" : "")};
    align-items: ${({ setMobileTable }) => (setMobileTable ? "center" : "")};
    align-items: ${({ setMobileForm }) => (setMobileForm ? "center" : "")};
  }
`;

export default DivStyle;
