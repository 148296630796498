import { apiRequest } from "../../utils/apiUtils";

export const areaApi = {
  getAreaCities: (id: number) =>
    apiRequest<any>("get", "/area/cities", {
      params: { id },
      fullResponse: true,
    }),
  getAreaProvince: () =>
    apiRequest<any>("get", "/area/provinces", { fullResponse: true }),
  getAreaStreets: (id: number) =>
    apiRequest<any>("get", "/area/streets", {
      params: { id },
      fullResponse: true,
    }),
};
