import { InputFormProps } from "./interface/formInterface";

const FormImage = (props: InputFormProps) => {
  const { value, width } = props;
  return value ? (
    <img src={value} style={{ width: width }} alt="이미지"></img>
  ) : null;
};

export default FormImage;
