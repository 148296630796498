import { adminApiRequest } from "../../utils/apiUtils";

export const ReviewAPI = {
  getPubReviews: () => {
    return adminApiRequest.get("/cafe/review").then((res) => res);
  },
  updateReview: (formData: FormData, id: number) => {
    return adminApiRequest
      .put(`/cafe/review/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
};
