import { apiRequest } from "../../utils/apiUtils";
import { DateRangeParams } from "../../types";

export const tournamentApi = {
  getTournament: () => apiRequest<any>("get", "/tournament/list"),
  getTournamentRankers: (startDate: string, endDate: string) =>
    apiRequest<any>("get", "/tournament/ranking/allList", {
      params: { startDate, endDate },
      fullResponse: true,
    }),
};
