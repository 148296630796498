import { UserJobOpeningResponse } from "../../pages/userRecruiting/interface";
import { adminApiRequest } from "../../utils/apiUtils";

export interface UserJobReportResponse {
  id: number;
  job: UserJobOpeningResponse;
  complete: boolean;
  delete: boolean;
  reportNickname: string;
  type: string;
  description: string;
  createdAt: string;
}

export const JobOpeningAPI = {
  getUserJobOpening: () => {
    return adminApiRequest
      .get("/job-opening/user/list")
      .then((res) => res.data);
  },
  registerUserJobOpening: (body: any) => {
    return adminApiRequest
      .post("/job-opening/user/register", body)
      .then((res) => res);
  },
  updateUserJobOpening: (body: any) => {
    return adminApiRequest
      .put("/job-opening/user/update", body)
      .then((res) => res);
  },
  deleteUserJobOpening: (id: number) => {
    return adminApiRequest
      .delete("/job-opening/user/delete", {
        params: { id },
      })
      .then((res) => res);
  },
  getJobReport: () => {
    return adminApiRequest
      .get("/job-opening/user/report")
      .then((res) => res.data);
  },
  removeUserJobReport: (id: number) => {
    return adminApiRequest
      .delete(`/job-opening/user/report/${id}`)
      .then((res) => res);
  },
  acceptUserJobReport: (id: number) => {
    return adminApiRequest
      .put(`/job-opening/user/report/${id}`)
      .then((res) => res);
  },
};
