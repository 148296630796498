import { adminApiRequest } from "../../utils/apiUtils";

export const NewsAPI = {
  getNews: () => {
    return adminApiRequest.get("/news").then((res) => res.data);
  },

  getNewsCategory: () => {
    return adminApiRequest.get("/news-category").then((res) => res.data);
  },

  updateNews: (formData: FormData, id: number) => {
    return adminApiRequest
      .put(`/news/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data);
  },

  uploadNewsImage: (formData: FormData) => {
    return adminApiRequest
      .post("/news/upload-image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  registerNews: (formData: FormData) => {
    return adminApiRequest
      .post("/news", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data);
  },

  deleteNews: (id: number) => {
    return adminApiRequest.delete(`/news/${id}`).then((res) => res.data);
  },

  registerNewsCategory: (body: any) => {
    return adminApiRequest.post("/news-category", body).then((res) => res.data);
  },

  deleteNewsCategory: (id: number) => {
    return adminApiRequest
      .delete(`/news-category/${id}`)
      .then((res) => res.data);
  },

  updateNewsCategory: (body: any, id: number) => {
    return adminApiRequest
      .put(`/news-category/${id}`, body)
      .then((res) => res.data);
  },
};
