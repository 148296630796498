import { InputFormProps } from "./interface/formInterface";
import SelectFormStyle from "../style/select.component";
import API from "../../api/api";
import { useEffect, useState } from "react";

const AreaForm = (props: InputFormProps) => {
  const { value, label, width, setValue, options } = props;
  const {
    value1,
    value2,
    value3,
    setValue2,
    setValue3,
    label2,
    label3,
    options2,
    options3,
    setOptions2,
    setOptions3,
  } = value;

  const [prob, setProb] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");

  const getCities = async (item: any, select?: boolean) => {
    const res = await API.getAreaCities(item.id);
    if (res && res.status === 200) {
      console.log(item);
      setOptions2(res.data);
      if (value2 > 0 && select) {
        const item: any = res.data?.find((option: any) => option.id === value2);

        setCity(item.name);
      }
      if (value3 > 0 && select) {
        const item: any = res.data?.find((option: any) => option.id === value2);
        getStreet(item);
      }
    }
    if (item.id === 8) {
      const res_street = await API.getAreaStreets(res?.data[0].id);
      if (res_street && res_street.status === 200) {
        setOptions3(res_street.data);
      }
    }
  };
  const getStreet = async (item: any, select?: boolean) => {
    const res = await API.getAreaStreets(item.id);
    if (res && res.status === 200) {
      setOptions3(res.data);
      if (value3 > 0) {
        const item: any = res.data?.find((option: any) => option.id === value3);
        setStreet(item.name);
      }
    }
  };

  useEffect(() => {
    if (value2 > 0) {
      const item: any = options?.find((option: any) => option.id === value1);
      setProb(item.name);
      getCities(item, true);
    }
    if (value1 === -1) {
      setProb("");
      setCity("");
      setStreet("");
    }
  }, [value1]);
  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SelectFormStyle
          value={prob}
          name="select"
          width="100%"
          onChange={(e) => {
            const item: any = options?.find(
              (option: any) => option.name === e.target.value
            );
            if (item) {
              setProb(item.name);
              setValue(item.id);
              getCities(item);
              setValue2(0);
              setValue3(0);
              setOptions2([]);
              setOptions3([]);
            }
          }}
        >
          <option value="" disabled selected hidden>
            {label}
          </option>
          {options?.map((item: any, index: number) => (
            <option value={item.name} key={index}>
              {item.name}
            </option>
          ))}
        </SelectFormStyle>
      </div>
      <div
        style={{
          width: "33%",
          marginLeft: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SelectFormStyle
          value={city}
          aria-placeholder={label2}
          name="select"
          width="100%"
          onChange={(e) => {
            const item: any = options2?.find(
              (option: any) => option.name === e.target.value
            );
            if (item) {
              setCity(item.name);
              setValue2(item.id);
              getStreet(item);
              setValue3(0);
              setOptions3([]);
            }
          }}
        >
          <option value="" disabled selected hidden>
            {label2}
          </option>
          {options2?.map((item: any, index: number) => (
            <option value={item.name} key={index}>
              {item.name}
            </option>
          ))}
        </SelectFormStyle>
      </div>

      <div
        style={{
          width: "33%",
          marginLeft: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SelectFormStyle
          value={street}
          name="select"
          width="100%"
          onChange={(e) => {
            const item: any = options3?.find(
              (option: any) => option.name === e.target.value
            );
            if (item) {
              setStreet(item.name);
              setValue3(item.id);
            }
          }}
        >
          <option value="" disabled selected hidden>
            {label3}
          </option>
          {options3?.map((item: any, index: number) => (
            <option value={item.name} key={index}>
              {item.name}
            </option>
          ))}
        </SelectFormStyle>
      </div>
    </div>
  );
};

export default AreaForm;
