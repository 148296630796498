import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import {
  CompetitionRegion,
  ICompetition,
  ICompetitionEvent,
} from "./interface/interface";
import ButtonStyle from "../../components/style/button.component";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import SmallForm from "../../components/form/small_form";
import PosterManager from "./posterManager";

const BrandCompetition: React.FC = () => {
  const [brandCompetitionList, setBrandCompetitionList] = useState<
    ICompetition[]
  >([]);
  const [searchBrandCompetitionList, setSearchBrandCompetitionList] = useState<
    ICompetition[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const setIsLoading = useSetRecoilState(loadingState);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [startAt, setStartAt] = useState("");
  const [competitionType, setCompetitionType] = useState("");
  const [endAt, setEndAt] = useState("");
  const [posterFiles, setPosterFiles] = useState<File[]>([]);
  const [events, setEvents] = useState<ICompetitionEvent[]>([]);
  const [location, setLocation] = useState("");
  const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState<string>("");
  const [currentPosterUrls, setCurrentPosterUrls] = useState<string[]>([]);
  const [region, setRegion] = useState<CompetitionRegion>(
    CompetitionRegion.SEOUL
  );
  const [currentEventForm, setCurrentEventForm] = useState<ICompetitionEvent>({
    name: "",
    buyIn: "0",
    registrationEndLevelString: "0",
    durations: "0",
    startingStack: 0,
    date: "",
  });

  const handleBasicFormAdd = async () => {
    await handleAdd();
  };

  const handleBasicFormSave = async () => {
    await handleSave();
  };

  const handleBasicFormRemove = async () => {
    await handleRemove();
  };

  const handleEventFormAdd = () => {
    addEvent();
  };

  const competitionFormData: FormProps = {
    title: "대회 정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "대회명",
        width: "100%",
        type: "input",
      },
      {
        value: competitionType,
        setValue: setCompetitionType,
        label: "대회종류",
        width: "100%",
        type: "select",
        options: [
          {
            name: "국제대회",
            value: "INTERNATIONAL",
          },
          {
            name: "국내대회",
            value: "DOMESTIC",
          },
          {
            name: "펍 후원 대회",
            value: "PUB",
          },
        ],
      },
      {
        value: region,
        setValue: setRegion,
        label: "지역",
        width: "100%",
        type: "select",
        options: [
          { name: "서울", value: CompetitionRegion.SEOUL },
          { name: "경기", value: CompetitionRegion.GYEONGGI },
          { name: "인천", value: CompetitionRegion.INCHEON },
          { name: "강원", value: CompetitionRegion.GANGWON },
          { name: "전라", value: CompetitionRegion.JUNRA },
          { name: "경남/부산", value: CompetitionRegion.GYUNGNAM_BUSAN },
          { name: "경북/대구", value: CompetitionRegion.GYUNGBUK_DAEGU },
          { name: "충청", value: CompetitionRegion.CHUNGCHEONG },
          { name: "대전/세종", value: CompetitionRegion.DAEJEON_SEJONG },
          { name: "제주", value: CompetitionRegion.JEJU },
          { name: "아시아", value: CompetitionRegion.ASIA },
          { name: "아메리카", value: CompetitionRegion.AMERICA },
          { name: "유렵", value: CompetitionRegion.EUROPE },
        ],
      },
      {
        value: location,
        setValue: setLocation,
        label: "대회 장소",
        width: "50%",
        type: "input",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "썸네일 이미지",
        width: "100%",
        type: "file",
        currentImageUrl: currentThumbnailUrl, // 이 부분 추가
      },
      {
        value: posterFiles,
        setValue: setPosterFiles,
        label: "포스터 이미지들",
        width: "100%",
        type: "files",
        multiple: true,
        currentImageUrls: currentPosterUrls, // 이 부분 추가
      },
      {
        value: startAt,
        setValue: setStartAt,
        label: "시작일시",
        width: "100%",
        type: "date",
      },
      {
        value: endAt,
        setValue: setEndAt,
        label: "종료일시",
        width: "100%",
        type: "date",
      },
    ],
    add: handleBasicFormAdd,
    save: handleBasicFormSave,
    remove: handleBasicFormRemove,
    mode: mode,
  };

  const eventFormData: FormProps = {
    title: "이벤트 정보",
    form: [
      {
        value: currentEventForm.name,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({ ...prev, name: v })),
        label: "이벤트명",
        width: "100%",
        type: "input",
      },
      {
        value: currentEventForm.buyIn,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({ ...prev, buyIn: v })),
        label: "바이인",
        width: "100%",
        type: "input",
      },
      {
        value:
          currentEventForm.startingStack === 0
            ? ""
            : currentEventForm.startingStack,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({
            ...prev,
            startingStack: Number(v),
          })),
        label: "시작스택",
        width: "100%",
        type: "input",
      },
      {
        value: currentEventForm.durations,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({ ...prev, durations: v })),
        label: "듀레이션",
        width: "100%",
        type: "input",
      },
      {
        value: currentEventForm.registrationEndLevelString,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({
            ...prev,
            registrationEndLevelString: v,
          })),
        label: "레지마감레벨",
        width: "100%",
        type: "input",
      },
      {
        value: currentEventForm.date,
        setValue: (v: string) =>
          setCurrentEventForm((prev) => ({ ...prev, date: v })),
        label: "이벤트 일시",
        width: "100%",
        type: "date",
      },
    ],
    add: handleEventFormAdd,
    mode: "EVENT",
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "대회명",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "대회종류",
      align: "center",
      sortable: false,
      value: "type",
    },
    {
      text: "지역",
      align: "center",
      sortable: false,
      value: "region",
    },
    {
      text: "대회 장소",
      align: "center",
      sortable: false,
      value: "location",
    },
    {
      text: "시작일시",
      align: "center",
      sortable: false,
      value: "startDate",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "endDate",
    },
  ];

  const formatDate = (dateString: string): string => {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const getList = async () => {
    try {
      const res = await AdminAPI.getCompetitions();
      if (res && res.status === 200) {
        const updatedData = res.data.items.map((item: ICompetition) => ({
          ...item,
          startDate: formatDate(item.startDate),
          endDate: formatDate(item.endDate),
        }));
        const totalPages = Math.ceil(updatedData.length / 10);
        setTotalPages(totalPages);
        setBrandCompetitionList(updatedData);
        setSearchBrandCompetitionList(updatedData);
      }
    } catch (error) {
      console.error("Failed to fetch competitions:", error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleAdd = async () => {
    if (!title) {
      alert("대회명을 입력해주세요.");
      return;
    }
    if (!competitionType) {
      alert("대회 종류를 선택해주세요.");
      return;
    }
    if (!region) {
      alert("지역을 선택해주세요.");
      return;
    }
    if (!selectedFile) {
      alert("썸네일 이미지를 선택해주세요.");
      return;
    }

    const competitionData = {
      title,
      type: competitionType,
      region,
      startDate: startAt,
      endDate: endAt,
      location,
      competitionEvents: events.map((event) => ({
        ...event,
        date: new Date(event.date).toISOString(),
      })),
    };

    const formData = new FormData();

    // 파일 데이터 추가
    formData.append("thumbnail", selectedFile);

    if (posterFiles) {
      Array.from(posterFiles).forEach((file) => {
        formData.append("posters", file);
      });
    }

    // JSON 데이터를 문자열로 변환하여 추가
    formData.append("data", JSON.stringify(competitionData));

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerCompetition(formData);
        if (res?.status === 200 || res?.status === 201) {
          alert("신규 등록 되었습니다.");
          await getList();
          addForm();
        }
      } catch (error) {
        console.error("Registration failed:", error);

        // 에러 타입 가드 추가
        if (error instanceof AxiosError) {
          if (error.response) {
            console.error("Error details:", error.response.data);
            alert(error.response.data.message || "등록에 실패했습니다.");
          } else {
            alert("서버 연결에 실패했습니다.");
          }
        } else {
          alert("등록에 실패했습니다.");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const addEvent = async () => {
    if (!currentEventForm.name) {
      alert("이벤트명을 입력해주세요.");
      return;
    }
    if (!currentEventForm.date) {
      alert("이벤트 일시를 입력해주세요.");
      return;
    }
    const request = {
      ...currentEventForm,
      competitionTournamentId: id,
    };
    await AdminAPI.registerCompetitionEvent(request);
    setEvents([...events, currentEventForm]);
    setCurrentEventForm({
      name: "",
      buyIn: "0",
      registrationEndLevelString: "0",
      durations: "0",
      startingStack: 0,
      date: "",
    });
  };

  const removeEvent = (index: number) => {
    const newEvents = [...events];
    newEvents.splice(index, 1);
    setEvents(newEvents);
  };

  const addForm = () => {
    setMode("I");
    setId(0);
    setTitle("");
    setStartAt("");
    setEndAt("");
    setCompetitionType("");
    setSelectedFile(null);
    setPosterFiles([]);
    setLocation("");
    setRegion(CompetitionRegion.SEOUL);
    setEvents([]);
    setCurrentEventForm({
      name: "",
      buyIn: "0",
      registrationEndLevelString: "0",
      durations: "0",
      startingStack: 0,
      date: "",
    });
    setCurrentThumbnailUrl("");
    setCurrentPosterUrls([]);
  };

  const setDetail = async (item: ICompetition) => {
    try {
      setIsLoading(true);
      // 상세 정보 조회
      const res = await AdminAPI.getCompetition(item.id);
      if (res?.status === 200) {
        const detailData = res.data;

        setMode("U");
        setId(detailData.id);
        setTitle(detailData.title);
        setLocation(detailData.location);
        setStartAt(formatDate(detailData.startDate));
        setEndAt(formatDate(detailData.endDate));
        setCompetitionType(detailData.type);
        setRegion(detailData.region);
        setEvents(detailData.events || []);
        setCurrentThumbnailUrl(detailData.thumbnail || "");
        setCurrentPosterUrls(detailData.posters || []);
      }
    } catch (error) {
      console.error("Failed to fetch detail:", error);
      if (error instanceof AxiosError) {
        if (error.response) {
          alert(
            error.response.data.message || "상세 정보 조회에 실패했습니다."
          );
        } else {
          alert("서버 연결에 실패했습니다.");
        }
      } else {
        alert("상세 정보 조회에 실패했습니다.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (id > 0) {
      const competitionData = {
        title,
        type: competitionType,
        region,
        startDate: startAt,
        endDate: endAt,
        location: location,
        posters: currentPosterUrls, // 현재 포스터 URL 순서 전달
        events: events.map((event) => ({
          ...event,
          date: new Date(event.date).toISOString(),
        })),
      };

      const formData = new FormData();
      formData.append("data", JSON.stringify(competitionData));

      if (selectedFile) {
        formData.append("thumbnail", selectedFile);
      }

      if (posterFiles) {
        Array.from(posterFiles).forEach((file) => {
          formData.append("posters", file);
        });
      }
      if (window.confirm("수정 하시겠습니까?")) {
        setIsLoading(true);
        try {
          const res = await AdminAPI.updateCompetition(id, formData);
          if (res?.status === 200) {
            alert("수정되었습니다.");
            await getList();
            addForm();
          }
        } catch (error) {
          // ... 에러 처리
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleRemove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        try {
          const res = await AdminAPI.deleteCompetition(id);
          if (res?.status === 200) {
            alert("삭제되었습니다.");
            await getList();
            addForm();
          }
        } catch (error) {
          alert("삭제에 실패했습니다.");
          console.error("Delete failed:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchBrandCompetitionList(brandCompetitionList);
      const totalPages = Math.ceil(brandCompetitionList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = brandCompetitionList.filter((item: ICompetition) => {
      return Object.values(item).some((value) => {
        if (value) {
          return value.toString().toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });

    setSearchBrandCompetitionList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      {/* 왼쪽 영역: 대회 목록 */}
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          대회 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle onChange={(e) => handleSearch(e.target.value)} />
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchBrandCompetitionList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchBrandCompetitionList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={addForm}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>

      {/* 오른쪽 영역: 입력 폼 */}
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          대회 정보
        </h2>

        {/* 기본 정보 폼 */}
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="30px"
        >
          <SmallForm {...competitionFormData} />
        </DivStyle>

        <DivStyle width="95%">
          {/* 등록된 이벤트 목록 */}
          <DivStyle
            width="60%"
            backgroundColor="#1E1E1E"
            borderRadius="10px"
            padding="20px"
            marginBottom="30px"
            flex_direction="column"
          >
            <h3
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                margin: "0 0 20px 0",
              }}
            >
              등록된 이벤트
            </h3>
            {events.length > 0 ? (
              events.map((event, index) => (
                <DivStyle
                  key={index}
                  backgroundColor="#2A2A2A"
                  padding="15px"
                  marginBottom="10px"
                  borderRadius="5px"
                >
                  <DivStyle
                    display="flex"
                    justify_content="space-between"
                    align_items="flex-end"
                  >
                    <div style={{ color: "white" }}>
                      <div>이벤트명: {event.name}</div>
                      <div>바이인: {event.buyIn}</div>
                      <div>
                        시작스택:{" "}
                        {event.startingStack === 0 ? "-" : event.startingStack}
                      </div>
                      <div>일시: {formatDate(event.date)}</div>
                    </div>
                    <ButtonStyle
                      onClick={() => removeEvent(index)}
                      backgroundColor="#DD2C00"
                      color="white"
                      width="60px"
                      height="30px"
                      borderRadius="5px"
                    >
                      삭제
                    </ButtonStyle>
                  </DivStyle>
                </DivStyle>
              ))
            ) : (
              <h5
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  margin: "0 0 0 20px",
                }}
              >
                등록된 이벤트 없음
              </h5>
            )}
          </DivStyle>
          {/* 이벤트 폼 */}
          <DivStyle
            width="95%"
            display="flex"
            backgroundColor="#1E1E1E"
            flex_direction="column"
            borderRadius="10px"
            marginBottom="30px"
          >
            <SmallForm {...eventFormData} />
          </DivStyle>
        </DivStyle>
        {currentPosterUrls.length > 0 && (
          <PosterManager
            posters={currentPosterUrls}
            posterFiles={posterFiles}
            setPosterFiles={setPosterFiles}
            currentPosterUrls={currentPosterUrls}
            setCurrentPosterUrls={setCurrentPosterUrls}
          />
        )}
      </DivStyle>
    </DivStyle>
  );
};

export default BrandCompetition;
