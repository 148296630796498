import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";

const FormInputDash = (props: InputFormProps) => {
  const { value, label, width, height, fontSize } = props;

  return (
    <div style={{ width: width, marginTop: "10px" }}>
      {value !== "" ? (
        <label style={{ color: "#7C7C7C", fontSize: "12px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
      )}
      <InputFormStyle
        color="#7C7C7C"
        fontSize={fontSize}
        border="1px dashed white"
        height={height}
        width="100%"
        value={value}
        placeholder={label}
        disabled
      ></InputFormStyle>
    </div>
  );
};

export default FormInputDash;
