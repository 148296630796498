import DivStyle from "../style/div.component";
import styled from "styled-components";
import { InputFormProps } from "./interface/formInterface";
import ButtonStyle from "../style/button.component";
const Gallery = styled(DivStyle)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const DeleteButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
`;
const ImageWrapper = styled.div`
  flex: 1 0 25%; // 25% width for each image to fit 4 in a row
  max-width: 25%; // Ensure it does not grow beyond 25%
  box-sizing: border-box;
  padding: 5px;
  position: relative; // For absolute positioning of the delete button
`;
const FormImages = (props: InputFormProps) => {
  const { value, setValue, width } = props;
  const { value1, setDeleteImage } = value;
  const handleDelete = (index: number) => {
    const targetImage = value1[index];
    const newValue = value1.filter((_: any, i: number) => i !== index);
    setValue(newValue);

    setDeleteImage((prev: any) => [...prev, targetImage]);
  };
  return Array.isArray(value1) && value1.length > 0 ? (
    <Gallery width={width}>
      {value1.map((src: string, index: number) => (
        <ImageWrapper key={index}>
          <img
            src={src}
            style={{ width: "100%", height: "80px" }}
            alt="이미지"
          />
          <ButtonStyle
            border="none"
            backgroundColor="red"
            color="white"
            height="30px"
            borderRadius="5px"
            onClick={() => handleDelete(index)}
          >
            삭제
          </ButtonStyle>
        </ImageWrapper>
      ))}
    </Gallery>
  ) : null;
};

export default FormImages;
