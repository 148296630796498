import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";
import FormCheckInput from "./form_check";
import FormCheckDashInput from "./form_check_dash";

const EmPloyerDashForm = (props: InputFormProps) => {
  const { value, label, width } = props;
  const { value1, value2, value3, setValue2, setValue3 } = value;
  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {value1 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          fontSize="14px"
          color="#7C7C7C"
          width="100%"
          value={value1}
          placeholder={label}
          border="1px dashed white"
          disabled
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "33%",
          marginLeft: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormCheckDashInput
          value={value2}
          setValue={setValue2}
          width="100%"
          label="제휴업체"
        />
      </div>

      <FormCheckDashInput
        value={value3}
        setValue={setValue3}
        width="33%"
        label="프리미엄"
      />
    </div>
  );
};

export default EmPloyerDashForm;
