import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import { FormProps } from "../../components/form/interface/formInterface";
import SmallForm from "../../components/form/small_form";
import AdminAPI from "../../api/admin";
import API from "../../api/api";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const AppUpdate = () => {
  const mode = "S";
  const setIsLoading = useSetRecoilState(loadingState);
  const [iosVersion, setIosVersion] = useState(0);
  const [iosUpdate, setIosUpdate] = useState("");
  const [aosVersion, setAosVersion] = useState("");
  const [aosUpdate, setAosUpdate] = useState("");

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: aosVersion,
        setValue: setAosVersion,
        label: "Android:",
        type: "input",
        width: "100%",
      },
      {
        value: aosUpdate,
        setValue: setAosUpdate,
        label: "Android 강제 업데이트 여부",
        type: "check",
        width: "100%",
      },
      {
        value: iosVersion,
        setValue: setIosVersion,
        label: "IOS:",
        type: "input",
        width: "100%",
      },
      {
        value: iosUpdate,
        setValue: setIosUpdate,
        label: "IOS 강제 업데이트 여부",
        type: "check",
        width: "100%",
      },
    ],
  };

  const save = async () => {
    const aosBody = {
      id: 1,
      appId: "com.runnersoft.runnerrunner",
      os: "android",
      version: aosVersion,
      isUpdate: aosUpdate,
    };

    const iosBody = {
      id: 2,
      appId: "id6469495456",
      os: "ios",
      version: iosVersion,
      isUpdate: iosUpdate,
    };
    if (window.confirm("변경 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.updateAppUpdate(aosBody);
      if (res && res.status === 200) {
        const ios_res = await AdminAPI.updateAppUpdate(iosBody);
        if (ios_res && ios_res.status === 200) {
          alert("변경되었습니다.");
        }
      }
    }
    setIsLoading(false);
  };

  const getUpdate = async () => {
    const res = await API.getUpdate();
    if (res && res.status === 200) {
      if (res.data[0].os === "ios") {
        setIosVersion(res.data[0].version);
        setIosUpdate(res.data[0].update);
        setAosVersion(res.data[1].version);
        setAosUpdate(res.data[1].update);
      } else {
        setIosVersion(res.data[1].version);
        setIosUpdate(res.data[1].update);
        setAosVersion(res.data[0].version);
        setAosUpdate(res.data[0].update);
      }
    }
  };
  useEffect(() => {
    getUpdate();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          앱 업데이트 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default AppUpdate;
