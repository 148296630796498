import { apiRequest } from "../../utils/apiUtils";

export const marketApi = {
  getMarket: () =>
    apiRequest<any>("get", "/second-hand-market?order=4", {
      fullResponse: true,
    }),
  getSecondHandMarketNoticeList: () =>
    apiRequest<any>("get", "/second-hand-market-notice", {
      fullResponse: true,
    }),
};
