import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import API from "../../api/api";
import SmallForm from "../../components/form/small_form";
import AdTable from "../../components/table/AdTable";
import { IAdvertisement } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import AdminAPI from "../../api/admin";

const Advertisement = () => {
  const [advertisementList, setAdvertisementList] = useState<IAdvertisement[]>(
    []
  );
  const [searchAdvertisementList, setSearchAdvertisementList] = useState<
    IAdvertisement[]
  >([]);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("V");

  const [id, setId] = useState(0);
  const [adUid, setAdUid] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [showCount, setShowCount] = useState(0);
  const [link, setLink] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [description, setDescription] = useState("");
  const [ord, setOrd] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteVideo, setdeleteVideo] = useState(null);

  const [adGroup, setAdGroup] = useState("");

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: adGroup,
        setValue: setAdGroup,
        label: "광고 그룹",
        type: "select",
        width: "100%",
        options: [
          {
            value: "엠포커",
          },
        ],
      },
      {
        value: adUid,
        setValue: setAdUid,
        label: "광고 고유키",
        type: "input-dash",
        width: "100%",
      },
      {
        value: title,
        setValue: setTitle,
        label: "광고 영상명",
        type: "input",
        width: "100%",
      },
      {
        value: showCount,
        setValue: setShowCount,
        label: "재생 횟수",
        type: "input",
        width: "100%",
      },
      {
        value: url,
        setValue: setUrl,
        label: "URL",
        type: "input-dash",
        width: "100%",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "광고 영상을 첨부해주세요.",
        type: "file-video",
        width: "100%",
      },
      {
        value: url,
        setValue: setUrl,
        label: "영상",
        type: "video",
        width: "50%",
      },
      {
        value: description,
        setValue: setDescription,
        label: "광고 영상 설명",
        type: "text",
        width: "100%",
      },
      {
        value: link,
        setValue: setLink,
        label: "광고 영상 링크",
        type: "text",
        width: "100%",
      },
    ],
  };

  const headers = [
    {
      text: "순번",
      align: "center",
      sortable: false,
      value: "ord",
    },
    {
      text: "광고그룹",
      align: "center",
      sortable: false,
      value: "adGroup",
    },
    {
      text: "광고영상명",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "URL",
      align: "start",
      sortable: false,
      value: "url",
      width: "400px",
    },
    {
      text: "재생횟수",
      align: "start",
      sortable: false,
      value: "showCount",
    },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const setDetail = async (item: IAdvertisement) => {
    setMode("U");
    setId(item.id);
    setAdGroup(item.adGroup);
    setTitle(item.title);
    setAdUid(item.adUid);
    setShowCount(item.showCount);
    setUrl(item.url);
    setDescription(item.description);
    setLink(item.link);
    setOrd(item.ord);
    setIsOpen(item.open);
    setStartAt(formatDate(item.startAt));
    setEndAt(formatDate(item.endAt));

    setdeleteVideo(null);
    setSelectedFile(null);
  };
  const addForm = () => {
    setMode("I");
    setId(0);
    setAdUid("");
    setAdGroup("");
    setUrl("");
    setTitle("");
    setDescription("");
    setShowCount(0);
    setLink("");
    setOrd(0);
    setStartAt("");
    setEndAt("");
    setSelectedFile(null);
    setdeleteVideo(null);
  };
  const save = async () => {
    const body = {
      id,
      adUid,
      adGroup,
      url,
      title,
      description,
      showCount,
      link,
      ord,
      isOpen,
      startAt,
      endAt,
      selectedFile,
      deleteVideo,
    };
    let formData = null;
    if (selectedFile) {
      formData = new FormData();
      formData.append("addVideoFile", selectedFile);
      formData.append(
        "deleteVideo",
        new Blob(
          [
            JSON.stringify({
              id,
              deleteVideoUrl: url,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }

    if (formData === null) {
      if (window.confirm("저장 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.updateAdMetaData(body);
        if (res && res.status === 200) {
          alert("저장되었습니다.");
          addForm();
          getAdvertisement();
        }
      }
    } else {
      if (window.confirm("저장 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.updateAdVideo(formData);
        if (res && res.status === 200) {
          const res_meta = await AdminAPI.updateAdMetaData(body);
          if (res_meta && res_meta.status === 200) {
            alert("저장되었습니다.");
            addForm();
            getAdvertisement();
          }
        }
      }
    }
    setIsLoading(false);
  };
  function formatCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeAd(id);
      if (res && res.status === 200) {
        alert("삭제 되었습니다.");
        getAdvertisement();
        addForm();
      }
    }
    setIsLoading(false);
  };
  const add = async () => {
    if (selectedFile) {
      const body = {
        adUid: uuidv4(),
        adGroup: "MPOKER",
        title,
        url,
        ord: advertisementList.length + 1,
        description,
        showCount,
        link,
        isOpen: true,
        startAt: formatCurrentDate(),
        endAt: formatDate("2099-12-31"),
      };

      let formData = new FormData();
      formData.append("video", selectedFile);
      formData.append(
        "data",
        new Blob([JSON.stringify(body)], {
          type: "application/json",
        })
      );
      if (window.confirm("신규 등록 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.registerAd(formData);
        if (res && res.status === 200) {
          alert("신규 등록이 되었습니다.");
          getAdvertisement();
          addForm();
        }
      }
    } else {
      alert("영상을 선택해주세요.");
    }

    setIsLoading(false);
  };
  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    if (!search.trim()) {
      setSearchAdvertisementList(advertisementList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(advertisementList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = advertisementList.filter((user: IAdvertisement) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(user).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchAdvertisementList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };

  const getAdvertisement = async () => {
    const res = await API.getAdvertisements();
    if (res && res.status === 200) {
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);

      setAdvertisementList(res.data);
      setSearchAdvertisementList(res.data);
    }
  };
  useEffect(() => {
    getAdvertisement();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          광고 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => handleSearch(e.target.value)}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <AdTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchAdvertisementList}
            setDetail={setDetail}
            setDataList={setSearchAdvertisementList}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchAdvertisementList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Advertisement;
