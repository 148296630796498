import styled from "styled-components";
import Props from "./interface/props";

const InputStyle = styled.input<Props>`
  margin-bottom: ${({ margin_bottom }) => margin_bottom || "0px"};
  margin-top: ${({ margin_top }) => margin_top || "0px"};
  padding: ${({ padding }) => padding || "8px 8px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  border: ${({ border }) => border || "1px solid #ccc"};
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  color: ${({ color }) => color || ""};

  &:focus {
    border-color: ${({ focusBorderColor }) => focusBorderColor || "#007bff"};
    box-shadow: ${({ focusBoxShadow }) =>
      focusBoxShadow || "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"};
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;

export default InputStyle;
