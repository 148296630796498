import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";
import ButtonStyle from "../style/button.component";

const SearchGroupForm = (props: InputFormProps) => {
  const { value, label, width, setIsOpen, isOpen } = props;

  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: width,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        {value ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          width="100%"
          border="1px dashed white"
          value={value}
          placeholder={label}
          disabled
        ></InputFormStyle>
      </div>

      <ButtonStyle
        onClick={() => {
          setIsOpen?.(!isOpen);
        }}
        marginLeft="10px"
        width="70px"
        height="40px"
        border="none"
        backgroundColor="#1976D2"
        borderRadius="50px"
        color="white"
      >
        검색
      </ButtonStyle>
    </div>
  );
};

export default SearchGroupForm;
