import { adminApiRequest } from "../../utils/apiUtils";

export const AdvertisementAPI = {
  updateAdMetaData: (body: any) => {
    return adminApiRequest
      .put("/advertisement-video/update-metadata", body)
      .then((res) => res);
  },

  updateAdVideo: (body: any) => {
    return adminApiRequest
      .put("/advertisement-video/update-video", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  registerAd: (body: any) => {
    return adminApiRequest
      .post("/advertisement-video/register", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  removeAd: (id: number) => {
    return adminApiRequest
      .delete(`/advertisement-video/exit?id=${id}`)
      .then((res) => res);
  },

  sortAd: (body: any) => {
    return adminApiRequest
      .post("/advertisement-video/sort", body)
      .then((res) => res);
  },
};
