import { adminApiRequest } from "../../utils/apiUtils";

export const RouletteAPI = {
  getRewardRecords: () => {
    return adminApiRequest
      .get("/roulette/rewardRecords")
      .then((res) => res.data);
  },
  getRewardRecordList: () => {
    return adminApiRequest
      .get("/roulette/rewardRecords")
      .then((res) => res.data);
  },
  getRewardRecordByDate: (date: string) => {
    return adminApiRequest
      .get("/roulette/rewardRecordsByDate", {
        params: { date },
      })
      .then((res) => res.data);
  },
  getTicketByUserId: (id: number) => {
    return adminApiRequest
      .get("/roulette/tickets", {
        params: { userId: id },
      })
      .then((res) => res.data);
  },
  getTicketByDate: (date: string) => {
    return adminApiRequest
      .get("/roulette/ticketsByDate", {
        params: { date },
      })
      .then((res) => res.data);
  },
  updateRoulettePush: (userId: number, itemId: number) => {
    return adminApiRequest
      .put(`/roulette/delivery`, null, {
        params: { userId, id: itemId },
      })
      .then((res) => res.data);
  },
  cancelRoulette: (userId: number, itemId: number) => {
    return adminApiRequest
      .put(`/roulette/deliveryCancel`, null, {
        params: { userId, id: itemId },
      })
      .then((res) => res.data);
  },
};
