import { SecondHandMarketResponse } from "../../pages/secondHandMarket/interface/SecondHandMarketResponse";
import { SecondHandMarketCommentResponse } from "../../pages/secondHandMarketComment/interface";
import { adminApiRequest } from "../../utils/apiUtils";

export interface MarketCommentReportResponse {
  marketComment: SecondHandMarketCommentResponse;
  id: number;
  complete: boolean;
  delete: boolean;
  reportNickname: string;
  type: string;
  description: string;
  createdAt: string;
}
export interface MarketReportResponse {
  id: number;
  market: SecondHandMarketResponse;
  complete: boolean;
  delete: boolean;
  reportNickname: string;
  type: string;
  description: string;
  createdAt: string;
}
export const SecondHandMarketAPI = {
  registerMarket: (body: any) => {
    return adminApiRequest
      .post("/second-hand-market", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  updateMarket: (body: any, id: number) => {
    return adminApiRequest
      .put(`/second-hand-market/${id}`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  removeMarket: (id: number) => {
    return adminApiRequest
      .delete(`/second-hand-market/${id}`)
      .then((res) => res);
  },

  updateSecondHandMarketNotice: (body: any) => {
    return adminApiRequest
      .put("/second-hand-market-notice", body)
      .then((res) => res);
  },

  removeSecondHandMarketNotice: (id: number) => {
    return adminApiRequest
      .delete(`/second-hand-market-notice?id=${id}`)
      .then((res) => res);
  },

  registerSecondHandMarketNotice: (body: any) => {
    return adminApiRequest
      .post("/second-hand-market-notice", body)
      .then((res) => res);
  },
  removeMarketReport: (id: number) => {
    return adminApiRequest
      .delete(`/second-hand-market/report/${id}`)
      .then((res) => res);
  },
  acceptMarketReport: (id: number) => {
    return adminApiRequest
      .put(`/second-hand-market/report/${id}`)
      .then((res) => res);
  },
  removeMarketCommentReport: (id: number) => {
    return adminApiRequest
      .delete(`/second-hand-market-comment/report/${id}`)
      .then((res) => res);
  },
  acceptMarketCommentReport: (id: number) => {
    return adminApiRequest
      .put(`/second-hand-market-comment/report/${id}`)
      .then((res) => res);
  },
  getMarketReport: () => {
    return adminApiRequest
      .get("/second-hand-market/report")
      .then((res) => res.data);
  },
  getMarketCommentReport: () => {
    return adminApiRequest
      .get("/second-hand-market-comment/report")
      .then((res) => res.data);
  },
};
