import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import RankingPointTable from "../../components/table/RankingPointTable";
import { RankingPointResponse } from "./interface";
import AdminAPI from "../../api/admin";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const SetRankingPoint = () => {
  const [dayDataList, setDayDataList] = useState<RankingPointResponse[]>([]);
  const [nightDataList, setNightDataList] = useState<RankingPointResponse[]>(
    []
  );
  const setIsLoading = useSetRecoilState(loadingState);
  const headers = [
    {
      text: "랭킹",
      align: "center",
      sortable: false,
      value: "rank",
    },
    {
      text: "보상",
      align: "center",
      sortable: false,
      value: "point",
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "remove",
    },
  ];

  const getDayDataList = async () => {
    const result = await AdminAPI.getRankingPoint("DAY");
    setDayDataList(result);
  };

  const getNightDataList = async () => {
    const result = await AdminAPI.getRankingPoint("NIGHT");
    setNightDataList(result);
  };

  const save = async (item: RankingPointResponse) => {
    const body = {
      id: item.id,
      point: item.point,
    };
    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.updateRankingPoint(body);
      if (res) {
        if (res.status === 200) {
          alert("수정되었습니다.");
          getDayDataList();
          getNightDataList();
        }
      }
      setIsLoading(false);
    }
  };

  const add = async (item: RankingPointResponse) => {
    const body = {
      point: item.point,
      tournamentType: item.tournamentType,
      rank: item.rank,
    };
    if (window.confirm("등록 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.registerRankingPoint(body);
      if (res) {
        if (res.status === 200) {
          alert("등록 되었습니다.");
          getDayDataList();
          getNightDataList();
          setIsLoading(false);
          return true;
        }
      }
      setIsLoading(false);
    }
    return false;
  };

  const remove = async (id: number) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeRankingPoint(id);
      if (res) {
        if (res.status === 200) {
          alert("삭제되었습니다.");
          getDayDataList();
          getNightDataList();
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDayDataList();
    getNightDataList();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="50%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          낮 토너 게임포인트
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <RankingPointTable
            headers={headers}
            dataList={dayDataList}
            setDataList={setDayDataList}
            save={save}
            remove={remove}
            add={add}
            tournamentType={"DAY"}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="50%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          밤 토너 게임포인트
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <RankingPointTable
            headers={headers}
            dataList={nightDataList}
            setDataList={setNightDataList}
            save={save}
            remove={remove}
            add={add}
            tournamentType={"NIGHT"}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SetRankingPoint;
