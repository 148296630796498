import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import RouletteForm from "../../components/form/roullete_form";
import AdminAPI from "../../api/admin";
import { IRoulette } from "./interface/interface";
import { IUser } from "../user/interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
const Roulette = () => {
  const [rewardRecords, setRewardRecords] = useState<IRoulette[]>([]);
  const [searchRewardRecords, setSearchRewardRecords] = useState<IRoulette[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState<string | undefined>(undefined);
  const setIsLoading = useSetRecoilState(loadingState);
  const [userId, setUserId] = useState(0);
  const [username, setUserName] = useState("");
  const [nickname, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [gender, setGender] = useState("");
  const [accountInfo, setAccountInfo] = useState("");
  const [itemId, setItemId] = useState(0);
  const [validate, setValidate] = useState(true);
  const [validateTemp, setValidateTemp] = useState(false);

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        type: "input-dash",
        width: "100%",
      },
      {
        value: username,
        setValue: setUserName,
        label: "사용자명",
        type: "input-dash",
        width: "100%",
      },
      {
        value: nickname,
        setValue: setNickName,
        label: "닉네임",
        type: "input-dash",
        width: "100%",
      },
      {
        value: birthDay,
        setValue: setBirthDay,
        label: "생년월일",
        type: "input-dash",
        width: "100%",
      },
      {
        value: phoneNumber,
        setValue: setPhoneNumber,
        label: "핸드폰번호",
        type: "input-dash",
        width: "100%",
      },
      {
        value: gender,
        setValue: setGender,
        label: "성별",
        type: "input-dash",
        width: "100%",
      },
      {
        value: accountInfo,
        setValue: setAccountInfo,
        label: "계좌번호",
        type: "input-dash",
        width: "100%",
      },
      {
        value: validate,
        setValue: setValidateTemp,
        label: "인증여부",
        type: "check",
        width: "100%",
      },
    ],
  };

  const headers = [
    { text: "ID", value: "id" },
    { text: "userId", value: "userId" },
    { text: "보상 날짜", value: "receiveDate" },
    { text: "보상 내역", value: "productName" },
    { text: "지급 여부", value: "received" },
    { text: "지급 방법", value: "deliveryMethod" },
  ];

  const setDetail = async (item: IRoulette) => {
    setItemId(item.id);
    const res = await AdminAPI.getUserById(item.userId);
    if (res && res.status === 200) {
      if (item.received) {
        setMode("U");
      } else {
        setMode("A");
      }
      setUser(res.data);
    }
  };

  const setUser = async (item: IUser) => {
    setUserId(item.id);
    setUserName(item.username);
    setNickName(item.nickname);
    setEmail(item.textId);
    setPhoneNumber(item.phoneNumber);
    setBirthDay(item.birthday);
    setGender(item.gender);
    setAccountInfo(item.accountInfo);
    setValidate(item.validate);
  };
  const addForm = () => {
    setItemId(0);
    setMode(undefined);
    setUserId(0);
    setUserName("");
    setNickName("");
    setEmail("");
    setPhoneNumber("");
    setBirthDay("");
    setGender("");
    setAccountInfo("");
    setValidate(false);
  };
  const save = async () => {
    if (window.confirm("지급 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.updateRoulettePush(userId, itemId);
      if (res && res.status === 200) {
        alert("지급 처리 되었습니다.");
        addForm();
        getRewards();
      }
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("지급 취소 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.cancelRoulette(userId, itemId);
      if (res && res.status === 200) {
        alert("지급 취소 되었습니다.");
        addForm();
        getRewards();
      }
      setIsLoading(false);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchRewardRecords(rewardRecords);
      const totalPages = Math.ceil(rewardRecords.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = rewardRecords.filter((roulette: IRoulette) => {
      return Object.values(roulette).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchRewardRecords(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  const getRewards = async () => {
    const res = await AdminAPI.getRewardRecordList();
    if (res && res.status === 200) {
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);
      setRewardRecords(res.data);
      setSearchRewardRecords(res.data);
    }
  };
  useEffect(() => {
    getRewards();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          지급 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => handleSearch(e.target.value)}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchRewardRecords}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchRewardRecords}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          유저
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <RouletteForm
            form={formData.form}
            title={formData.title}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Roulette;
