import { IssuedGameCouponResponse } from "../../api/admin/gameCoupon";
import { InputFormProps } from "./interface/formInterface";

const CouponListForm = (props: InputFormProps) => {
  const { value, label, width } = props;
  return (
    <div style={{ width: width, marginTop: "10px" }}>
      {value !== "" ? (
        <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          background: "#000",
          color: "#fff",
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="item" style={{ flex: 1 }}>
            넘버
          </div>
          <div className="item" style={{ flex: 3 }}>
            코드
          </div>
          <div className="item" style={{ flex: 1.5 }}>
            사용 유저
          </div>
          <div className="item" style={{ flex: 1.5 }}>
            사용 시간
          </div>
        </div>
        <div
          className="list-wrapper"
          style={{ maxHeight: "300px", overflow: "auto" }}
        >
          {value.map((item: IssuedGameCouponResponse, index: number) => (
            <div
              className="item"
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="item" style={{ flex: 1 }}>
                {item.numbering}
              </div>
              <div className="item" style={{ flex: 3 }}>
                {item.code}
              </div>
              <div className="item" style={{ flex: 1.5 }}>
                {item.nickname}
              </div>
              <div className="item" style={{ flex: 1.5 }}>
                {item.usedTime}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CouponListForm;
