import { adminApiRequest } from "../../utils/apiUtils";

export const UserMessageAPI = {
  getAllUserMessage: () => {
    return adminApiRequest.get("/user/getAllMessageList").then((res) => res);
  },
  updateUserMessage: (body: any, userId: number) => {
    return adminApiRequest
      .post(`/user/messageUpdate?userId=${userId}`, body)
      .then((res) => res);
  },
  updateUserMessageWithOut: (body: any, userId: number) => {
    return adminApiRequest
      .post(`/user/messageUpdateWithOutImage?userId=${userId}`, body)
      .then((res) => res);
  },
  removeUserMessage: (id: number) => {
    return adminApiRequest
      .delete(`/user/messageDelete?messageId=${id}`)
      .then((res) => res);
  },
  getUserMessageLog: () => {
    return adminApiRequest.get("/user/messageHistoryList").then((res) => res);
  },
  getMessageHistory: () => {
    return adminApiRequest.get("/cafe/messageHistoryList").then((res) => res);
  },
};
