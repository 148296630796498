import { useState } from "react";
import TableStyle from "../style/table.component";
import { Header, TableProps } from "./interface/interface";

interface GroupProps {
  selected: string;
  setSelected: (value: any) => void;
  headers: Header[];
  dataList: any[];
}
const CheckTable = (props: GroupProps) => {
  const { headers, setSelected, selected, dataList } = props;

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value);
  };

  return (
    <TableStyle
      marginTop="30px"
      width="90%"
      style={{ height: "200px", overflowY: "auto", display: "block" }}
    >
      <thead>
        <tr>
          <th></th>
          {headers.map((header: Header, index: number) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataList.map((data: any, index: number) => (
          <tr key={index}>
            <td>
              <input
                type="radio"
                name="selectRow"
                value={index}
                onChange={handleRadioChange}
                checked={selected === String(index)}
              />
            </td>
            {headers.map((header, index: number) =>
              header.value === "data.name" ? (
                <td key={header.value}>{data["data"].name}</td>
              ) : (
                <td key={header.value}>{data[header.value]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default CheckTable;
