import { useEffect, useState } from "react";
import { FormProps } from "../form/interface/formInterface";
import SmallForm from "../form/small_form";
import DivStyle from "../style/div.component";
import GameForm from "../form/game_form";
import GAMEAPI from "../../api/game";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserState } from "../../recoil/user.atom";
import API from "../../api/api";
import { loadingState } from "../../recoil/loading.atom";
import { IJoinSetting } from "./interface/interface";
import { IUser } from "../../pages/user/interface/interface";

const FriendSetting = () => {
  const mode = "S";
  const [usePointLimit, setUsePointLimit] = useState("");
  const [gamePointReward, setgamePointReward] = useState("");
  const [rebuyinReward, setRebuyinReward] = useState("");
  const [frNickName, setfrNickName] = useState("");
  const [frUser, setFrUser] = useState<IUser | null>(null);
  const [frGameMoney, setfrGameMoney] = useState("");
  const [frRebuy, setfrRebuy] = useState("");
  const user: any | IUser = useRecoilValue(UserState);
  const setIsLoading = useSetRecoilState(loadingState);
  const search = async () => {
    const res = await API.findByNickName(frNickName);
    if (res && res.status === 200) {
      if (res.data.code === 4007) {
        alert(res.data.message);
      } else {
        if (!res.data.validate) {
          alert("본인인증하지 않은 유저입니다.");
        } else {
          if (
            window.confirm("추천하실 수 있는 사용자 입니다. 추천 하시겠습니까?")
          ) {
            setFrUser(res.data);
          }
        }
      }
    }
  };
  const save = async () => {
    const body = {
      rebuyinReward,
      gamePointReward,
      usePointLimit,
      password: "qlrnqofiwjdo1932938@#@#1",
    };
    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);
      const res = await GAMEAPI.saveGameSetting(body);
      if (res) {
        if (res.status === 200) {
          alert("수정되었습니다.");
          getJoinSetting();
        } else {
          alert("수정에 실패하였습니다.");
        }
      }
      setIsLoading(false);
    }
  };
  const send = async () => {
    if (!frUser) {
      alert("추천할 친구를 선택해주세요.");
      return;
    }
    const body = {
      userId: user.id,
      userNickname: user.nickname,
      friendId: frUser.id,
      friendNickname: frUser.nickname,
      rebuy: frRebuy,
      gameMoney: frGameMoney,
    };
    setIsLoading(true);
    const res = await API.registerFriendRecommend(body);
    if (res && res.status === 200) {
      if (res.data.code === 200) {
        const addRecommendBody = {
          token: frUser.accessToken,
          point: frGameMoney,
          rebuyinTicket: frRebuy,
          password: "qlrnqofiwjdo1932938@#@#1",
        };
        const addPointRes = await GAMEAPI.addPointRebuyin(addRecommendBody);
        if (addPointRes && addPointRes.status === 200) {
          if (addPointRes.data.result === 1) {
            alert("수동 추천에 성공 했습니다.");
          } else {
            await API.removeFriendRecommend(body);
            alert("수동 추천에 실패했습니다.");
          }
        }
      } else if (res.data.code === 6001) {
        alert(res.data.message);
      } else {
        alert("수동 추천에 실패했습니다.");
      }
    }
    setIsLoading(false);

    setfrNickName("");
    setfrRebuy("");
    setFrUser(null);
    setfrGameMoney("");
  };
  const getJoinSetting = async () => {
    const res = await GAMEAPI.getJoinSetting();
    if (res && res.status) {
      const data = res.data;
      const mgm = data.find((o: IJoinSetting) => o.name === "usePointLimit");
      const fgm = data.find((o: IJoinSetting) => o.name === "gamePointReward");
      const fr = data.find((o: IJoinSetting) => o.name === "rebuyinReward");

      if (mgm) {
        setUsePointLimit(mgm.value);
      }
      if (fgm) {
        setgamePointReward(fgm.value);
      }
      if (fr) {
        setRebuyinReward(fr.value);
      }
    }
  };
  useEffect(() => {
    getJoinSetting();
  }, []);
  const formData: FormProps = {
    title: "토너먼트 입장 설정",
    form: [
      {
        value: usePointLimit,
        setValue: setUsePointLimit,
        label: "게임 머니 사용 최대 값",
        type: "input",
        width: "100%",
      },
      {
        value: gamePointReward,
        setValue: setgamePointReward,
        label: "게임 머니",
        type: "input",
        width: "100%",
      },
      {
        value: rebuyinReward,
        setValue: setRebuyinReward,
        label: "리바인",
        type: "input",
        width: "100%",
      },
    ],
  };
  const formData2: FormProps = {
    title: "친구 보상 수동 출금",
    form: [
      {
        value: { value1: frNickName, search: search },
        setValue: setfrNickName,
        label: "닉네임",
        type: "search-form",
        width: "100%",
      },
      {
        value: frGameMoney,
        setValue: setfrGameMoney,
        label: "게임 머니",
        type: "input",
        width: "100%",
      },
      {
        value: frRebuy,
        setValue: setfrRebuy,
        label: "리바인",
        type: "input",
        width: "100%",
      },
    ],
  };
  return (
    <DivStyle
      width="100%"
      backgroundColor="#121212"
      height="auto"
      display="flex"
      flex_direction="row"
      justify_content="center"
      setMobile={true}
    >
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <GameForm
            form={formData.form}
            title={formData.title}
            save={save}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData2.form}
            title={formData2.title}
            save={send}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default FriendSetting;
