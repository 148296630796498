/* eslint-disable */
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import DivStyle from "../style/div.component";
import { PageProps } from "./interface/interface";
import { ICafe } from "../../pages/cafe/interface/interface";
import API from "../../api/api";
import AdminAPI from "../../api/admin";
import { IUser } from "../../pages/user/interface/interface";
import { IRole } from "../../pages/referralSales/interface/interface";

const UserPage = (props: PageProps) => {
  const {
    currentPage,
    totalPages,
    dataList,
    setCurrentPage,
    setDataList,
    setSearchDataList,
    count,
    isSearch,
    search,
  } = props;
  const roles: IRole[] = [
    {
      type: "ROLE_USER",
      value: "일반유저",
    },
    {
      type: "ROLE_SELLER",
      value: "판매자",
    },
    {
      type: "ROLE_ADMIN",
      value: "어드민",
    },
    {
      type: "ROLE_DEALER",
      value: "딜러",
    },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function formatBirthDay(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const RoleName = (value: string) => {
    let rtn = "";
    if (value) {
      rtn = roles.find((element: IRole) => element.type === value)?.value || "";
    }
    return rtn;
  };
  const getRegisterType = (user: IUser) => {
    if (user.appleId) return "apple";
    if (user.kakaoId) return "kakao";
    if (user.naverId) return "naver";
    if (user.googleId) return "google";
    return "normal";
  };
  const getDataForPage = async (page: number) => {
    let updatedData;
    if (isSearch && search) {
      const res = await AdminAPI.findUsers(search, page - 1);
      if (res && res.status === 200) {
        updatedData = res.data.userList.map((item: IUser) => ({
          ...item,
          role: RoleName(item.role),
          birthday: formatBirthDay(item.birthday),
          createdAt: formatDate(item.createdAt),
          type: getRegisterType(item),
        }));
      }
    } else {
      const res = await AdminAPI.getUsers(page - 1);
      if (res && res.status === 200) {
        updatedData = res.data.userList.map((item: IUser) => ({
          ...item,
          role: RoleName(item.role),
          birthday: formatBirthDay(item.birthday),
          createdAt: formatDate(item.createdAt),
          type: getRegisterType(item),
        }));
      }
    }
    if (setDataList) setDataList(updatedData);
    if (setSearchDataList) setSearchDataList(updatedData);
  };
  return (
    <DivStyle
      width="auto"
      display="flex"
      fontSize="12px"
      align_items="center"
      marginTop="10px"
      marginRight="40px"
      justify_content="flex-end"
    >
      <p style={{ color: "white" }}>Total : {count}</p>
      <p style={{ color: "white", marginLeft: "15px" }}>
        {currentPage} of {totalPages > 0 ? totalPages : 1}
      </p>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "10px",
        }}
        onClick={() => {
          getDataForPage(currentPage - 1);
          setCurrentPage(currentPage - 1);
        }}
        disabled={currentPage === 1}
      >
        <Icon
          path={mdiChevronLeft}
          size={1}
          style={{
            color: currentPage === 1 ? "#626262" : "white",
          }}
        />
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "#1E1E1E",
          width: "24px",
          height: "24px",
          marginLeft: "20px",
        }}
        onClick={() => {
          setCurrentPage(currentPage + 1);
          getDataForPage(currentPage + 1);
        }}
        disabled={currentPage >= totalPages}
      >
        <Icon
          path={mdiChevronRight}
          size={1}
          style={{
            color: currentPage >= totalPages ? "#626262" : "white",
          }}
        />
      </button>
    </DivStyle>
  );
};

export default UserPage;
