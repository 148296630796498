import styled from "styled-components";
import Props from "./interface/props";

const SelectFormStyle = styled.select<Props>`
  height: 35px;
  width: ${({ width }) => width || "100%"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  border: none;
  border-bottom: ${({ border }) => border || "1px solid white"};
  background-color: rgba(255, 255, 255, 0);
  color: white;
  &:focus {
    outline: none;
    border-bottom-color: #007bff;
  }
  option {
    height: 20px;
    color: white;
    background-color: gray;
  }

  @media (max-width: 300px) {
    width: 90%;
  }
`;

export default SelectFormStyle;
