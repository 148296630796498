import styled from "styled-components";
import Props from "./interface/props";

const InputFormStyle = styled.input<Props>`
  height: ${({ height }) => height || ""};
  padding: ${({ padding }) => padding || "8px 0px"};
  width: ${({ width }) => width || "100%"};
  font-size: ${({ fontSize }) => fontSize || "18px"};
  border: none;
  border-bottom: ${({ border }) => border || "1px solid white"};
  background-color: rgba(255, 255, 255, 0);
  color: ${({ color }) => color || "white"};
  &:focus {
    outline: none;
    border-bottom-color: #007bff;
  }

  &::file-selector-button {
    display: none;
  }

  @media (max-width: 300px) {
    width: 90%;
  }
`;

export default InputFormStyle;
