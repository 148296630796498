import { useEffect, useState } from "react";
import AdminAPI from "../../api/admin";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import { FormProps } from "../../components/form/interface/formInterface";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import SmallForm from "../../components/form/small_form";
import { BoardNoticeResponse } from "./interface/response";
import API from "../../api/api";

const BoardNotice = () => {
  const [dataList, setDataList] = useState<BoardNoticeResponse[]>([]);
  const [type, setType] = useState<null | any>({ id: 0, name: "핸드게시판" });
  const [mode, setMode] = useState("I");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const [selectedFile, setSelectedFile] = useState(null);

  const setIsLoading = useSetRecoilState(loadingState);

  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [contentHtml, setContentHtml] = useState("");

  const typeList = [
    { id: 0, name: "핸드게시판" },
    { id: 3, name: "딜러게시판" },
    { id: 2, name: "펍주게시판" },
  ];

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "제목",
        width: "100%",
        type: "input",
      },
      {
        value: type,
        setValue: setType,
        options: typeList,
        label: "쿠폰 종류",
        width: "100%",
        type: "category",
      },
      {
        value: contentHtml,
        setValue: setContentHtml,
        label: "내용",
        width: "100%",
        type: "notice-editor",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "제목",
      align: "center",
      sortable: false,
      value: "title",
    },
    {
      text: "게시판 종류",
      align: "center",
      sortable: false,
      value: "allowedRole",
    },
    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];

  const addForm = async () => {
    setId(0);
    setMode("I");
    setTitle("");
    setContentHtml("");
  };

  const save = async () => {
    if (!id) {
      return;
    }
    if (window.confirm("수정 하시겠습니까?")) {
      setIsLoading(true);
      const body = {
        title,
        content: contentHtml,
      };

      const result = await AdminAPI.updateBoardNotice(body, id);
      if (result) {
        alert("수정되었습니다.");
        addForm();
        getNotice();
      }
      setIsLoading(false);
    }
  };

  const add = async () => {
    if (window.confirm("신규 등록 하시겠습니까?")) {
      setIsLoading(true);
      const body = {
        title,
        content: contentHtml,
        allowedRole: type.id,
      };

      const res = await AdminAPI.registerBoardNotice(body);
      if (res) {
        alert("등록되었습니다.");
        addForm();
        getNotice();
      }
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      if (id) {
        setIsLoading(true);
        const res = await AdminAPI.removeBoardNotice(id);
        if (res) {
          alert("삭제되었습니다.");
          getNotice();
          addForm();
        }
        setIsLoading(false);
      }
    }
  };

  const setDetail = (item: BoardNoticeResponse) => {
    setId(item.id);
    setMode("U");
    setTitle(item.title);
    setContentHtml(item.content);
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getNotice = async () => {
    const notice = await AdminAPI.getBoardNotice();
    if (!notice) {
      return;
    }
    const updatedData = notice.map((item: BoardNoticeResponse) => ({
      ...item,
      createdAt: formatDate(item.createdAt),
      allowedRole:
        item.allowedRole === 0
          ? "핸드게시판"
          : item.allowedRole === 3
          ? "딜러게시판"
          : "펍주게시판",
    }));
    const totalPages = Math.ceil(updatedData.length / 10);
    setTotalPages(totalPages);
    setDataList(updatedData);
  };

  useEffect(() => {
    getNotice();
  }, []);

  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="column"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="100%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          게시판 공지사항
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            ></DivStyle>
          </DivStyle>

          <Table
            addForm={addForm}
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="100%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
            remove={remove}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default BoardNotice;
