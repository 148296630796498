import { adminApiRequest } from "../../utils/apiUtils";

export const PubMessageAPI = {
  uploadPubMessage: (body: any) => {
    return adminApiRequest.put("/cafe/addMessage", body).then((res) => res);
  },
  uploadPubMessageWithOut: (body: any) => {
    return adminApiRequest
      .put("/cafe/addMessageWithoutImage", body)
      .then((res) => res);
  },
  removeMessage: (id: number, pubId: number) => {
    return adminApiRequest
      .delete(`/cafe/messageDelete?pubId=${pubId}&messageId=${id}`)
      .then((res) => res);
  },
  updatePubMessage: (body: any) => {
    return adminApiRequest.post("/cafe/messageUpdate", body).then((res) => res);
  },
  updatePubMessageWithOut: (body: any) => {
    return adminApiRequest
      .post("/cafe/messageUpdateWithOutImage", body)
      .then((res) => res);
  },
  removePubMessageWithOut: (pubId: number, id: number) => {
    return adminApiRequest
      .delete(`/cafe/messageDelete?pubId=${pubId}&messageId=${id}`)
      .then((res) => res);
  },
};
