import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import Form from "../../components/form/form";
import API from "../../api/api";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { IPopupNotice } from "./interface/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const PopupNotice = () => {
  const [popupList, setPopupList] = useState<IPopupNotice[]>([]);
  const [searchPopupList, setsearchPopupList] = useState<IPopupNotice[]>([]);
  const [displayOrder, setDisplayOrder] = useState(1);
  const [id, setId] = useState(0);
  const [noticeTitle, setNoticeTitle] = useState("");
  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");

  const [appImage, setAppImage] = useState(null);
  const [webImage, setWebImage] = useState(null);
  const [webImageUrl, setWebImageUrl] = useState("");
  const [appImageUrl, setAppImageUrl] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [appUrl, setAppUrl] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");
  const setIsLoading = useSetRecoilState(loadingState);
  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: displayOrder,
        setValue: setDisplayOrder,
        label: "공지제목",
        width: "100%",
        type: "order-form",
      },
      {
        value: noticeTitle,
        setValue: setNoticeTitle,
        label: "공지제목",
        width: "100%",
        type: "input",
      },
      {
        value: startAt,
        setValue: setStartAt,
        label: "시작일시",
        width: "100%",
        type: "date",
      },
      {
        value: endAt,
        setValue: setEndAt,
        label: "종료일시",
        width: "100%",
        type: "date",
      },
      {
        value: webImage,
        setValue: setWebImage,
        label: "웹용 팝업 이미지를 첨부해주세요.",
        width: "100%",
        type: "file",
      },
      {
        value: webImageUrl,
        setValue: setWebImageUrl,
        label: "이미지",
        width: "100%",
        type: "image",
      },
      {
        value: webUrl,
        setValue: setWebUrl,
        label: "웹 팝업 상세페이지 URL을 입력하세요.",
        width: "100%",
        type: "input",
      },
      {
        value: appImage,
        setValue: setAppImage,
        label: "앱용 팝업 이미지를 첨부해주세요.",
        width: "100%",
        type: "file",
      },
      {
        value: appImageUrl,
        setValue: setAppImageUrl,
        label: "이미지",
        width: "100%",
        type: "image",
      },
      {
        value: appUrl,
        setValue: setAppUrl,
        label: "앱 팝업 상세페이지 URL을 입력하세요.",
        width: "100%",
        type: "input",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "타이틀",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "등록일시",
      align: "center",
      sortable: false,
      value: "startAt",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "endAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(0);
    setNoticeTitle("");
    setDisplayOrder(1);
    setAppImageUrl("");
    setWebImageUrl("");
    setStartAt("");
    setEndAt("");
    setAppUrl("");
    setWebUrl("");
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    const body = {
      title: noticeTitle,
      displayOrder,
      startAt: formatDate(startAt),
      endAt: formatDate(endAt),
      webUrl,
      appUrl,
    };
    const formData = new FormData();
    if (webImage && appImage) {
      formData.append("webImage", webImage);
      formData.append("appImage", appImage);
    } else {
      alert("이미지를 첨부해주세요.");
      return;
    }

    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.registerPopupNotice(formData);
      if (res && res.status === 200) {
        alert("신규 등록 되었습니다.");
        getPopupNoticeList();
        addForm();
      }
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removePopupNotice(id);
      if (res && res.status === 200) {
        alert("삭제 되었습니다.");
        getPopupNoticeList();
        addForm();
      }
      setIsLoading(false);
    }
  };

  const save = async () => {
    const body = {
      id,
      title: noticeTitle,
      displayOrder: displayOrder * 1,
      startAt: formatDate(startAt),
      endAt: formatDate(endAt),
      webUrl,
      appUrl,
    };
    if (appImage || webImage) {
      const formData = new FormData();
      if (webImage) {
        formData.append("webImage", webImage);
      }
      if (appImage) {
        formData.append("appImage", appImage);
      }
      formData.append(
        "data",
        new Blob([JSON.stringify(body)], {
          type: "application/json",
        })
      );
      setIsLoading(true);
      try {
        const res = await AdminAPI.updatePopupNoticeImage(formData);
        if (res && res.status === 200) {
          getPopupNoticeList();
          addForm();
        }
      } catch (e) {
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(true);
    const res = await AdminAPI.updatePopupNotice(body);
    if (res && res.status === 200) {
      alert("저장 되었습니다.");
      getPopupNoticeList();
      addForm();
    }
    setIsLoading(false);
  };

  const detail = (item: IPopupNotice) => {
    setMode("U");
    setId(item.id);
    setNoticeTitle(item.title);
    setDisplayOrder(item.displayOrder);
    setAppImageUrl(item.appImage);
    setWebImageUrl(item.webImage);
    setStartAt(item.startAt);
    setEndAt(item.endAt);
    setAppUrl(item.appUrl);
    setWebUrl(item.webUrl);
    setAppImage(null);
    setWebImage(null);
  };

  const getPopupNoticeList = async () => {
    const res = await API.getPopupList();
    if (res && res.status === 200) {
      const totalPages = Math.ceil(res.data.length / 10);
      setTotalPages(totalPages);
      setPopupList(res.data);
      setsearchPopupList(res.data);
    }
  };
  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    if (!search.trim()) {
      setsearchPopupList(popupList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(popupList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = popupList.filter((item: IPopupNotice) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setsearchPopupList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };
  useEffect(() => {
    getPopupNoticeList();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          팝업 공지사항 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="end"
            align_items="center"
          >
            <DivStyle
              width="50%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => handleSearch(e.target.value)}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchPopupList}
            setDetail={detail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchPopupList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <Form
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default PopupNotice;
