import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";

const LocationNotSearchForm = (props: InputFormProps) => {
  const { value, label, width } = props;
  const { value1, value2, label2 } = value;

  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {value1 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          width="100%"
          border="1px dashed white"
          value={value1}
          placeholder={label}
          disabled
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "20px",
        }}
      >
        {value2 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          width="100%"
          border="1px dashed white"
          value={value2}
          placeholder={label2}
          disabled
        ></InputFormStyle>
      </div>
    </div>
  );
};

export default LocationNotSearchForm;
