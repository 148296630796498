import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";
import ButtonStyle from "../style/button.component";

const LocationForm = (props: InputFormProps) => {
  const { value, label, width, setIsOpen, isOpen } = props;
  const { value1, value2, label2 } = value;

  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {value1 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          width="100%"
          border="1px solid white"
          value={value1}
          placeholder={label}
          onChange={(e) => {
            value.setValue1(e.target.value);
          }}
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "20px",
        }}
      >
        {value2 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label2}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label2}</label>
        )}
        <InputFormStyle
          width="100%"
          border="1px solid white"
          value={value2}
          placeholder={label2}
          onChange={(e) => {
            value.setValue2(e.target.value);
          }}
        ></InputFormStyle>
      </div>
      <ButtonStyle
        onClick={() => {
          setIsOpen?.(!isOpen);
        }}
        marginLeft="10px"
        width="70px"
        height="40px"
        border="none"
        backgroundColor="#1976D2"
        borderRadius="50px"
        color="white"
      >
        검색
      </ButtonStyle>
    </div>
  );
};

export default LocationForm;
