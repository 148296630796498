import styled from "styled-components";
import Props from "./interface/props";

const DateFormStyle = styled.input<Props>`
  height: ${({ height }) => height || ""};
  padding: 8px 8px;
  width: ${({ width }) => width || "100%"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  border: ${({ border }) => border || "1px solid white"};
  background-color: #424242;
  color: white;
  &:focus {
    outline: none;
    border-color: #007bff;
  }

  @media (max-width: 300px) {
    width: 90%;
  }
`;

export default DateFormStyle;
