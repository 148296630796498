import { adminApiRequest } from "../../utils/apiUtils";
export enum GameCouponType {
  GAMEPOINT = "GAMEPOINT",
}
export interface GameCouponResponse {
  id: number;
  type: GameCouponType;
  value: number;
  title: string;
  closedAt: string;
}
export interface GameCouponRequest {
  type: GameCouponType;
  value: number;
  title: string;
  closedAt: string;
}
export interface IssuedGameCouponRequest {
  couponId: number;
  count: number;
}
export interface IssuedGameCouponResponse {
  id: number;
  numbering: number;
  code: string;
  nickname?: string | null;
  usedTime?: string | null;
}
export const GameCouponAPI = {
  getGameCoupon: () => {
    return adminApiRequest.get("/game-coupon").then((res) => res.data);
  },
  getIssuedGameCoupon: (id: number) => {
    return adminApiRequest
      .get(`/game-coupon/issued/${id}`)
      .then((res) => res.data);
  },
  registerGameCoupon: (request: GameCouponRequest) => {
    return adminApiRequest
      .post("/game-coupon", request)
      .then((res) => res.data);
  },
  issuedGameCoupon: (request: IssuedGameCouponRequest) => {
    return adminApiRequest
      .post("/game-coupon/issued", request)
      .then((res) => res.data);
  },
  updateGameCoupon: (id: number, request: GameCouponRequest) => {
    return adminApiRequest
      .put(`/game-coupon/${id}`, request)
      .then((res) => res.data);
  },
  removeGameCoupon: (id: number) => {
    return adminApiRequest.delete(`/game-coupon/${id}`).then((res) => res.data);
  },
};
