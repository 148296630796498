import { useEffect, useState } from "react";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import ButtonStyle from "../../components/style/button.component";
import API from "../../api/api";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import SmallForm from "../../components/form/small_form";
import { CouponResponse } from "../../api/admin/coupon";
import SearchUser from "../../components/dialog/SeachUser";

const Coupon = () => {
  const [list, setList] = useState<CouponResponse[]>([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [warning, setWarning] = useState("");
  const [closeAt, setCloseAt] = useState("");
  const [userId, setUserId] = useState<number | null>(null);
  const [userNickname, setUserNickName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [couponImageUrl, setCouponImageUrl] = useState("");
  const [type, setType] = useState<null | any>({ id: 1, name: "COUPON" });
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("I");
  const [isUserSearchOpen, setIsUserSearchOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCouponFile, setSelectedCouponFile] = useState(null);

  const typeList = [
    {
      id: 0,
      name: "COUPON",
    },
    {
      id: 1,
      name: "DISCOUNT",
    },
  ];
  const formData: FormProps = {
    title: "상세 정보",
    form: [
      {
        value: title,
        setValue: setTitle,
        label: "제목",
        width: "100%",
        type: "input",
      },
      {
        value: userNickname,
        setValue: setUserNickName,
        label: "유저",
        width: "100%",
        isOpen: isUserSearchOpen,
        setIsOpen: setIsUserSearchOpen,
        type: "search-group",
      },
      {
        value: type,
        setValue: setType,
        options: typeList,
        label: "쿠폰 종류",
        width: "100%",
        type: "category",
      },
      {
        value: closeAt,
        setValue: setCloseAt,
        label: "종료일시",
        width: "100%",
        type: "date",
      },
      {
        value: description,
        setValue: setDescription,
        label: "사용방법",
        width: "100%",
        type: "input",
      },
      {
        value: warning,
        setValue: setWarning,
        label: "주의사항",
        width: "100%",
        type: "input",
      },
      {
        value: selectedFile,
        setValue: setSelectedFile,
        label: "코드 사진",
        width: "100%",
        type: "file",
      },
      {
        value: imageUrl,
        setValue: setImageUrl,
        label: "코드 이미지",
        width: "100%",
        type: "image",
      },
      {
        value: selectedCouponFile,
        setValue: setSelectedCouponFile,
        label: "쿠폰 썸네일",
        width: "100%",
        type: "file",
      },
      {
        value: couponImageUrl,
        setValue: setCouponImageUrl,
        label: "쿠폰 썸네일",
        width: "100%",
        type: "image",
      },
    ],
  };

  const headers = [
    {
      text: "id",
      align: "start",
      sortable: false,
      value: "id",
    },
    {
      text: "타이틀",
      align: "start",
      sortable: false,
      value: "title",
    },
    {
      text: "닉네임",
      align: "center",
      sortable: false,
      value: "userNickname",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "closeAt",
    },
  ];

  const addForm = () => {
    setMode("I");
    setId(0);
    setTitle("");
    setDescription("");
    setWarning("");
    setImageUrl("");
    setUserId(null);
    setUserNickName("");
    setType({ id: 1, name: "COUPON" });
    setCloseAt("");
    setSelectedFile(null);
    setCouponImageUrl("");
    setSelectedCouponFile(null);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function formatJsonDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const add = async () => {
    const body = {
      title,
      description,
      warning,
      type: type.id,
      closeAt: formatJsonDate(closeAt),
      userId,
    };
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (!selectedFile) {
      alert("코드 이미지를 선택해주세요.");
      return;
    }
    if (!selectedCouponFile) {
      alert("쿠폰 이미지를 선택해주세요.");
      return;
    }
    formData.append("image", selectedFile);
    formData.append("couponImage", selectedCouponFile);

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerCoupon(formData);
        if (res) {
          alert("신규 등록 되었습니다.");
          getCoupons();
          addForm();
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    }
  };

  const remove = async () => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeCoupon(id);
      if (res) {
        alert("삭제 되었습니다.");
        getCoupons();
        addForm();
      }
      setIsLoading(false);
    }
  };

  const save = async () => {
    const body = {
      title,
      description,
      warning,
      type: type.id,
      closeAt: formatJsonDate(closeAt),
      userId,
    };
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(body)], {
        type: "application/json",
      })
    );
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    if (selectedCouponFile) {
      formData.append("couponImage", selectedCouponFile);
    }
    setIsLoading(true);
    try {
      const res = await AdminAPI.updateCoupon(formData, id);
      if (res) {
        alert("저장 되었습니다.");
        getCoupons();
        addForm();
        setIsLoading(false);
      }
    } catch (e: any) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const setDetails = (item: CouponResponse) => {
    setMode("U");
    setId(item.id);
    setTitle(item.title);
    setDescription(item.description);
    setCloseAt(item.closeAt);
    setWarning(item.warning);
    setImageUrl(item.imageUrl);
    setUserId(item.userId);
    setUserNickName(item.userNickname);
    setCouponImageUrl(item.couponImageUrl);
    setType({ id: item.type === "COUPON" ? 0 : 1, name: item.type });
  };

  const getCoupons = async () => {
    const res = await AdminAPI.getCoupons();
    if (res) {
      const updatedData = res.map((item: CouponResponse) => ({
        ...item,
        closeAt: formatDate(item.closeAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      console.log(updatedData);
      setList(updatedData);
    }
  };
  useEffect(() => {
    getCoupons();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          쿠폰 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={list}
            setDetail={setDetails}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={list}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          <ButtonStyle
            border="none"
            backgroundColor="#1976D2"
            width="85px"
            height="40px"
            color="white"
            borderRadius="5px"
            onClick={() => addForm()}
          >
            신규 등록
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm
            form={formData.form}
            title={formData.title}
            add={add}
            save={save}
            remove={remove}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
      {isUserSearchOpen ? (
        <SearchUser
          setUserId={setUserId}
          setUserNickname={setUserNickName}
          setIsOpen={setIsUserSearchOpen}
        />
      ) : null}
    </DivStyle>
  );
};

export default Coupon;
