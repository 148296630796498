import React, { useEffect } from "react";
import SelectFormStyle from "../style/select.component";
import {
  InputFormProps,
  SelectOptionInterface,
} from "./interface/formInterface";

const FormSelect: React.FC<InputFormProps> = ({
  value,
  setValue,
  label,
  width,
  options,
}) => {
  useEffect(() => {
    if (!value && options && options.length > 0) {
      setValue(options[0].value);
    }
  }, [options, setValue, value]);

  return (
    <div style={{ width, marginTop: "10px" }}>
      <SelectFormStyle
        value={value}
        name="select"
        width="100%"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setValue(e.target.value);
        }}
      >
        <option value="" disabled>
          {label}
        </option>
        {options?.map((item: SelectOptionInterface, index: number) => (
          <option value={item.value} key={index}>
            {item.name ? item.name : item.value}
          </option>
        ))}
      </SelectFormStyle>
    </div>
  );
};

export default FormSelect;
