import DateFormStyle from "../style/date.component";
import { InputFormProps } from "./interface/formInterface";

const FormDate = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  return (
    <div
      style={{
        width,
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <label style={{ color: "white", fontSize: "12px", marginBottom: "5px" }}>
        {label}
      </label>
      <DateFormStyle
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        type="datetime-local"
        width="100%"
      ></DateFormStyle>
    </div>
  );
};

export default FormDate;
