import { useEffect, useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
interface AddressProps {
  setOldAddress?: (value: any) => void;
  setNewAddress?: (value: any) => void;
  setLat: (value: any) => void;
  setLon: (value: any) => void;
  setIsOpen: (value: any) => void;
}
const SearchAddress = (props: AddressProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState([]);
  const { setOldAddress, setNewAddress, setLat, setLon, setIsOpen } = props;

  const [search, setSearch] = useState("");
  const headers = [
    {
      text: "주소",
      align: "center",
      value: "roadAddress",
      sortable: false,
    },
  ];

  const handleSearch = () => {
    window.naver.maps.Service.geocode(
      {
        query: search,
      },
      function (status: number, response: any) {
        if (status !== window.naver.maps.Service.Status.OK) {
          alert("없는 주소입니다.");
          return;
        }
        setDataList(response.v2.addresses);
      }
    );
  };

  const setData = () => {
    if (dataList.length > 0 && selected !== "") {
      const data = dataList[parseInt(selected)];
      setLon(data["x"]);
      setLat(data["y"]);
      if (setNewAddress && setOldAddress) {
        setNewAddress(data["roadAddress"]);
        setOldAddress(data["jibunAddress"]);
      }

      setIsOpen(false);
    } else {
      alert("선택해주세요.");
    }
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=6o0zptar2v&submodules=geocoder";
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>주소 검색</h4>
      <DivStyle
        width="80%"
        display="flex"
        flex_direction="row"
        align_items="center"
      >
        <InputFormStyle
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        ></InputFormStyle>
        <div onClick={() => handleSearch()}>
          <Icon path={mdiMagnify} size={1} />
        </div>
      </DivStyle>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />

      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SearchAddress;
