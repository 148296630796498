import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import { InputFormProps } from "./interface/formInterface";

const FormOrder = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  return (
    <DivStyle
      marginTop="30px"
      display="flex"
      flex_direction="row"
      width="100%"
      align_items="center"
      justify_content="center"
    >
      <ButtonStyle
        border="none"
        backgroundColor="#666666"
        width="70px"
        height="35px"
        borderRadius="5px"
        color="white"
        isSelect={value === 1}
        onClick={() => setValue(1)}
      >
        1
      </ButtonStyle>
      <ButtonStyle
        border="none"
        backgroundColor="#666666"
        width="70px"
        height="35px"
        borderRadius="5px"
        color="white"
        marginLeft="10px"
        isSelect={value === 2}
        onClick={() => setValue(2)}
      >
        2
      </ButtonStyle>
      <ButtonStyle
        border="none"
        backgroundColor="#666666"
        width="70px"
        height="35px"
        borderRadius="5px"
        color="white"
        marginLeft="10px"
        isSelect={value === 3}
        onClick={() => setValue(3)}
      >
        3
      </ButtonStyle>
    </DivStyle>
  );
};

export default FormOrder;
