import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import AdminAPI from "../../api/admin";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import ReferralForm from "../../components/form/referral_form";
import { IRole } from "./interface/interface";
import { IUser } from "../user/interface/interface";
import CafePagingTable from "../../components/table/CafePagingTable";
import UserPage from "../../components/table/userPaing";

const ReferralSales = () => {
  const [userList, setUserList] = useState<IUser[]>([]);
  const [searchUserList, setSearchUserList] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mode, setMode] = useState("V");

  const [username, setUserName] = useState("");
  const [nickname, setNickName] = useState("");
  const [textId, setTextId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [gender, setGender] = useState("");
  const [referral, setReferral] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const roles: IRole[] = [
    {
      type: "ROLE_USER",
      value: "일반유저",
    },
    {
      type: "ROLE_SELLER",
      value: "판매자",
    },
    {
      type: "ROLE_ADMIN",
      value: "어드민",
    },
    {
      type: "ROLE_DEALER",
      value: "딜러",
    },
  ];
  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: textId,
        setValue: setTextId,
        label: "아이디",
        type: "input-dash",
        width: "100%",
      },
      {
        value: username,
        setValue: setTextId,
        label: "사용자명",
        type: "input-dash",
        width: "100%",
      },
      {
        value: nickname,
        setValue: setTextId,
        label: "닉네임",
        type: "input-dash",
        width: "100%",
      },
      {
        value: birthDay,
        setValue: setTextId,
        label: "생년월일",
        type: "input-dash",
        width: "100%",
      },
      {
        value: phoneNumber,
        setValue: setTextId,
        label: "핸드폰번호",
        type: "input-dash",
        width: "100%",
      },
      {
        value: gender,
        setValue: setTextId,
        label: "성별",
        type: "input-dash",
        width: "100%",
      },
      {
        value: referral,
        setValue: setReferral,
        label: "레퍼럴",
        type: "input-dash",
        width: "100%",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "아이디",
      align: "start",
      sortable: false,
      value: "textId",
    },
    {
      text: "사용자명",
      align: "center",
      sortable: false,
      value: "username",
    },
    {
      text: "닉네임",
      align: "center",
      sortable: false,
      value: "nickname",
    },
    {
      text: "생년월일",
      align: "center",
      sortable: false,
      value: "birthDay",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "가입일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "탈퇴여부",
      align: "center",
      sortable: false,
      value: "exit",
    },
  ];
  const setDetail = async (item: IUser) => {
    setMode("U");
    setTextId(item.textId);
    setUserName(item.username);
    setNickName(item.nickname);
    setPhoneNumber(item.phoneNumber);
    setBirthDay(item.birthDay);
    setGender(item.gender);
  };

  const save = async () => {
    window.navigator.clipboard.writeText(referral);
    alert("복사되엇습니다.");
  };
  const add = async () => {
    setReferral(
      "https://runnerrunner.co.kr/signup?referral=" + nickname
    );
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function formatBirthDay(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const RoleName = (value: string) => {
    let rtn = "";
    if (value) {
      rtn = roles.find((element: IRole) => element.type === value)?.value || "";
    }
    return rtn;
  };
  const getUsers = async () => {
    const res = await AdminAPI.getUsers(0);
    if (res && res.status === 200) {
      console.log(res.data.userList);
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        role: RoleName(item.role),
        birthday: formatBirthDay(item.birthday),
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setUserList(updatedData);
      setSearchUserList(updatedData);
    }
  };
  const handleSearch = async (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
    setIsSearch(true);
    if (!search.trim()) {
      setIsSearch(false);
      getUsers();
      return;
    }

    const res = await AdminAPI.findUsers(search, currentPage - 1);
    if (res && res.status === 200) {
      console.log(res.data.userList);
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        role: RoleName(item.role),
        birthday: formatBirthDay(item.birthday),
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setSearchUserList(updatedData);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          사용자 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <CafePagingTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchUserList}
            setDetail={setDetail}
          />
          <UserPage
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchUserList}
            setCurrentPage={setCurrentPage}
            count={totalCount}
            setDataList={setUserList}
            setSearchDataList={setSearchUserList}
            isSearch={isSearch}
            search={search}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <ReferralForm
            form={formData.form}
            title={formData.title}
            save={save}
            add={add}
            mode={mode}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default ReferralSales;
