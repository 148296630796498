import { adminApiRequest } from "../../utils/apiUtils";

export const EventAPI = {
  removeEventBanner: (id: number) => {
    return adminApiRequest
      .delete(`/runner-event/delete?id=${id}`)
      .then((res) => res);
  },

  registerEventBanner: (formData: FormData) => {
    return adminApiRequest
      .post("/runner-event/register", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  updateEventBanner: (id: number, formData: FormData) => {
    return adminApiRequest
      .put(`/runner-event/update?id=${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },
};
