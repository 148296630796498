import { useEffect, useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import PremiumTable from "../table/PremiumTable";
import API from "../../api/api";
import AdminAPI from "../../api/admin";
import AddRecommend from "./AddRecommend";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

interface PremiumProps {
  setIsOpen: (value: any) => void;
}
const PremiumCafe = (props: PremiumProps) => {
  const { setIsOpen } = props;
  const [currentPage] = useState(1);
  const [IsPremiumOpen, setIsPremiumOpen] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const setIsLoading = useSetRecoilState(loadingState);
  const [premiumCafeList, setPremiumCafeList]: any = useState([]);
  const headers = [
    {
      text: "노출순위",
      align: "center",
      value: "cafeIndex",
      sortable: false,
    },
    {
      text: "순위 노출 가맹점",
      align: "center",
      value: "cafe.cafeName",
      sortable: false,
    },
    {
      text: "노출 순위 삭제",
      align: "center",
      value: "id",
      sortable: false,
    },
  ];

  const getCafes = async () => {
    const res = await API.getRecommendCafes();
    if (res && res.status === 200) {
      let sortCafe = res.data.filter((item: any) => item.cafeIndex !== null);
      sortCafe.sort((a: any, b: any) => a.cafeIndex - b.cafeIndex);
      setPremiumCafeList(sortCafe);
    }
  };

  useEffect(() => {
    getCafes();
  }, []);

  const setData = async () => {
    let pcList = premiumCafeList;
    const combinedList = [...pcList, ...deleteList];
    const req = [];
    for (let i = 0; i < combinedList.length; i++) {
      req.push({
        id: combinedList[i].id,
        cafeId: combinedList[i].cafe.id,
        cafeIndex: combinedList[i].cafeIndex,
      });
    }
    setIsLoading(true);
    const res = await AdminAPI.updateRecommendList(req);
    if (res && res.status === 200) {
      alert("수정되었습니다.");
      setIsOpen(false);
    }
    setIsLoading(false);
  };
  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>프리미엄 홀덤 펍 노출 순위</h4>
      <PremiumTable
        headers={headers}
        currentPage={currentPage}
        dataList={premiumCafeList}
        setDataList={setPremiumCafeList}
        setDeleteList={setDeleteList}
        deleteList={deleteList}
      />
      <DivStyle
        display="flex"
        flex_direction="row"
        width="300px"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="120px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setIsPremiumOpen(true)}
        >
          카페 추가
        </ButtonStyle>
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
      {IsPremiumOpen ? (
        <AddRecommend
          premiunCafes={premiumCafeList}
          setPremiunCafes={setPremiumCafeList}
          setIsOpen={setIsPremiumOpen}
        />
      ) : null}
    </DivStyle>
  );
};

export default PremiumCafe;
