export interface IMenuItem {
  name: string;
  path?: string;
  children?: Array<IMenuItem>;
  icon: string;
}

export const MENU: IMenuItem[] = [
  {
    name: "대시보드",
    icon: "mdiApps",
    path: "/",
  },
  {
    name: "토너먼트(게임)",
    icon: "mdiMessageBulleted",
    children: [
      {
        name: "토너먼트(게임)",
        path: "/tournament",
        icon: "",
      },
      {
        name: "토너먼트(게임) 랭킹",
        path: "/tournamentRanking",
        icon: "",
      },
      {
        name: "자동 랭킹 보상 로그",
        path: "/autoRankingLog",
        icon: "",
      },
      {
        name: "랭킹 보상 세팅",
        path: "/setRankingPoint",
        icon: "",
      },
      {
        name: "토너먼트(관리)",
        path: "/tournamentAdmin",
        icon: "mdiStar",
      },
      {
        name: "입장 설정 및 친구 보상",
        path: "/gameSetting",
        icon: "mdiNintendoGameBoy",
      },
    ],
  },

  {
    name: "공지",
    icon: "mdiMaterialUi",
    children: [
      {
        name: "팝업 공지사항",
        path: "/popupNotice",
        icon: "",
      },
      {
        name: "공지사항",
        path: "/notice",
        icon: "",
      },
      {
        name: "자주하는 질문",
        path: "/faq",
        icon: "",
      },
      {
        name: "중고장터 공지사항",
        path: "/market-notice",
        icon: "",
      },
      {
        name: "핸드 게시판 공지사항",
        path: "/board-notice",
        icon: "",
      },
    ],
  },
  {
    name: "펍",
    icon: "mdiMapMarkerStar",
    children: [
      {
        name: "펍",
        path: "/cafe",
        icon: "",
      },
      {
        name: "리뷰",
        path: "/review",
        icon: "", //"mdiMapMarkerStar",
      },
      {
        name: "허위신고",
        path: "/report",
        icon: "", //"mdiBullhorn",
      },
      {
        name: "푸시 횟수 컨트롤 로그",
        path: "/push-count-log",
        icon: "",
      },
    ],
  },
  {
    name: "제휴 관리",
    icon: "mdiMessageBulleted",
    children: [
      {
        name: "제휴 신청 관리",
        path: "/pub-grade",
        icon: "",
      },
      {
        name: "제휴 관리 로그",
        path: "/pub-grade-log",
        icon: "",
      },
    ],
  },
  {
    name: "출석 보상",
    icon: "mdiNewspaper",
    children: [
      {
        name: "출석 테이블",
        path: "/attendance-table",
        icon: "",
      },
      {
        name: "출석 보상 설정",
        path: "/attendance-reward",
        icon: "",
      },
      {
        name: "출석 현황",
        path: "/attendance-history",
        icon: "",
      },
    ],
  },
  {
    name: "사용자",
    path: "/user",
    icon: "mdiAccountCard",
  },
  {
    name: "쿠폰함",
    icon: "mdiApps",
    path: "/coupon",
  },
  {
    name: "게임 쿠폰",
    icon: "mdiApps",
    children: [
      {
        name: "게임 쿠폰 생성 및 삭제",
        path: "/game-coupon",
        icon: "",
      },
      {
        name: "게임 쿠폰 발행",
        path: "/issued-game-coupon",
        icon: "",
      },
    ],
  },
  {
    name: "이벤트 배너",
    path: "/eventBanner",
    icon: "mdiImageFilterFrames",
  },
  {
    name: "토너먼트 소식",
    icon: "mdiNewspaper",
    children: [
      {
        name: "뉴스 리스트",
        path: "/news",
        icon: "",
      },
      {
        name: "뉴스 카테고리",
        path: "/news-category",
        icon: "",
      },
      {
        name: "토너먼트",
        path: "/brandCompetition",
        icon: "",
      },
    ],
  },

  {
    name: "커뮤니티",
    icon: "mdiFormatListBulleted",
    children: [
      {
        name: "핸드게시판",
        path: "/article",
        icon: "",
      },
      {
        name: "핸드게시판 댓글",
        path: "/article-comment",
        icon: "",
      },
      {
        name: "가맹점 구인",
        path: "/recruiting",
        icon: "",
      },
      {
        name: "일반 유저 구인",
        path: "/user-recruiting",
        icon: "mdiFormatListBulleted",
      },
      {
        name: "중고장터 리스트",
        path: "/market",
        icon: "",
      },
      {
        name: "중고 장터 댓글",
        path: "/market-comment",
        icon: "",
      },
    ],
  },
  {
    name: "커뮤니티 신고",
    icon: "mdiFormatListBulleted",
    children: [
      {
        name: "핸드게시판",
        path: "/article-report",
        icon: "",
      },
      {
        name: "핸드게시판 댓글",
        path: "/article-comment-report",
        icon: "",
      },
      {
        name: "중고장터",
        path: "second-hand-market-report",
        icon: "",
      },
      {
        name: "중고장터 댓글",
        path: "second-hand-market-comment-report",
        icon: "",
      },
      {
        name: "구인구직(유저)",
        path: "user-job-opening-report",
        icon: "",
      },
    ],
  },
  {
    name: "러튜브",
    icon: "mdiYoutube",
    children: [
      {
        name: "러너 영상",
        path: "/youtube",
        icon: "mdiYoutube",
      },
      {
        name: "유튜브 채널",
        path: "/youtube-channel",
        icon: "mdiYoutube",
      },
    ],
  },
  {
    name: "오픈채팅방",
    path: "/openChat",
    icon: "mdiChat",
  },
  {
    name: "광고 관리",
    path: "/advertisement",
    icon: "mdiAdvertisements",
  },
  {
    name: "업데이트",
    path: "/appUpdate",
    icon: "mdiCloudDownload",
  },
  {
    name: "레퍼럴(영업용)",
    path: "/referralSales",
    icon: "mdiAccountCard",
  },
];
