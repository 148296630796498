import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";
import FormCheckInput from "./form_check";
import FormCafeCheckInput from "./form_cafe_check";

const EmPloyerForm = (props: InputFormProps) => {
  const { value, label, width, setValue } = props;
  const { value1, value2, value3, value4, setValue2, setValue3, setValue4 } =
    value;
  return (
    <div
      style={{
        width: width,
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {value1 !== "" ? (
          <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
        ) : (
          <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
        )}
        <InputFormStyle
          width="100%"
          value={value1}
          placeholder={label}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        ></InputFormStyle>
      </div>
      <div
        style={{
          width: "50%",
          marginLeft: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormCafeCheckInput
          value={{ value1: value3, value2: value2, setValue2: setValue2 }}
          setValue={setValue3}
          width="100%"
          label="스탠다드"
        />
        <FormCafeCheckInput
          value={{ value1: value2, value2: value3, setValue2: setValue3 }}
          setValue={setValue2}
          width="100%"
          label="프리미엄"
        />
      </div>
    </div>
  );
};

export default EmPloyerForm;
