import { adminApiRequest } from "../../utils/apiUtils";

export const CommentAPI = {
  getUserComments: () => {
    return adminApiRequest
      .get("/runner-comment/commentList")
      .then((res) => res);
  },

  getSecondHandMarketComment: () => {
    return adminApiRequest
      .get("/second-hand-market-comment")
      .then((res) => res);
  },

  removeSecondHandMarketComment: (id: number) => {
    return adminApiRequest
      .patch(`/second-hand-market-comment/exit/${id}/?exit=true`)
      .then((res) => res);
  },

  removeUserComment: (id: number) => {
    return adminApiRequest
      .delete(`/runner-comment/deleteComment?commentId=${id}`)
      .then((res) => res);
  },
};
