import { useEffect, useState, useRef } from "react";
import DivStyle from "../style/div.component";
import InputStyle from "../style/input.component";
import { InputFormProps } from "./interface/formInterface";

const FacilityForm = (props: InputFormProps) => {
  const { value, setValue, label, width } = props;
  const [isOpen, setIsOpen] = useState(false);
  const facilityList = [
    {
      id: 1,
      type: "WIFI",
      name: "와이파이",
    },
    {
      id: 2,
      type: "PARKING",
      name: "주차가능",
    },
    {
      id: 3,
      type: "SMOKING",
      name: "흡연실",
    },
    {
      id: 4,
      type: "FOOD",
      name: "식사제공",
    },
    {
      id: 5,
      type: "REST_AREA",
      name: "휴게실",
    },
    {
      id: 6,
      type: "COFFEE",
      name: "커피제공",
    },
  ];

  const node = useRef<HTMLDivElement>(null);
  const inputNode = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      node.current &&
      !node.current.contains(event.target as Node) &&
      inputNode.current &&
      !inputNode.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    setIsOpen(!isOpen);
  };

  const getTypeName = (type: string) => {
    const facility = facilityList.find((facility) => facility.type === type);
    return facility ? facility.name : undefined;
  };

  const handleCheckboxChange = (option: any) => {
    if (value.some((item: any) => item === option.type)) {
      setValue(value.filter((item: any) => item !== option.type));
    } else {
      setValue([...value, option.type]);
    }
  };

  const checkBoxValue = (option: any) => {
    return value.some((item: any) => item === option.type);
  };

  const viewValue = () => {
    const names = value.map((item: any) => getTypeName(item));
    return names.join(", ");
  };

  return (
    <DivStyle width={width} position="relative">
      <InputStyle
        ref={inputNode}
        style={{ color: "white" }}
        value={viewValue()}
        width={width}
        type="text"
        placeholder={label}
        onClick={() => handleInputClick()}
      />
      {isOpen && (
        <DivStyle
          ref={node}
          style={{
            top: "100%",
            left: "0",
            zIndex: "100",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            padding: "10px",
          }}
          display="flex"
          flex_direction="column"
          position="absolute"
          backgroundColor="#1E1E1E"
          border="1px solid #ccc"
          borderRadius="4px"
          width="100%"
          height="200px"
        >
          {facilityList.map((option) => (
            <label
              key={option.name}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ height: "40px" }}
                type="checkbox"
                checked={checkBoxValue(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <span style={{ color: "white", fontWeight: "bold" }}>
                {option.name}
              </span>
            </label>
          ))}
        </DivStyle>
      )}
    </DivStyle>
  );
};
export default FacilityForm;
