import { adminApiRequest } from "../../utils/apiUtils";

export interface CouponResponse {
  id: number;
  userId: number;
  userNickname: string;
  title: string;
  type: string;
  closeAt: string;
  description: string;
  warning: string;
  imageUrl: string;
  couponImageUrl: string;
}

export interface CouponRequest {
  userId: number;
  title: string;
  type: number;
  closeAt: string;
  description: string;
  warning: string;
}
export const CouponAPI = {
  getCoupons: () => {
    return adminApiRequest.get("/coupon").then((res) => res.data);
  },
  registerCoupon: (formData: FormData) => {
    return adminApiRequest
      .post("/coupon", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data);
  },
  updateCoupon: (formData: FormData, id: number) => {
    return adminApiRequest
      .put(`/coupon/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data);
  },
  removeCoupon: (id: number) => {
    return adminApiRequest.delete(`/coupon/${id}`).then((res) => res.data);
  },
};
