import { useEffect, useState } from "react";
import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import Table from "../table/Table";
import Page from "../table/paging";
import API from "../../api/api";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { useRecoilValue } from "recoil";
import { UserState } from "../../recoil/user.atom";

const FriendWithdraw = () => {
  const [dataList, setDataList]: any = useState([]);
  const [searchDataList, setSearchDataList]: any = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const user: any = useRecoilValue(UserState);
  const headers = [
    {
      text: "날짜",
      align: "center",
      sortable: false,
      value: "createDt",
    },
    {
      text: "보낸 닉네임",
      align: "center",
      sortable: false,
      value: "friendNickname",
    },
    {
      text: "리바인",
      align: "center",
      sortable: false,
      value: "rebuy",
    },
    {
      text: "게임머니",
      align: "center",
      sortable: false,
      value: "gameMoney",
    },
  ];
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getList = async () => {
    const res = await API.getFriendRecommendsById(user.id);
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: any) => ({
        ...item,
        createDt: formatDate(item.createDt),
      }));
      setDataList(updatedData);
      const totalPages = Math.ceil(res.data.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(updatedData);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: any) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      width="100%"
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="column"
      align_items="center"
      marginTop="65px"
    >
      <DivStyle
        width="80%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="60px"
      >
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          justify_content="end"
          align_items="center"
        >
          <DivStyle
            width="50%"
            display="flex"
            justify_content="flex-end"
            flex_direction="row"
            align_items="center"
            marginRight="20px"
            marginTop="20px"
          >
            <InputFormStyle
              onChange={(e) => handleSearch(e.target.value)}
            ></InputFormStyle>
            <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
          </DivStyle>
        </DivStyle>
        <Table
          headers={headers}
          currentPage={currentPage}
          dataList={searchDataList}
        />
        <Page
          currentPage={currentPage}
          totalPages={totalPages}
          dataList={searchDataList}
          setCurrentPage={setCurrentPage}
        />
      </DivStyle>
    </DivStyle>
  );
};
export default FriendWithdraw;
