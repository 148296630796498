import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import AdminAPI from "../../api/admin";
import { AttendanceUserDto } from "../../api/admin/attendance";
import AttendancePage from "../../components/table/AttendancePaging";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import CafePagingTable from "../../components/table/CafePagingTable";

const AttendanceHistory = () => {
  const [dataList, setDataList] = useState<AttendanceUserDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [keyword, setKeyWord] = useState("");
  const limit = 10;
  const headers = [
    { text: "닉네임", align: "center", sortable: false, value: "nickname" },
    { text: "수령일", align: "center", sortable: false, value: "createdAt" },
    { text: "수령 GP", align: "center", sortable: false, value: "value" },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleSearch = async (search: string) => {
    setCurrentPage(1);

    const res = await AdminAPI.getAttendanceUser(currentPage, limit, search);
    if (res) {
      const updatedData = res.data.map((item: AttendanceUserDto) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.total / limit);
      setTotalCount(res.total);
      setTotalPages(totalPages);
      setDataList(updatedData);
    }
  };
  const getDatas = async () => {
    const res = await AdminAPI.getAttendanceUser(currentPage, limit, keyword);
    if (res) {
      const updatedData = res.data.map((item: AttendanceUserDto) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.total / limit);
      setTotalCount(res.total);
      setTotalPages(totalPages);
      setDataList(updatedData);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);
  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
      >
        <h2 style={{ color: "white", width: "95%", marginTop: "40px" }}>
          출석 현황
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="flex-end"
            align_items="center"
          >
            <DivStyle
              width="30%"
              display="flex"
              justify_content="flex-end"
              flex_direction="row"
              align_items="center"
              marginRight="20px"
              marginTop="20px"
            >
              <InputFormStyle
                onChange={(e) => {
                  setKeyWord(e.target.value);
                  handleSearch(e.target.value);
                }}
              ></InputFormStyle>
              <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
            </DivStyle>
          </DivStyle>

          <CafePagingTable
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            size={limit}
          />
          <AttendancePage
            count={totalCount}
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setDataList={setDataList}
            setCurrentPage={setCurrentPage}
            search={keyword}
          />
        </DivStyle>
      </DivStyle>
    </>
  );
};

export default AttendanceHistory;
