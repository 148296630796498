import { useEffect, useState } from "react";
import GAMEAPI from "../../api/game";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
interface GroupProps {
  setGroupId: (value: any) => void;
  setStartedAt: (value: any) => void;
  setIsOpen: (value: any) => void;
}
const GroupDialog = (props: GroupProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState([]);
  const { setGroupId, setStartedAt, setIsOpen } = props;
  const headers = [
    {
      text: "그룹ID",
      align: "center",
      value: "groupId",
      sortable: false,
    },
    {
      text: "토너먼트 명",
      align: "center",
      value: "data.name",
      sortable: false,
    },
    {
      text: "운영여부",
      align: "center",
      value: "isOpen",
      sortable: false,
    },
  ];

  const getList = async () => {
    const res = await GAMEAPI.getTournamentList();
    if (res.status === 200) {
      const updatedData = res.data.list.map((item: any) => ({
        ...item,
        isOpen: item.isOpen === 1 ? "운영중" : "미운영",
      }));
      setDataList(updatedData);
    }
  };

  const convertToKoreanTime = (dateString: string) => {
    const utcTime = new Date(`${dateString} UTC`);
    const korTime = new Date(utcTime.getTime() + 9 * 60 * 60 * 1000);
    const korTimeString = korTime.toISOString().replace("T", " ").split(".")[0];
    return korTimeString;
  };

  const setData = () => {
    const data = dataList[parseInt(selected)];
    const koreanTime = convertToKoreanTime(data["data"]["startedAt"]);
    setGroupId(data["groupId"]);
    setStartedAt(koreanTime);
    setIsOpen(false);
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>토너먼트 (그룹ID) 검색</h4>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />
      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default GroupDialog;
