import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";

const FormNickNameInput = (props: InputFormProps) => {
  const { value, setValue, label, width, height, fontSize } = props;
  const handleChange = (e: any) => {
    const newValue = e.target.value;
    const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]*$/;
    if (regex.test(newValue) && newValue.length <= 7) {
      setValue(newValue);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <div style={{ width: width, marginTop: "10px" }}>
      {value !== "" ? (
        <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
      )}
      <InputFormStyle
        fontSize={fontSize}
        height={height}
        width="100%"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={label}
      ></InputFormStyle>
    </div>
  );
};

export default FormNickNameInput;
