import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
import { IUser } from "../user/interface/interface";
import { IRecord } from "./interface/interface";
import CafePagingTable from "../../components/table/CafePagingTable";
import UserPage from "../../components/table/userPaing";
const RouletteTicket = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [records, setRecords] = useState<IRecord[]>([]);
  const [searchUsers, setSearchUsers] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recordCurrentPage, setRecordCurrentPage] = useState(1);
  const [recordTotalPages, setRecordTotalPages] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "아이디",
      align: "start",
      sortable: false,
      value: "textId",
    },
    {
      text: "사용자명",
      align: "center",
      sortable: false,
      value: "username",
    },
    {
      text: "닉네임",
      align: "center",
      sortable: false,
      value: "nickname",
    },
    {
      text: "생년월일",
      align: "center",
      sortable: false,
      value: "birthday",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "가입일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "인증여부",
      align: "center",
      sortable: false,
      value: "validate",
    },
    {
      text: "탈퇴여부",
      align: "center",
      sortable: false,
      value: "exit",
    },
  ];
  const ticketHeader = [
    { text: "ID", value: "id" },
    { text: "획득시간", value: "createdAt" },
    { text: "수정시간", value: "updatedAt" },
    { text: "사용여부", value: "used" },
    { text: "사용기한", value: "expirationTime" },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const setDetail = async (item: IUser) => {
    const res = await AdminAPI.getTicketByUserId(item.id);
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: IRecord) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
        updatedAt: formatDate(item.updatedAt),
        expirationTime: formatDate(item.expirationTime),
      }));
      const totalPages = Math.ceil(res.data.length / 10);
      setRecordTotalPages(totalPages);
      setRecords(updatedData);
    }
  };

  const phoneNumberFormat = (number: string) => {
    if (number) {
      const value = number.replace(/[^0-9]/g, "");
      const firstLength = value.length > 9 ? 3 : 2;
      return [
        value.slice(0, firstLength),
        value.slice(firstLength, value.length - 4),
        value.slice(value.length - 4),
      ].join("-");
    }
  };

  const getUsers = async () => {
    const res = await AdminAPI.getUsers(0);
    if (res && res.status === 200) {
      console.log(res.data.userList);
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setUsers(updatedData);
      setSearchUsers(updatedData);
    }
  };
  const handleSearch = async (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
    setIsSearch(true);
    if (!search.trim()) {
      setIsSearch(false);
      getUsers();
      return;
    }

    const res = await AdminAPI.findUsers(search, currentPage - 1);
    if (res && res.status === 200) {
      console.log(res.data.userList);
      const updatedData = res.data.userList.map((item: IUser) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(res.data.totalCount / 10);
      setTotalPages(totalPages);
      setTotalCount(res.data.totalCount);
      setSearchUsers(updatedData);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="60%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          유저 조회
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <CafePagingTable
            headers={headers}
            currentPage={currentPage}
            dataList={searchUsers}
            setDetail={setDetail}
          />
          <UserPage
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchUsers}
            setCurrentPage={setCurrentPage}
            count={totalCount}
            setDataList={setUsers}
            setSearchDataList={setSearchUsers}
            isSearch={isSearch}
            search={search}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="40%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          이용권 조회
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <Table
            headers={ticketHeader}
            currentPage={recordCurrentPage}
            dataList={records}
          />
          <Page
            currentPage={recordCurrentPage}
            totalPages={recordTotalPages}
            dataList={records}
            setCurrentPage={setRecordCurrentPage}
          />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default RouletteTicket;
