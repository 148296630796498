import {
  IMessage,
  IUserPoints,
} from "../../pages/DashBoard/interface/interface";
import { adminApiRequest } from "../../utils/apiUtils";

export const UserAPI = {
  getSeller: (name: string) => {
    return adminApiRequest
      .get(`/user/findUsers?username=${name}`)
      .then((res) => res);
  },
  getUsers: (page: number) => {
    return adminApiRequest
      .get("/user/adminList", { params: { page, size: 10 } })
      .then((res) => res);
  },
  findUsers: (text: string, page: number) => {
    return adminApiRequest
      .get("/user/findByText", { params: { text, page, size: 10 } })
      .then((res) => res);
  },
  updateRole: (userId: number, role: string) => {
    return adminApiRequest
      .put(`/user/updateRole?userId=${userId}&roleType=${role}`)
      .then((res) => res);
  },
  getUserById: (id: number) => {
    return adminApiRequest.get(`/user/info?userId=${id}`).then((res) => res);
  },
  updateNickName: (userId: number, nickName: string) => {
    return adminApiRequest
      .patch(`/user/nickname?nickname=${nickName}&userId=${userId}`)
      .then((res) => res);
  },
  getAllUserMessage: () => {
    return adminApiRequest.get("/user/getAllMessageList").then((res) => res);
  },
  getMessageHistory: () => {
    return adminApiRequest.get("/user/messageHistoryList").then((res) => res);
  },
  updateUserMessage: (body: any, userId: number) => {
    return adminApiRequest
      .post(`/user/messageUpdate?userId=${userId}`, body)
      .then((res) => res);
  },
  updateUserMessageWithOut: (body: any, userId: number) => {
    return adminApiRequest
      .post(`/user/messageUpdateWithOutImage?userId=${userId}`, body)
      .then((res) => res);
  },
  removeUserMessage: (id: number) => {
    return adminApiRequest
      .delete(`/user/messageDelete?messageId=${id}`)
      .then((res) => res.data);
  },
  getUserPoint: () => {
    return adminApiRequest.get("/user/allUserPoint").then((res) =>
      res.data.map((obj: IUserPoints) => {
        obj.date = obj.createdAt.slice(0, 10);
        return obj;
      })
    );
  },
  getAllUserPoint: () => {
    return adminApiRequest.get("/user/allUserPoint").then((res) => res);
  },
  getMessageList: () => {
    return adminApiRequest.get("/user/getAllMessageList").then((res) =>
      res.data.map((obj: IMessage) => {
        obj.date = obj.createdAt.slice(0, 10);
        return obj;
      })
    );
  },
  chargePoint: (id: number, points: number) => {
    return adminApiRequest
      .post("/user/productCharge", {
        params: { userId: id },
        data: {
          chargeProduct: "ANY",
          points,
        },
      })
      .then((res) => res.data);
  },
  getAllChargeList: () => {
    return adminApiRequest.get("/user/allChargeList").then((res) => res);
  },
};
