import { useEffect, useState } from "react";
import { Header } from "./interface/interface";
import TableStyle from "../style/table.component";
import { RankingPointResponse } from "../../pages/setRankingPoint/interface";
import InputStyle from "../style/input.component";
import ButtonStyle from "../style/button.component";

interface RankingPointProps {
  headers: any[];
  dataList: RankingPointResponse[];
  setDataList: (value: RankingPointResponse[]) => void;
  save: any;
  add: any;
  remove: any;
  tournamentType: string;
}
const RankingPointTable = (props: RankingPointProps) => {
  const [isWide, setIsWide] = useState(window.innerWidth > 700);
  const { headers, dataList, setDataList, save, add, remove, tournamentType } =
    props;
  const [addPoint, setAddPoint] = useState(0);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 700) {
        setIsWide(false);
      } else {
        setIsWide(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return isWide ? (
    <TableStyle marginTop="30px">
      <thead>
        <tr>
          {headers.map((header: Header) => (
            <th key={header.value}>{header.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataList.map((data: any, index: number) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header.value}>
                {header.text === "랭킹" ? (
                  data[header.value]
                ) : header.text === "보상" ? (
                  <InputStyle
                    height="25px"
                    width="190px"
                    fontSize="13px"
                    color="white"
                    placeholder="지급할 포인트를 입력하세요."
                    value={data[header.value]}
                    onChange={(e: any) => {
                      const newValue = Number(e.target.value);
                      let updateDataList = dataList.map(
                        (item: RankingPointResponse) => {
                          if (item.rank === data["rank"]) {
                            return { ...item, point: newValue };
                          }
                          return item;
                        }
                      );
                      setDataList(updateDataList);
                    }}
                  />
                ) : (
                  <>
                    <ButtonStyle
                      fontWeight="700"
                      backgroundColor="#1976D2"
                      border="none"
                      borderRadius="7px"
                      width="85px"
                      height="25px"
                      color="white"
                      marginRight="10px"
                      onClick={() => {
                        save(data);
                      }}
                    >
                      수정
                    </ButtonStyle>
                    {index === dataList.length - 1 && (
                      <ButtonStyle
                        fontWeight="700"
                        backgroundColor="red"
                        border="none"
                        borderRadius="7px"
                        width="85px"
                        height="25px"
                        color="white"
                        marginRight="10px"
                        onClick={() => {
                          remove(data.id);
                        }}
                      >
                        삭제
                      </ButtonStyle>
                    )}
                  </>
                )}
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td>{dataList.length + 1}</td>
          <td>
            <InputStyle
              height="25px"
              width="190px"
              fontSize="13px"
              color="white"
              type="number"
              placeholder="지급할 포인트를 입력하세요."
              value={addPoint}
              onChange={(e: any) => {
                setAddPoint(e.target.value);
              }}
            />
          </td>
          <td>
            <ButtonStyle
              fontWeight="700"
              backgroundColor="#1976D2"
              border="none"
              borderRadius="7px"
              width="85px"
              height="25px"
              color="white"
              marginRight="10px"
              onClick={async () => {
                const item = {
                  rank: dataList.length + 1,
                  point: addPoint,
                  tournamentType,
                };
                const result = await add(item);
                if (result === true) {
                  setAddPoint(0);
                }
              }}
            >
              추가 등록
            </ButtonStyle>
          </td>
        </tr>
      </tbody>
    </TableStyle>
  ) : (
    <TableStyle setMobile={true} marginTop="30px">
      <tbody>
        {dataList.map((data: any, index: number) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={header.value}>
                <div>{header.text}</div>
                <td key={header.value}>
                  {header.text === "랭킹" ? (
                    data[header.value]
                  ) : header.text === "보상" ? (
                    <InputStyle
                      height="25px"
                      width="190px"
                      fontSize="13px"
                      color="white"
                      placeholder="지급할 포인트를 입력하세요."
                      value={data[header.value]}
                      onChange={(e: any) => {
                        const newValue = Number(e.target.value);
                        let updateDataList = dataList.map(
                          (item: RankingPointResponse) => {
                            if (item.rank === data["rank"]) {
                              return { ...item, point: newValue };
                            }
                            return item;
                          }
                        );
                        setDataList(updateDataList);
                      }}
                    />
                  ) : (
                    <>
                      <ButtonStyle
                        fontWeight="700"
                        backgroundColor="#1976D2"
                        border="none"
                        borderRadius="7px"
                        width="85px"
                        height="25px"
                        color="white"
                        marginRight="10px"
                        onClick={() => {
                          save(data);
                        }}
                      >
                        수정
                      </ButtonStyle>
                      {index === dataList.length - 1 && (
                        <ButtonStyle
                          fontWeight="700"
                          backgroundColor="red"
                          border="none"
                          borderRadius="7px"
                          width="85px"
                          height="25px"
                          color="white"
                          marginRight="10px"
                          onClick={() => {
                            remove(data.id);
                          }}
                        >
                          삭제
                        </ButtonStyle>
                      )}
                    </>
                  )}
                </td>
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td>
            <div>랭킹</div>
            <td>{dataList.length + 1}</td>
          </td>
          <td>
            <div>포인트</div>
            <td>
              <InputStyle
                height="25px"
                color="white"
                value={addPoint}
                onChange={(e: any) => {
                  setAddPoint(e.target.value);
                }}
              />
            </td>
          </td>
          <td>
            <div></div>
            <td>
              <ButtonStyle
                fontWeight="700"
                backgroundColor="#1976D2"
                border="none"
                borderRadius="7px"
                width="85px"
                height="25px"
                color="white"
                marginRight="10px"
                onClick={async () => {
                  const item = {
                    rank: dataList.length + 1,
                    point: addPoint,
                    tournamentType,
                  };
                  const result = await add(item);
                  if (result) {
                    setAddPoint(0);
                  }
                }}
              >
                추가 등록
              </ButtonStyle>
            </td>
          </td>
        </tr>
      </tbody>
    </TableStyle>
  );
};

export default RankingPointTable;
