import { useState } from "react";
import DivStyle from "../style/div.component";
import CheckTable from "../table/CheckTable";
import ButtonStyle from "../style/button.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
interface SellerProps {
  setOwnerId: (value: any) => void;
  setOwnerName: (value: any) => void;
  setIsOpen: (value: any) => void;
}

interface SellerData {
  id: number;
  username: string;
  phoneNumber: string;
  pubs: string[];
}
const SearchSeller = (props: SellerProps) => {
  const [selected, setSelected] = useState("");
  const [dataList, setDataList] = useState<SellerData[]>([]);
  const { setOwnerId, setOwnerName, setIsOpen } = props;

  const [search, setSearch] = useState("");
  const headers = [
    {
      text: "이름",
      align: "center",
      value: "username",
      sortable: false,
    },
    {
      text: "펍 이름",
      align: "center",
      value: "pubs",
      sortable: false,
    },
    {
      text: "연락처",
      align: "center",
      value: "phoneNumber",
      sortable: false,
    },
  ];

  const getList = async () => {
    const res = await AdminAPI.getSeller(search);
    if (res && res.status === 200) {
      // Promise.all을 사용하여 모든 비동기 작업 완료를 기다림
      const updatedData = await Promise.all(
        res.data.map(async (item: any) => {
          const pubs = await AdminAPI.getSellerPubCount(item.id);

          return {
            ...item,
            pubs:
              process.env.ENV === "prod" && item.username === "러너관리자"
                ? ""
                : pubs.data,
          };
        })
      );
      setDataList(updatedData);
    }
  };

  const setData = async () => {
    if (dataList.length > 0 && selected !== "") {
      const data = dataList[parseInt(selected)];
      const res = await AdminAPI.getSellerPubCount(data.id);
      const isProd = process.env.ENV === "prod";
      const isAdmin = isProd && data["username"] === "러너관리자";
      if (res.data.length > 0 && !isAdmin) {
        alert(
          "해당 계정으로 이미 등록한 펍이 존재합니다.\n" +
            "등록하신 펍 : " +
            res.data
        );
        return;
      }

      setOwnerId(data["id"]);
      setOwnerName(data["username"]);
      setIsOpen(false);
    } else {
      alert("선택해주세요.");
    }
  };

  return (
    <DivStyle
      style={{
        maxWidth: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "300px",
      }}
      borderRadius="10px"
      position="fixed"
      width="90%"
      height="auto"
      backgroundColor="black"
      color="white"
      display="flex"
      flex_direction="column"
      align_items="center"
    >
      <h4>셀러 검색</h4>
      <DivStyle
        width="80%"
        display="flex"
        flex_direction="row"
        align_items="center"
      >
        <InputFormStyle
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getList();
            }
          }}
        ></InputFormStyle>
        <div onClick={() => getList()}>
          <Icon path={mdiMagnify} size={1} />
        </div>
      </DivStyle>
      <CheckTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        dataList={dataList}
      />
      <DivStyle
        display="flex"
        flex_direction="row"
        width="auto"
        marginTop="30px"
        marginBottom="30px"
      >
        <ButtonStyle
          color="white"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginRight="5px"
          backgroundColor="#1976D2"
          onClick={() => setData()}
        >
          적용
        </ButtonStyle>
        <ButtonStyle
          color="white"
          backgroundColor="#757575"
          border="none"
          borderRadius="5px"
          width="100px"
          height="40px"
          marginLeft="5px"
          onClick={() => setIsOpen(false)}
        >
          닫기
        </ButtonStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default SearchSeller;
