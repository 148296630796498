import { adminApiRequest } from "../../utils/apiUtils";

export const PopupNoticeAPI = {
  updatePopupNotice: (body: any) => {
    return adminApiRequest.put("/popup-notice/update", body).then((res) => res);
  },

  removePopupNotice: (id: number) => {
    return adminApiRequest
      .delete(`/popup-notice/delete?id=${id}`)
      .then((res) => res);
  },

  registerPopupNotice: (body: any) => {
    return adminApiRequest
      .post("/popup-notice/register", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },

  updatePopupNoticeImage: (body: any) => {
    return adminApiRequest
      .put("/popup-notice/updateImage", body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res);
  },
};
