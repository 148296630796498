import ButtonStyle from '../style/button.component';
import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";

const FormInputButton = (props: InputFormProps) => {
  const { value, setValue, label, width, height, fontSize } = props;
  const {value1, setValue2, getThumnail} = value;
  return (
    <div style={{ width: width, marginTop: "10px" }}>
      {value !== "" ? (
        <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
      )}
      <div style={{display: 'flex',flexDirection: 'row'}}>
        <InputFormStyle
          fontSize={fontSize}
          height={height}
          width="80%"
          value={value1}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder={label}
        ></InputFormStyle>
        <ButtonStyle
        border='none'
        width='19%'
        marginLeft='10px'
        backgroundColor='#1976D2'
        color='white'
        borderRadius='5px'
        onClick={() => {getThumnail(value1)}}
        >
          썸네일 추출
        </ButtonStyle>
      </div>
    </div>
  );
};

export default FormInputButton;
