import { useEffect, useState } from "react";
import ButtonStyle from "../style/button.component";
import DivStyle from "../style/div.component";
import Table from "../table/Table";
import Page from "../table/paging";
import API from "../../api/api";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import ExcelJS from "exceljs";
import { Header } from "../table/interface/interface";
import FileSaver from "file-saver";
import { ICafeMessage } from "./interface/interface";

const HoldemNow = () => {
  const [dataList, setDataList] = useState<ICafeMessage[]>([]);
  const [searchDataList, setSearchDataList] = useState<ICafeMessage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const [dayUseCount, setDayUseCount] = useState(0);
  const [allUseCount, setAllUseCount] = useState(0);
  const headers = [
    {
      text: "날짜 (최근 순)",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
    {
      text: "내용",
      align: "center",
      sortable: true,
      value: "message",
    },
    {
      text: "펍 이름",
      align: "center",
      sortable: true,
      value: "pubName",
    },
    {
      text: "사용 포인트",
      align: "center",
      sortable: false,
      value: "points",
    },
  ];
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getList = async () => {
    const res = await API.getCafeMessage();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: ICafeMessage) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      setAllUseCount(updatedData.length);
      const today = new Date().toISOString().split("T")[0];
      const todayList = updatedData.filter((message: ICafeMessage) =>
        message.createdAt.includes(today)
      );
      setDayUseCount(todayList.length);

      setDataList(updatedData);
      const totalPages = Math.ceil(res.data.length / pageSize);
      setTotalPages(totalPages);
      setSearchDataList(updatedData);
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(1);

    if (!search.trim()) {
      setSearchDataList(dataList);
      const totalPages = Math.ceil(dataList.length / 10);
      setTotalPages(totalPages);
      return;
    }

    const filteredList = dataList.filter((item: ICafeMessage) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(item).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchDataList(filteredList);
    const totalPages = Math.ceil(filteredList.length / 10);
    setTotalPages(totalPages);
  };

  const exportToExcel = async (headers: Header[], items: ICafeMessage[]) => {
    const today = new Date().toISOString().split("T")[0];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // 헤더 행 추가
    worksheet.addRow(headers.map((header) => header.text));

    // 데이터 행 추가
    items.forEach((item: ICafeMessage | any) => {
      const rowValues = headers.map((header) => {
        if (header.value === "createdAt") {
          // createdAt 컬럼에서 날짜만 추출하여 사용
          return item[header.value].split("T")[0];
        } else {
          return item[header.value];
        }
      });
      worksheet.addRow(rowValues);
    });

    // 엑셀 파일 생성 및 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "all_report" + "_" + today + ".xlsx");
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <DivStyle
      width="100%"
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="column"
      align_items="center"
      marginTop="65px"
    >
      <h4 style={{ color: "white", width: "auto", margin: "0" }}>
        1일 사용 현황 : {dayUseCount}
      </h4>
      <h4 style={{ color: "white", width: "auto", margin: "0" }}>
        누적 사용 현황 : {allUseCount}{" "}
        <ButtonStyle
          fontWeight="700"
          backgroundColor="#272727"
          border="none"
          borderRadius="7px"
          marginTop="10px"
          marginLeft="15px"
          width="85px"
          height="40px"
          color="white"
          marginRight="20px"
          onClick={() => exportToExcel(headers, dataList)}
        >
          TOEXCEL
        </ButtonStyle>
      </h4>

      <DivStyle
        width="80%"
        display="flex"
        backgroundColor="#1E1E1E"
        flex_direction="column"
        borderRadius="10px"
        marginTop="30px"
        marginBottom="60px"
      >
        <DivStyle
          width="100%"
          display="flex"
          flex_direction="row"
          justify_content="end"
          align_items="center"
        >
          <DivStyle
            width="50%"
            display="flex"
            justify_content="flex-end"
            flex_direction="row"
            align_items="center"
            marginRight="20px"
            marginTop="20px"
          >
            <InputFormStyle
              onChange={(e) => handleSearch(e.target.value)}
            ></InputFormStyle>
            <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
          </DivStyle>
        </DivStyle>
        <Table
          headers={headers}
          currentPage={currentPage}
          dataList={searchDataList}
        />
        <Page
          currentPage={currentPage}
          totalPages={totalPages}
          dataList={searchDataList}
          setCurrentPage={setCurrentPage}
        />
      </DivStyle>
    </DivStyle>
  );
};
export default HoldemNow;
