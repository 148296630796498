// form_files.tsx
import { useState } from "react";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiCamera } from "@mdi/js";
import { InputFormProps } from "./interface/formInterface";
import DivStyle from "../style/div.component";

interface FormFilesInputProps extends InputFormProps {
  currentImageUrls?: string[];
  multiple?: boolean; // multiple 속성 추가
}

const FormFilesInput = (props: FormFilesInputProps) => {
  const { value, setValue, label, width, currentImageUrls, multiple } = props;
  return (
    <DivStyle width={width} display="flex" flex_direction="column">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <span style={{ color: "white", marginRight: "7px" }}>{label}</span>
        <label htmlFor="input">
          <Icon
            path={mdiCamera}
            size={1}
            style={{ color: "white", marginRight: "7px" }}
          />
        </label>

        <InputFormStyle
          onChange={(e: any) => {
            setValue(e.target.files);
          }}
          id="input"
          fontSize="14px"
          width="500px"
          type="file"
          accept=".jpg, .jpeg, .png"
          multiple={true}
        />
      </div>
      {currentImageUrls && currentImageUrls.length > 0 && (
        <DivStyle
          marginTop="10px"
          display="flex"
          flex_direction="row"
          flex_wrap="wrap"
          gap="10px"
        >
          {currentImageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Current poster ${index + 1}`}
              style={{ maxWidth: "150px", maxHeight: "150px" }}
            />
          ))}
        </DivStyle>
      )}
    </DivStyle>
  );
};

export default FormFilesInput;
