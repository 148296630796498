import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";
import { FormProps } from "../../components/form/interface/formInterface";
import API from "../../api/api";
import SmallForm from "../../components/form/small_form";
import AdminAPI from "../../api/admin";
import InputFormStyle from "../../components/style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { ICafe, IFacilities } from "../cafe/interface/interface";

const Affiliate = () => {
  const [cafeList, setCafeList] = useState<ICafe[]>([]);
  const [searchCafeList, setSearchCafeList] = useState<ICafe[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [cafeName, setCafeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [employerId, setEmployerId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [areaProvinceId, setAreaProvinceId] = useState(0);
  const [areaCityId, setAreaCityId] = useState(0);
  const [areaStreetId, setAreaStreetId] = useState(0);
  const [detailAddress, setDetailAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [images, setImages] = useState<string[]>([]);
  const [facilities, setFacilities] = useState<string[]>([]);
  const [directions, setDirection] = useState("");
  const [informationUse, setInformationUse] = useState("");

  const [areaProvinces, setAreaProvinces] = useState([]);
  const [areaCities, setAreaCities] = useState([]);
  const [areaStreets, setAreaStreets] = useState([]);

  const formData: FormProps = {
    title: "상세정보",
    form: [
      {
        value: cafeName,
        setValue: setCafeName,
        label: "펍명",
        width: "100%",
        type: "input-dash",
      },
      {
        value: ownerName,
        setValue: setOwnerName,
        label: "대표자",
        width: "100%",
        type: "input-dash",
      },

      {
        type: "input-dash",
        value: employerId,
        setValue: setEmployerId,
        label: "사업자 등록번호(-를 포함시켜주세요.)",
        width: "100%",
      },
      {
        type: "input-dash",
        value: phoneNumber,
        setValue: setPhoneNumber,
        label: "연락처",
        width: "100%",
      },
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        width: "100%",
        type: "input-dash",
      },
      {
        value: {
          value1: areaProvinceId,
          value2: areaCityId,
          value3: areaStreetId,
          setValue2: setAreaCityId,
          setValue3: setAreaStreetId,
          options2: areaCities,
          options3: areaStreets,
          setOptions2: setAreaCities,
          setOptions3: setAreaStreets,
          label2: "군/구",
          label3: "동",
        },
        setValue: setAreaProvinceId,
        label: "시/도",
        width: "100%",
        type: "area-form",
        options: areaProvinces,
      },
      {
        value: detailAddress,
        setValue: setDetailAddress,
        label: "상세주소",
        width: "100%",
        type: "input-dash",
      },
      {
        value: {
          value1: lat,
          value2: lon,
          label2: "경도",
        },
        setValue: setLat,
        label: "위도",
        width: "100%",
        type: "location-not-search-form",
      },
      {
        value: {
          value1: images,
        },
        setValue: setImages,
        label: "사진",
        width: "100%",
        type: "images-not-delete",
      },
      {
        value: facilities,
        setValue: setFacilities,
        label: "편의시설 및 서비스",
        width: "100%",
        type: "facility-form",
      },
      //제공 서비스
      {
        value: directions,
        setValue: setDirection,
        label: "오시는길",
        width: "100%",
        height: "120px",
        type: "text",
      },
      {
        value: informationUse,
        setValue: setInformationUse,
        label: "펍설명",
        width: "100%",
        height: "120px",
        type: "text",
      },
    ],
  };

  const headers = [
    {
      text: "ID",
      align: "center",
      sortable: false,
      value: "id",
    },
    {
      text: "제휴신청명",
      align: "center",
      sortable: false,
      value: "cafeName",
    },
    {
      text: "대표자",
      align: "center",
      sortable: false,
      value: "ownerName",
    },
    {
      text: "연락처",
      align: "center",
      sortable: false,
      value: "phoneNumber",
    },
    {
      text: "주소",
      align: "center",
      sortable: false,
      value: "newAddress",
    },

    {
      text: "생성일자",
      align: "center",
      sortable: false,
      value: "createdAt",
    },
  ];
  const setDetail = async (item: ICafe) => {
    setCafeName(item.cafeName);
    setOwnerName(item.ownerName);
    setEmployerId(item.employerId);
    setPhoneNumber(item.phoneNumber);
    setEmail(item.email);
    setAreaCityId(item.areaCityId);
    setAreaProvinceId(item.areaProvinceId);
    setAreaStreetId(item.areaStreetId);
    setDetailAddress(item.detailAddress);
    setLat(item.lat);
    setLon(item.lon);
    const imageUrls = item.images.map(image => image.imageUrl);
    setImages(imageUrls);
    const facilities: string[] = item.facilities.map(function (e: IFacilities) {
      return e.type;
    });
    setFacilities(facilities);
    setDirection(item.directions);
    setInformationUse(item.informationUse);
  };
  const handleSearch = (search: string) => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동

    if (!search.trim()) {
      setSearchCafeList(cafeList); // 검색어가 비어있다면 전체 목록을 다시 보여줌
      const totalPages = Math.ceil(cafeList.length / 10); // 페이지 총 개수 계산
      setTotalPages(totalPages);
      return;
    }

    const filteredList = cafeList.filter((cafe: ICafe) => {
      // 모든 객체의 키를 반복하여 해당 키의 값에 검색어가 포함되어 있는지 확인
      return Object.values(cafe).some((value) => {
        if (value)
          return value.toString().toLowerCase().includes(search.toLowerCase());
      });
    });

    setSearchCafeList(filteredList); // 필터링된 리스트를 검색 결과로 설정
    const totalPages = Math.ceil(filteredList.length / 10); // 페이지 총 개수 계산
    setTotalPages(totalPages);
  };
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getCafes = async () => {
    const res = await AdminAPI.getAffiliateList();
    if (res && res.status === 200) {
      const updatedData = res.data.map((item: ICafe) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);

      setCafeList(updatedData);
      setSearchCafeList(updatedData);
    }

    const area_res = await API.getAreaProvince();
    if (area_res && area_res.status === 200) {
      setAreaProvinces(area_res.data);
    }
  };
  useEffect(() => {
    getCafes();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          제휴신청 관리
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="20px"
        >
          <DivStyle
            width="100%"
            display="flex"
            flex_direction="row"
            justify_content="space-between"
            align_items="center"
          >
            <DivStyle
              width="100%"
              display="flex"
              flex_direction="row"
              justify_content="end"
              align_items="center"
            >
              <DivStyle
                width="50%"
                display="flex"
                justify_content="flex-end"
                flex_direction="row"
                align_items="center"
                marginRight="20px"
                marginTop="20px"
              >
                <InputFormStyle
                  onChange={(e) => handleSearch(e.target.value)}
                ></InputFormStyle>
                <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
              </DivStyle>
            </DivStyle>
          </DivStyle>

          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={searchCafeList}
            setDetail={setDetail}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={searchCafeList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <SmallForm form={formData.form} title={formData.title} />
        </DivStyle>
      </DivStyle>
    </DivStyle>
  );
};

export default Affiliate;
