import { adminApiRequest } from "../../utils/apiUtils";

export interface PubGradeRequest {
  pubType: string;
  pubId: number;
  closeAt: string;
}

export enum PubType {
  VIP = "VIP",
  PREMIUM = "PREMIUM",
  AFFILIATE = "AFFILIATE",
  NORMAL = "NORMAL",
}

export enum RequestGradeState {
  REQUEST = "REQUEST",
  TM_STAND_BY = "TM_STAND_BY",
  WAIT_DEPOSIT = "WAIT_DEPOSIT",
  FINISH = "FINISH",
  RETURN = "RETURN",
}

interface PubResponse {
  id: number;
  pubName: string;
  newAddress: string;
  detailAddress: string;
  ownerName: string;
  phoneNumber: string;
}

export interface AdminRequestGradeResponse {
  id: number;
  pub: PubResponse;
  preGrade: PubType;
  afterGrade: PubType;
  state: RequestGradeState;
  createdAt: string;
  hasPackage: boolean;
}

export interface AdminRequestGradeLogResponse {
  id: number;
  pub: PubResponse;
  preGrade: PubType;
  afterGrade: PubType;
  state: RequestGradeState;
  createdAt: string;
  username: string;
}
export interface AdminPubPushLogResponse {
  id: number;
  pubName: string;
  count: number;
  createdAt: string;
  username: string;
}
export interface PubGradeCloseAtAndPushCountResponse {
  closeAt: string;
  pushCount: number;
}

export interface RequestState {
  state: string;
}

export interface CalcPushCountRequest {
  id: number;
  count: number;
}

export interface SellerRequestPackageResponse {
  packageName: string;
  price: number;
}

export const PubGradeApi = {
  createGrade: (pubGradeRequest: PubGradeRequest) => {
    return adminApiRequest
      .post("/pub-grade", pubGradeRequest)
      .then((res) => res);
  },
  getRequestGrade: (): Promise<AdminRequestGradeResponse[]> => {
    return adminApiRequest.get("/pub-grade/request").then((res) => res.data);
  },
  getRequestGradeLog: (): Promise<AdminRequestGradeLogResponse[]> => {
    return adminApiRequest
      .get("/pub-grade/request/log")
      .then((res) => res.data);
  },
  getPushLog: (): Promise<AdminPubPushLogResponse[]> => {
    return adminApiRequest.get("/pub-grade/push/log").then((res) => res.data);
  },
  getPubGradesClosingInThreeDays: (): Promise<string[]> => {
    return adminApiRequest
      .get("/pub-grade/close-three-days")
      .then((res) => res.data);
  },
  getPubGradeCloseAt: (
    pubId: number
  ): Promise<PubGradeCloseAtAndPushCountResponse> => {
    return adminApiRequest
      .get(`/pub-grade/close/${pubId}`)
      .then((res) => res.data);
  },
  updateGradeRequestState: (
    id: number,
    request: RequestState
  ): Promise<AdminRequestGradeResponse[]> => {
    return adminApiRequest
      .put(`/pub-grade/request/${id}`, request)
      .then((res) => res.data);
  },
  calcGradePushCount: (request: CalcPushCountRequest) => {
    return adminApiRequest
      .put(`/pub-grade/push`, request)
      .then((res) => res.data);
  },
  getPackage: (id: number): Promise<SellerRequestPackageResponse[]> => {
    return adminApiRequest
      .get(`/pub-grade/package-request/${id}`)
      .then((res) => res.data);
  },
};
