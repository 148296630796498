import InputFormStyle from "../style/input_form.component";
import { InputFormProps } from "./interface/formInterface";

const FormInputColor = (props: InputFormProps) => {
  const { value, setValue, label, width, height, fontSize } = props;

  return (
    <div style={{ width: width, marginTop: "10px" }}>
      {value !== "" ? (
        <label style={{ color: "white", fontSize: "12px" }}>{label}</label>
      ) : (
        <label style={{ color: "#1E1E1E", fontSize: "12px" }}>{label}</label>
      )}
      <input
        type="color"
        style={{ width: "100%", marginTop: "5px" }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={label}
      ></input>
    </div>
  );
};

export default FormInputColor;
