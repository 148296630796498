import { InputFormProps } from "./interface/formInterface";

const FormVideo = (props: InputFormProps) => {
  const { value, width } = props;
  return value ? (
    <video src={value} width={width} controls style={{ maxWidth: "100%" }}>
      Your browser does not support the video tag.
    </video>
  ) : null;
};

export default FormVideo;
