import { useState } from "react";
import DivStyle from "../style/div.component";
import InputFormStyle from "../style/input_form.component";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import AdminAPI from "../../api/admin";
import SmallForm from "../form/small_form";
import { FormProps } from "../form/interface/formInterface";
import ButtonStyle from "../style/button.component";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";

const UserCharge = () => {
  const [search, setSearch] = useState<number>(0);
  const setIsLoading = useSetRecoilState(loadingState);
  const [userId, setUserId] = useState(0);
  const [username, setUserName] = useState("");
  const [nickname, setNickName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [chargeValue, setChargeValue] = useState<number>(0);

  const formData: FormProps = {
    title: "유저 정보",
    form: [
      {
        value: nickname,
        setValue: setNickName,
        label: "닉네임",
        width: "100%",
        type: "input-dash",
      },
      {
        value: username,
        setValue: setUserName,
        label: "이름",
        width: "100%",
        type: "input-dash",
      },
      {
        value: phoneNumber,
        setValue: setPhoneNumber,
        label: "연락처",
        width: "100%",
        type: "input-dash",
      },
      {
        value: email,
        setValue: setEmail,
        label: "이메일",
        width: "100%",
        type: "input-dash",
      },
    ],
  };

  const charge = async () => {
    if (userId > 0) {
      if (window.confirm("충전 하시겠습니까?")) {
        setIsLoading(true);
        const res = await AdminAPI.chargePoint(userId, chargeValue);
        if (res && res.status === 200) {
          alert("충전 성공하였습니다.");
          setChargeValue(0);
        } else {
          alert("충전에 실패하였습니다.");
        }
        setIsLoading(false);
      }
    } else {
      alert("먼저 유저를 검색해주세요.");
    }
  };

  const handleSearch = async () => {
    if (search > 0) {
      setIsLoading(true);
      const res = await AdminAPI.getUserById(search);
      if (res && res.status === 200) {
        setUserId(res.data.id);
        setUserName(res.data.username);
        setNickName(res.data.nickname);
        setPhoneNumber(res.data.phoneNumber);
        setEmail(res.data.email);
      } else {
        alert("잘못된 아이디입니다.");
      }
      setIsLoading(false);
    } else {
      alert("1 이상의 숫자를 입력하세요.");
    }
  };

  return (
    <DivStyle
      width="100%"
      backgroundColor="#121212"
      height="auto"
      display="flex"
      flex_direction="column"
      align_items="center"
      marginTop="65px"
    >
      <DivStyle
        width="80%"
        height="auto"
        backgroundColor="#272727"
        display="flex"
        flex_direction="row"
        justify_content="center"
        align_items="center"
        setMobile={true}
      >
        <DivStyle
          display="flex"
          flex_direction="row"
          align_items="center"
          height="50px"
        >
          <InputFormStyle
            type="number"
            placeholder="유저 아이디 입력 창"
            width="90%"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(parseInt(e.target.value));
            }}
          ></InputFormStyle>
          <div onClick={() => handleSearch()}>
            <Icon path={mdiMagnify} size={1} style={{ color: "white" }} />
          </div>
        </DivStyle>
        <DivStyle
          display="flex"
          flex_direction="row"
          align_items="center"
          height="50px"
          marginLeft="100px"
          setMobileForm={true}
        >
          <InputFormStyle
            type="number"
            placeholder="충전할 금액 입력 창"
            width="90%"
            value={chargeValue}
            onChange={(e) => setChargeValue(parseInt(e.target.value))}
          ></InputFormStyle>
          <ButtonStyle
            onClick={() => charge()}
            marginLeft="10px"
            width="60px"
            height="40px"
            border="none"
            backgroundColor="#1976D2"
            color="white"
            borderRadius="5px"
          >
            충전
          </ButtonStyle>
        </DivStyle>
      </DivStyle>
      <DivStyle marginTop="100px" width="600px" setMobileForm={true}>
        <SmallForm form={formData.form} title={formData.title}></SmallForm>
      </DivStyle>
    </DivStyle>
  );
};
export default UserCharge;
