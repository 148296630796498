import { useEffect, useState } from "react";
import DivStyle from "../../components/style/div.component";
import Page from "../../components/table/paging";
import { AdminRequestGradeLogResponse } from "../../api/admin/pub-grade";
import AdminAPI from "../../api/admin";
import PubGradeTable from "../../components/table/PubGradeTable";

const PubGradeLog = () => {
  const [dataList, setDataList] = useState<AdminRequestGradeLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const headers = [
    { text: "신청 날짜", align: "center", sortable: false, value: "createdAt" },
    { text: "매장", align: "center", sortable: false, value: "pub.pubName" },
    { text: "주소", align: "center", sortable: false, value: "pub.newAddress" },
    {
      text: "대표자명",
      align: "center",
      sortable: false,
      value: "pub.ownerName",
    },
    {
      text: "전화번호",
      align: "center",
      sortable: false,
      value: "pub.phoneNumber",
    },
    {
      text: "실청 플랜",
      align: "center",
      sortable: false,
      value: "afterGrade",
      subValue: "preGrade",
    },
    {
      text: "진행 현황",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "처리 인원",
      align: "center",
      sortable: false,
      value: "username",
    },
  ];
  function formatDate(dateString: string) {
    if (!dateString) {
      return "Invalid Date";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const getRequest = async () => {
    const res = await AdminAPI.getRequestGradeLog();
    if (res) {
      const updatedData = res.map((item: AdminRequestGradeLogResponse) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));

      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setDataList(updatedData);
    }
  };

  useEffect(() => {
    getRequest();
  }, []);

  return (
    <>
      <DivStyle
        style={{ overflow: "auto" }}
        backgroundColor="#121212"
        height="100vh"
        display="flex"
        flex_direction="column"
        align_items="center"
        marginTop="65px"
      >
        <h2 style={{ color: "white", width: "95%", marginTop: "40px" }}>
          제휴 신청 관리 로그
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="60px"
        >
          <PubGradeTable
            headers={headers}
            currentPage={currentPage}
            dataList={dataList}
            getDatas={getRequest}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={dataList}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
      </DivStyle>
    </>
  );
};

export default PubGradeLog;
