import { useEffect, useState } from "react";

import AdminAPI from "../../api/admin";
import DivStyle from "../../components/style/div.component";
import Table from "../../components/table/Table";
import Page from "../../components/table/paging";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/loading.atom";
import {
  AttendanceRewardRequest,
  AttendanceRewardResponse,
  AttendanceTableResponse,
} from "../../api/admin/attendance";
import AttendanceRewardTable from "../../components/table/AttendanceRewardTable";
import ButtonStyle from "../../components/style/button.component";

const AttendanceReward = () => {
  const [list, setList] = useState<AttendanceTableResponse[]>([]);
  const [id, setId] = useState<null | number>(null);
  const setIsLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rewardList, setRewardList] = useState<AttendanceRewardResponse[]>([]);

  const headers = [
    {
      text: "id",
      align: "start",
      sortable: false,
      value: "id",
    },
    {
      text: "시작일시",
      align: "center",
      sortable: false,
      value: "startAt",
    },
    {
      text: "종료일시",
      align: "center",
      sortable: false,
      value: "closeAt",
    },
  ];

  const reward_headers = [
    {
      text: "보상",
      align: "center",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "remove",
    },
  ];

  const getRewardList = async (id: number) => {
    const response = await AdminAPI.getAttendanceReward(id);
    setRewardList(response);
  };
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const add = async (request: AttendanceRewardRequest) => {
    if (!id) {
      alert("설정할 테이블을 선택해주세요.");
      return;
    }

    if (window.confirm("신규등록 하시겠습니까?")) {
      setIsLoading(true);
      try {
        const res = await AdminAPI.registerAttendanceReward(request);
        if (res) {
          alert("신규 등록 되었습니다.");
          getRewardList(id);
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    }
  };

  const remove = async (id: number) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      setIsLoading(true);
      const res = await AdminAPI.removeAttendanceReward(id);
      if (res) {
        alert("삭제 되었습니다.");
        getRewardList(id);
      }
      setIsLoading(false);
    }
  };

  const save = async (request: AttendanceRewardResponse) => {
    if (!id) {
      alert("설정할 테이블을 선택해주세요.");
      return;
    }

    setIsLoading(true);
    try {
      const res = await AdminAPI.updateAttendanceReward(request.id, request);
      if (res) {
        alert("저장 되었습니다.");
        getRewardList(id);
        setIsLoading(false);
      }
    } catch (e: any) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const setDetails = async (item: AttendanceTableResponse) => {
    setId(item.id);
    await getRewardList(item.id);
  };

  const createAutoReward = async () => {
    if (!id) return;
    setIsLoading(true);
    const response = await AdminAPI.createAutoReward(id);

    if (response) {
      getRewardList(id);
      setIsLoading(false);
    }
  };

  const getTables = async () => {
    const res = await AdminAPI.getAttendanceTable();
    if (res) {
      const updatedData = res.map((item: AttendanceTableResponse) => ({
        ...item,
        closeAt: formatDate(item.closeAt),
        startAt: formatDate(item.startAt),
      }));
      const totalPages = Math.ceil(updatedData.length / 10);
      setTotalPages(totalPages);
      setList(updatedData);
    }
  };
  useEffect(() => {
    getTables();
  }, []);
  return (
    <DivStyle
      style={{ overflow: "auto" }}
      backgroundColor="#121212"
      height="100vh"
      display="flex"
      flex_direction="row"
      marginTop="65px"
      setMobile={true}
    >
      <DivStyle
        width="66%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileTable={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>
          출석 테이블
        </h2>
        <DivStyle
          width="95%"
          display="flex"
          backgroundColor="#1E1E1E"
          flex_direction="column"
          borderRadius="10px"
          marginTop="30px"
          marginBottom="10px"
        >
          <Table
            headers={headers}
            currentPage={currentPage}
            dataList={list}
            setDetail={setDetails}
          />
          <Page
            currentPage={currentPage}
            totalPages={totalPages}
            dataList={list}
            setCurrentPage={setCurrentPage}
          />
        </DivStyle>
        <DivStyle
          marginBottom="50px"
          width="95%"
          display="flex"
          justify_content="flex-end"
        >
          {id && rewardList.length === 0 && (
            <ButtonStyle
              border="none"
              backgroundColor="#1976D2"
              width="100px"
              height="40px"
              color="white"
              borderRadius="5px"
              onClick={() => createAutoReward()}
            >
              보상 자동 생성
            </ButtonStyle>
          )}
        </DivStyle>
      </DivStyle>
      <DivStyle
        width="33%"
        height="auto"
        display="flex"
        flex_direction="column"
        align_items="center"
        setMobileForm={true}
      >
        <h2 style={{ color: "white", width: "auto", marginTop: "40px" }}>폼</h2>
        <AttendanceRewardTable
          headers={reward_headers}
          dataList={rewardList}
          setDataList={setRewardList}
          save={save}
          add={add}
          remove={remove}
          tableId={id}
        />
      </DivStyle>
    </DivStyle>
  );
};

export default AttendanceReward;
